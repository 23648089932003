import { Box, Button, Stack, Typography } from "@mui/material";
import ModalLayout from "./ModalLayout";

import { ReactComponent as Check } from "../../images/check.svg";
import { pxToRem } from "px2rem2px";

const ThankModal = (props) => {
  return (
    <ModalLayout {...props} headerTitle="וואס טוט מען ווען דער מאן">
      <Box textAlign="center">
        <Stack
          position="absolute"
          top="-27px"
          left="50%"
          alignItems="center"
          justifyContent="center"
          bgcolor="#026670"
          width={pxToRem(54)}
          height={pxToRem(54)}
          borderRadius="100%"
          sx={{ transform: "translateX(-50%)" }}
        >
          <Check width={16} height={16} color="#FCE181" />
        </Stack>
        <Typography component="p" fontSize={pxToRem(20)} fontWeight={700} sx={{ marginTop: pxToRem(10) }}>
          Thank you.
          <br /> Payment Successful!
        </Typography>
        <Typography variant="medium" component="p" fontSize={pxToRem(16)} mt={pxToRem(20)}>
          An email confirmation was sent.
        </Typography>
        <Button variant="yellow" sx={{ width: {xs: "100%", ssm: "300px"}, maxWidth: {xs: "300px", ssm: "none"}, mt: pxToRem(40), height: pxToRem(54) }}>
          Start Watching
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default ThankModal;
