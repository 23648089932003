import React, { createContext, useState, useContext } from "react";

const CoursesContext = createContext();

export const CoursesProvider = ({ children }) => {
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [activeCourses, setActiveCourses] = useState([]);

  return (
    <CoursesContext.Provider
      value={{
        courses,
        setCourses,
        activeCourses,
        setActiveCourses,
        page,
        setPage,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};

export const useCourses = () => useContext(CoursesContext);
