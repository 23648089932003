import {
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Fab,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import Guides from "../../components/Guides";
import Hero from "../../components/courses/Hero";
import Sidebar from "../../components/courses/Sidebar";
import CoursesSlider from "../../components/courses/CoursesSlider";
import ContentSlider from "../../components/courses/ContentSlider";
import CoursesSection from "./CoursesSection";
import NavGroup from "../../components/courses/NavGroup";
import MenuDropdownLink from "../../components/menuDropdown/MenuDropdownLink";
import { useCallback, useEffect, useRef, useState } from "react";
import { pxToRem } from "px2rem2px";
import { listCourses, listCategories } from "../../api/course";
import { addFavorite, removeFavorite } from "../../api/course";

import { useAuth } from "../../utils/AuthContext";
import { useTheme, useMediaQuery } from "@mui/material";
import { useCourses } from "../../utils/CoursesContext";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CardSkeleton from "../../components/CardSkeleton";
import { get } from "react-hook-form";
import { getLanguageClass } from "../../utils/languageClass";

const SidebarCourses = ({
  categories,
  subscribedCourses,
  latestCourses,
  handleCategoryClick,
  isAuthenticated,
  isSmallScreen,
  isMediumScreen,
  selectedCategory,
  categoriesLoading,
  latestCoursesLoading,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: { md: "180px", lg: "215px" },
        flexDirection: isSmallScreen
          ? "column"
          : isMediumScreen
          ? "row"
          : "column",
        px: { xs: pxToRem(20), md: 0 },
        mt: { xs: pxToRem(20), md: 0 },
      }}
    >
      <Box sx={{ width: "100%" }}>
        {/* Categories Section */}
        <NavGroup
          title="Categories"
          itemsLength={categories.length}
          selectedCategory={selectedCategory}
          handleCategoryClick={handleCategoryClick}
        >
          {categoriesLoading
            ? Array.from(new Array(1)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="text"
                  animation="wave"
                  sx={{
                    fontSize: pxToRem(15),
                    fontWeight: 600,
                    color: "#333",
                    width: "100px",
                  }}
                />
              ))
            : categories.map((category) => (
                <MenuDropdownLink
                  to="#"
                  className={`overflow-ellipsis ${getLanguageClass(
                    category?.name,
                    true
                  )}`}
                  key={category?.id}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category?.name}
                </MenuDropdownLink>
              ))}
        </NavGroup>

        {/* Subscribed Courses Section */}
        {isAuthenticated && subscribedCourses.length > 0 && (
          <NavGroup
            title="My Courses"
            itemsLength={subscribedCourses?.length}
            isSubscribedCourses
          >
            {subscribedCourses.map((course) => (
              <MenuDropdownLink
                key={course.slug}
                to={`/course/${course.slug}`}
                className={`overflow-ellipsis ${getLanguageClass(
                  course?.name,
                  true
                )}`}
              >
                {course.name}
              </MenuDropdownLink>
            ))}
          </NavGroup>
        )}

        {/* Recently Added Section */}
        {(latestCoursesLoading || latestCourses?.length > 0) && (
          <NavGroup title="Recently Added" itemsLength={latestCourses.length}>
            {latestCoursesLoading
              ? Array.from(new Array(4)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="text"
                    animation="wave"
                    height={20}
                  />
                ))
              : latestCourses.map((course) => (
                  <MenuDropdownLink
                    key={course.slug}
                    to={`/course/${course.slug}`}
                    className={`overflow-ellipsis ${getLanguageClass(
                      course?.name,
                      true
                    )}`}
                  >
                    {course.name}
                  </MenuDropdownLink>
                ))}
          </NavGroup>
        )}
      </Box>

      {!isMediumScreen && (
        <>
          {isAuthenticated && (
            <>
              <Box sx={{ width: "90%" }}>
                <Typography
                  fontSize="14px"
                  lineHeight="18px"
                  fontWeight={600}
                  mb="20px"
                  textAlign="center"
                >
                  Receive notifications for new courses!
                </Typography>
                <Button
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: "14px !important" }}
                >
                  Get Notified
                </Button>
              </Box>
              <Divider
                sx={{ width: "90%", my: { xs: "20px", md: pxToRem(30) } }}
              />
            </>
          )}

          <Box
            sx={{
              marginLeft: isSmallScreen ? "0px" : isMediumScreen ? "30px" : "0",
            }}
          >
            <Stack
              gap="15px"
              sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "row" : "column",
                justifyContent: "center",
                "& a": { fontSize: "14px" },
              }}
            >
              <MenuDropdownLink to="/">Account</MenuDropdownLink>
              <MenuDropdownLink to="/">FAQ</MenuDropdownLink>
              <MenuDropdownLink to="/">Help</MenuDropdownLink>
            </Stack>
          </Box>
        </>
      )}
    </Box>
  );
};

const Courses = () => {
  useEffect(() => {
    document.title = "Explore Courses - Reb Yakov Moshe";
  }, []);
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery("(min-width:1227px)");
  const isMediumScreen = useMediaQuery("(max-width:950px)");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const {
    courses,
    setCourses,
    activeCourses,
    setActiveCourses,
    page,
    setPage,
    selectedCategory,
    setSelectedCategory,
  } = useCourses();

  const [isLoading, setIsLoading] = useState(false);
  const [subscribedCourses, setSubscribedCourses] = useState([]);
  const [latestCourses, setLatestCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [courseCount, setCoursesCount] = useState("");
  const [isOnSearchMode, setIsOnSearchMode] = useState(false);
  const [activeCoursesPage, setActiveCoursesPage] = useState(1);
  const [hasMoreActive, setHasMoreActive] = useState(true);
  const [filterMode, setFilterMode] = useState(false);
  const [totalCourses, setTotalCourses] = useState(0);
  const [subscribedCoursesPageSize, setSubscribedCoursesPageSize] =
    useState(100);
  const [countLoading, setCountLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  // sidebar
  const [latestCoursesLoading, setLatestCoursesLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);



  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  // courses
  const observer = useRef();
  const observerActive = useRef();

  const lastCourseElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            console.log("Last course is visible, loading more courses...");
            setPage((prevPage) => prevPage + 1);
            setIsLoading(true);
          }
        },
        {
          threshold: 0.5, // Add this line
        }
      );
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );



  // active courses



  useEffect(() => {
    if (!isAuthenticated) {
      setHasMoreActive(false);
    }
  }, [isAuthenticated]);

  const lastActiveCourseElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observerActive.current) observerActive.current.disconnect();
      observerActive.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMoreActive) {
            console.log(
              "Last active course is visible, loading more active courses..."
            );
            setActiveCoursesPage((prevPage) => prevPage + 1);
            setIsLoading(true);
          }
        },
        {
          threshold: 0.5, 
        }
      );
      if (node) observerActive.current.observe(node);
    },
    [isLoading, hasMoreActive]
  );

  const fetchCourses = () => {
    const searchParam = isOnSearchMode ? searchQuery : "";
    setIsLoading(true);
    listCourses(
      4,
      page,
      searchParam,
      selectedCategory,
      selectedCategory || isOnSearchMode ? "" : false,
      "",
      ""
    )
      .then((res) => {
        if (res?.data?.results) {
          setHasMore(!!res.data.next);
          if (selectedCategory || searchParam) {
            setCoursesCount(res.data.count);
          }

          if (hasMoreActive) return;

          setCourses((prevCourses) => {
            const uniqueCourses = [
              ...new Map(
                [...prevCourses, ...res?.data?.results].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ];
            return uniqueCourses;
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isOnSearchMode) {
      setCourses([]);
      setPage(1);
      setSelectedCategory("");

      setActiveCourses([]);
      setHasMoreActive(false);
    }
    if (hasMoreActive) {
      return;
    }
    console.log(
      "Fetching courses for page:",
      page,
      "search:",
      searchQuery,
      "category:",
      selectedCategory
    );

    fetchCourses();
  }, [page, searchQuery, selectedCategory, isOnSearchMode, hasMoreActive]);

  useEffect(() => {
    loadCourses(100);
  }, [subscribedCoursesPageSize]);

  const loadCourses = (page) => {
    if (isFetching) {
      console.log("loadCourses called while fetching, returning early");
      return;
    }

    console.log("loadCourses called with page:", page);
    setIsFetching(true);
    setIsLoading(true);
    listCourses(subscribedCoursesPageSize, 1, "", "", "true")
      .then((res) => {
        console.log("API response received:", res);
        if (res?.data?.results) {
          setSubscribedCourses(res.data.results);
          setTotalCourses(res?.data?.count || 0);
        }
        setIsLoading(false);
        setIsFetching(false);
      })
      .catch((err) => {
        console.log("API error:", err);
        setIsLoading(false);
        setIsFetching(false);
      });
  };

  // fetch categories

  useEffect(() => {
    const fetchCategories = async () => {
      setCategoriesLoading(true);

      try {
        const res = await listCategories();

        if (res?.data?.results) {
          setCategories(res?.data?.results);
          console.log("categories", res.data.results);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setCategoriesLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // fetch latest courses
  useEffect(() => {
    const fetchLatestCourses = async () => {
      setLatestCoursesLoading(true);

      const currentDate = new Date();
      const startDate = new Date();
      startDate.setDate(currentDate.getDate() - 60);

      const formattedStartDate = startDate.toISOString().slice(0, 10);
      const formattedEndDate = currentDate.toISOString().slice(0, 10);

      try {
        const res = await listCourses(
          4,
          1,
          "",
          "",
          "",
          "",
          "",
          "",
          formattedStartDate,
          formattedEndDate
        );

        if (res?.data?.results) {
          setLatestCourses(res?.data?.results);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLatestCoursesLoading(false);
      }
    };

    fetchLatestCourses();
  }, []);

  // start: active courses

  useEffect(() => {
    if (filterMode || isOnSearchMode || !isAuthenticated) {
      setHasMoreActive(false);
      return;
    } else {
      setCourses([]);
    }

    setIsLoading(true);

    listCourses(4, activeCoursesPage, "", "", true, "", "")
      .then((res) => {
        if (res?.data?.results) {
          setActiveCourses((prevCourses) => {
            const uniqueCourses = [
              ...new Map(
                [...prevCourses, ...res?.data?.results].map((item) => [
                  item.id,
                  item,
                ])
              ).values(),
            ];
            return uniqueCourses;
          });
          setHasMoreActive(!!res.data.next);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("activeCoerrursesPage", err);
        setIsLoading(false);
      });
  }, [activeCoursesPage, filterMode, isOnSearchMode]);

  // end: active courses

  // get count

  useEffect(() => {
    setCountLoading(true);

    listCourses(1, 1)
      .then((res) => {
        if (res?.data?.results && !filterMode && !isOnSearchMode) {
          setCoursesCount(res.data.count);
        }
        setCountLoading(false);
      })
      .catch((err) => {
        console.log("activeCoerrursesPage", err);
        setCountLoading(false);
      });
  }, [filterMode, isOnSearchMode]);

  useEffect(() => {
    if (!isOnSearchMode && isAuthenticated) {
      setHasMoreActive(true);
      setCourses([]);
    }
  }, [isOnSearchMode]);

  // get count end

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleCategoryClick = (category) => {
    setCoursesCount("");
    setPage(1);
    setCourses([]);
    setActiveCourses([]);
    setFilterMode(true);
    setHasMoreActive(false);
    setSelectedCategory(parseInt(category, 10));
  };

  const handleFavoriteClick = (courseId) => {
    const course = courses.find((c) => c.id === courseId);
    if (course.is_favorite) {
      // Remove from favorites
      removeFavorite(courseId)
        .then((res) => {
          console.log("Favorite bookmark removed successfully");
          // Update the course's is_favorite attribute
          setCourses(
            courses.map((c) =>
              c.id === courseId ? { ...c, is_favorite: false } : c
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Add to favorites
      addFavorite(courseId)
        .then((res) => {
          console.log("Favorite bookmark added successfully");
          // Update the course's is_favorite attribute
          setCourses(
            courses.map((c) =>
              c.id === courseId ? { ...c, is_favorite: true } : c
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

 
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box position="relative">
      {mobile_ssm && (
        <Box>
          <Fab
            size="small"
            aria-label="scroll back to top"
            onClick={scrollToTop}
            sx={{
              position: "fixed",
              bottom: 17,
              right: 17,
              zIndex: 50,
              opacity: isVisible ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
              boxShadow: "0px 10px 20px #00000012",
              backgroundColor: "#FCE181",
              color: "#026670",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#026670",
                color: "#FCE181",
              },
            }}
          >
            <KeyboardArrowUpIcon style={{ width: "28px", height: "28px" }} />
          </Fab>
        </Box>
      )}
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Hero
        title="Courses"
        onSearch={handleSearch}
        courses={courses}
        setCourses={setCourses}
        setIsOnSearchMode={setIsOnSearchMode}
        isOnSearchMode={isOnSearchMode}
        setPage={setPage}
        setHasMoreActive={setHasMoreActive}
        setFilterMode={setFilterMode}
        sx={{
          marginBottom: { xs: pxToRem(63), md: pxToRem(100) },
          overflow: isLargeScreen ? "visible" : "hidden",
        }}
      />

      <Box position="relative">
        <Box
          sx={{
            width: { xs: "100%", md: "90%" },
            margin: isMediumScreen ? "auto" : undefined,
          }}
        >
          <Stack
            flexDirection={isMediumScreen ? "column" : "row"}
            justifyContent="space-between"
            gap="5%"
            mb="100px"
            width="100%"
            maxWidth="1367px"
            position="relative"
          >
            {mobile_ssm && (
              <Box
                sx={{ height: "70px" }}
                marginBottom={{ xs: pxToRem(63), ssm: pxToRem(94), md: 0 }}
              ></Box>
            )}
            <Sidebar
              display="flex"
              sx={{
                gap: "0",
                padding: isMediumScreen ? "20px 25px" : "50px 30px 50px 30px",
                position: { xs: "absolute", ssm: "static" },
                zIndex: { xs: 2, md: 0 },
                mb: { ssm: pxToRem(94), md: 0 },
                mx: { ssm: "25px", md: 0 },
              }}
            >
              {isMediumScreen ? (
                <>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ cursor: "pointer" }}
                    onClick={handleToggle}
                  >
                    <Stack flexDirection="row" alignItems="center">
                      <FilterAltIcon
                        sx={{ width: pxToRem(33), height: pxToRem(33) }}
                      />
                      <Typography sx={{ fontWeight: 600, fontSize: "17px" }}>
                        Filters
                      </Typography>
                    </Stack>
                    <KeyboardArrowDownIcon
                      sx={{
                        width: pxToRem(31),
                        height: pxToRem(31),
                        transition: "all 300ms ease-in-out",
                        transform: isExpanded ? "rotate(-180deg)" : "rotate(0)",
                      }}
                    />
                  </Stack>
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <SidebarCourses
                      categories={categories}
                      subscribedCourses={subscribedCourses}
                      latestCourses={latestCourses}
                      handleCategoryClick={handleCategoryClick}
                      isAuthenticated={isAuthenticated}
                      isSmallScreen={isSmallScreen}
                      isMediumScreen={isMediumScreen}
                      selectedCategory={selectedCategory}
                      categoriesLoading={categoriesLoading}
                      latestCoursesLoading={latestCoursesLoading}
           
                    />
                  </Collapse>
                </>
              ) : (
                <SidebarCourses
                  categories={categories}
                  subscribedCourses={subscribedCourses}
                  latestCourses={latestCourses}
                  handleCategoryClick={handleCategoryClick}
                  isAuthenticated={isAuthenticated}
                  isSmallScreen={isSmallScreen}
                  isMediumScreen={isMediumScreen}
                  selectedCategory={selectedCategory}
                  categoriesLoading={categoriesLoading}
                  latestCoursesLoading={latestCoursesLoading}
                />
              )}
            </Sidebar>

            <Box
              padding={{ xs: "0 25px", md: "0" }}
              flex={1}
              width={isMediumScreen ? "100%" : "70%"}
            >
              {isAuthenticated ? <CoursesSlider /> : <></>}
                <CoursesSection
                  activeCourses={activeCourses}
                  courses={courses}
                  onFavoriteClick={handleFavoriteClick}
                  isAuthenticated={isAuthenticated}
                  lastCourseElementRef={lastCourseElementRef}
                  lastActiveCourseElementRef={lastActiveCourseElementRef}
                  loading={isLoading}
                  courseCount={courseCount}
                  isOnSearchMode={isOnSearchMode}
                  categoriesList={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setCourses={setCourses}
                  setPage={setPage}
                  setHasMoreActive={setHasMoreActive}
                  setFilterMode={setFilterMode}
                  setActiveCoursesPage={setActiveCoursesPage}
                />
            </Box>
          </Stack>
        </Box>
      </Box>

      <Container disableGutters>
        <ContentSlider />
      </Container>
    </Box>
  );
};

export default Courses;
