import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Avatar,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import { getGiftCheckoutLink } from "../../api/student";
import ModalHeader from "../../components/ModalHeader";
import FormInputText from "../../components/FormInputText";
import { useForm } from "react-hook-form";
import { isTodayBetweenDates } from "../../utils/format";
import { styled } from "@mui/system";
import { pxToRem } from "px2rem2px";

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: "30px",
    backgroundColor: "#EDECE8",
    height: "54px",
  },
  "& input": {
    borderRadius: "30px !important",
    padding: "15px 40px",
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

const GiftCourseModal = ({ open, handleClose, course }) => {
  const { control, setValue, handleSubmit, watch } = useForm();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const email = watch("loginEmail");
  const [optionalMessage, setOptionalMessage] = useState("");
  const [error, setError] = useState("");

  const handleGiftCourse = async (data, giftType) => {
    const email = data.loginEmail;
    const message = data.optionalMessage; // Capture the optional message
    if (email) {
      try {
        const response = await getGiftCheckoutLink(
          course.id,
          email,
          giftType,
          message
        );
        const checkoutUrl = response.data.checkout_url;
        window.location.href = checkoutUrl;
      } catch (error) {
        if (error.response && error.response.data.non_field_errors) {
          setError(error.response.data.non_field_errors[0]);
        } else {
          console.error("Error generating checkout link:", error);
        }
      }
    }
  };

  const handleDialogClose = () => {
    setSelectedStudent(null);
    handleClose();
    setError("")
  };

  //is on sale
  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

    useEffect(() => {
      if (email) {
        setError(""); // Clear the error when email changes
      }
    }, [email]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px",
          margin: {xs: "20px !important" , ssm: `${pxToRem(32)} !important`},
          maxWidth: {xs: "100%", sm: "500px"},
          width: {xs: "100%", sm: "500px"}
        },
      }}
    >
      <ModalHeader
        title="Gift Course to Someone"
        inModal={true}
        onClose={() => handleDialogClose()}
      />
      <DialogContent
        sx={{
          minHeight: "240px",
          // display: "flex",
          // flexDirection: "column",
          // padding: "20px",
          backgroundColor: "#f7f6f2",
          p: {xs: pxToRem(30), ssm: pxToRem(50) }
        }}
      >
        <form>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
           
            <Box>
              <FormInputText
                name="loginEmail"
                control={control}
                setValue={setValue}
                placeholder="Enter Email"
                muiProps={{ type: "email", error: !!error }} 
                rules={{
                  required: "Field can't be empty",
                  maxLength: {
                    value: 100,
                    message: "Maximum 100 characters",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
              />
              {error && (
                <FormHelperText error sx={{fontSize:"14px",marginLeft:"14px"}}>{error}</FormHelperText> 
              )}
            </Box>

            <Box>
              <FormInputText
                name="optionalMessage"
                control={control}
                setValue={setValue}
                placeholder="Add a Message (Optional)"
                muiProps={{ type: "text" }}
                rules={{
                  maxLength: {
                    value: 100,
                    message: "Maximum 100 characters",
                  },
                }}
              />
            </Box>

            <Box
              mt={1}
              sx={{ display: "flex", flexDirection: {xs: "column", ssm: "row"}, justifyContent: "space-between", gap: pxToRem(16) }}
            >
              <Button
                variant="outlined"
                disabled={!email}
                onClick={handleSubmit((data) =>
                  handleGiftCourse(data, "Normal")
                )}
                sx={{ width: "48%", fontSize: "14px" }}
              >
                {isOnSale ? (
                  <>
                    <Typography component="span" fontWeight={600}>
                      {`Subscription: $${course.sale_price}`}
                    </Typography>

                    <Typography
                      fontSize="13px"
                      fontWeight="600"
                      color="#4a4a4a"
                      sx={{
                        textDecoration: "line-through",
                        marginLeft: 1,
                        // transform: "translateY(0.9px)",
                      }}
                    >
                      {course.subscription_price > 0 &&
                        `$${course.subscription_price}`}
                    </Typography>
                  </>
                ) : (
                  <Typography component="span" fontWeight={600}>
                    {`Subscription: $${course.subscription_price}`}
                  </Typography>
                )}
              </Button>
              <Button
                variant="yellow"
                disabled={!email}
                onClick={handleSubmit((data) =>
                  handleGiftCourse(data, "Lifetime")
                )}
                sx={{ width: "48%", fontSize: "14px", padding: "0" }}
              >
                Lifetime {`$${course.lifetime_price}`}
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default GiftCourseModal;
