import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import PropTypes from "prop-types";
import { getLanguageClass } from "../../utils/languageClass";

const RichTextDisplay = ({
  str,
  showFull = false,
  onContentExceedsMaxHeight = () => {},
  isPresubscribe = false,
}) => {
  const contentRef = useRef(null);
  const [contentExceedsMaxHeight, setContentExceedsMaxHeight] = useState(false);

  useEffect(() => {
    if (contentRef.current) {
      const exceedsMaxHeight = contentRef.current.scrollHeight > 400;
      setContentExceedsMaxHeight(exceedsMaxHeight);
      onContentExceedsMaxHeight(exceedsMaxHeight);
    }
  }, [str, onContentExceedsMaxHeight]);

  if (!str) {
    return null;
  }

  const content = str;

  return (
    <div
      style={{
        position: "relative",
        maxHeight: showFull ? "none" : "400px",
        overflow: "hidden",
      }}
    >
      <div
        ref={contentRef}
        className={`rich-text-display ${
          showFull ? "expanded" : ""
        } ${getLanguageClass(content)}`}
        dangerouslySetInnerHTML={{ __html: content }}
        style={{
          fontWeight: 500,
          maxHeight: showFull ? "none" : "400px",
          overflow: "hidden",
        }}
      />
      {!showFull && contentExceedsMaxHeight && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "50px",
            background: isPresubscribe
              ? " linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)"
              : "linear-gradient(to bottom, rgba(247, 246, 242, 0) 0%, rgba(247, 246, 242, 1) 100%)",

            pointerEvents: "none",
          }}
        />
      )}
    </div>
  );
};

RichTextDisplay.propTypes = {
  str: PropTypes.string.isRequired,
  showFull: PropTypes.bool,
  onContentExceedsMaxHeight: PropTypes.func,
};

export default RichTextDisplay;
