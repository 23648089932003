import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Link as MuiLink,
  Stack,
} from "@mui/material";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import { pxToRem } from "px2rem2px";
import HeaderBgrImg from "../../images/bgr-short.svg";
import BlockBordered from "../../components/BlockBordered";
import Philosophy from "../../images/philosophy-img.png";
import CustomerService from "../../images/customer-service.svg";
import Help from "../../images/help.svg";
import FormInputText from "../../components/FormInputText";
import { useForm } from "react-hook-form";
import ContactCard from "../../components/ContactCard";
import { useEffect } from "react";
import { getLanguageClass } from "../../utils/languageClass";
const contactHelpAndSupportCardsDetails = [
  {
    icon: Philosophy,
    title: "Need 1 on 1 help?",
    description:
      "Talk to <span style='font-weight: 700;'> Reb Yakov Moshe!</span> Schedule an appointment now to get personalized guidance and support. He’s here to answer your questions and provide the help you need. <span style='font-weight: 700;'>Book your session today!</span>",
    btnName: "Schedule Appointment",
    rounded: true,
  },
  {
    icon: Help,
    title: "Have a Questions?",
    description:
      "Find answers to common questions in our <span style='font-weight: 700;'>FAQ section.</span> Explore topics on account management, website navigation, technical issues, troubleshooting, and much more related.",
    btnName: "FAQ",
  },
  {
    icon: CustomerService,
    title: "Technical Support",
    description:
      "For <span style='font-weight: 700;'> website or account support,</span> contact our technical support team for quick and efficient assistance with any issues you encounter.",
    btnName: "Contact Support",
  },
];

const Contact = () => {
  const {
    control,
    // handleSubmit,
    setValue,
    // setError,
    // clearErrors,
    // formState: { errors },
  } = useForm({
    values: {
      fullName: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  useEffect(() => {
    document.title = "Contact and Support - Reb Yakov Moshe";
  }, []);

  const tempText =
    "Get the assistance you need with our comprehensive support options.";
  const tempText2 =
    "We’re here to help and answer any question you might have. We look forward to hearing from you.";

  return (
    <>
      <Box position="relative">
        <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
        <Box
          display="flex"
          height={{ xs: "100px", md: pxToRem(130) }}
          borderRadius={{ xs: 0, md: "40px 40px 0 0" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url("${HeaderBgrImg}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <Header menuVisible hideUserProfile />
          <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
        </Box>
        <Container sx={{ px: pxToRem(30) }} disableGutters>
          <Box
            sx={{
              paddingTop: {
                xs: pxToRem(55),
                ssm: pxToRem(60),
                sm: pxToRem(88),
              },
            }}
          >
            <Box maxWidth={pxToRem(552)}>
              <Typography
                variant="sectionTitle"
                component="h2"
                mb={{ xs: "20px", sm: pxToRem(23) }}
                fontSize={{ xs: "30px", md: pxToRem(45) }}
              >
                Help &<span className="highlighted"> Support</span>
              </Typography>
              <BlockBordered
                mb={pxToRem(40)}
                isAlignedRight={getLanguageClass(tempText) === "is-hebrew-text"}
              >
                <Typography
                  maxWidth={pxToRem(522)}
                  className={getLanguageClass(tempText)}
                >
                  {tempText}
                </Typography>
              </BlockBordered>
            </Box>
          </Box>
          <Grid
            container
            rowSpacing={{ xs: pxToRem(20), md: pxToRem(25), lg: pxToRem(120) }}
            columnSpacing={{ xs: pxToRem(20), md: pxToRem(25) }}
          >
            <ContactCard
              cardsDetails={contactHelpAndSupportCardsDetails}
              gridSm={6}
              gridLg={4}
            />
            <Grid item sm={6}>
              <Box
                maxWidth={{
                  xs: "100%",
                  sm: pxToRem(380),
                  md: pxToRem(450),
                  lg: pxToRem(520),
                }}
                marginLeft={{ ssm: "auto", lg: 0 }}
              >
                <Typography
                  variant="sectionTitle"
                  component="h2"
                  mb={{ xs: "20px", sm: pxToRem(23) }}
                  mt={{ xs: pxToRem(60), sm: pxToRem(45), lg: pxToRem(10) }}
                  fontSize={{ xs: "30px", md: pxToRem(45) }}
                >
                  Help &<span className="highlighted"> Support</span>
                </Typography>
                <BlockBordered
                  mb={pxToRem(40)}
                  isAlignedRight={
                    getLanguageClass(tempText2) === "is-hebrew-text"
                  }
                >
                  <Typography
                    maxWidth={pxToRem(522)}
                    className={getLanguageClass(tempText2)}
                  >
                    {tempText2}
                  </Typography>
                </BlockBordered>
                <Box
                  sx={{
                    backgroundColor: "#026670",
                    borderRadius: pxToRem(10),
                    color: "#ffffff",
                    padding: {
                      xs: `${pxToRem(50)} ${pxToRem(18)}`,
                      ssm: `${pxToRem(50)} ${pxToRem(25)}`,
                      md: pxToRem(50),
                    },
                  }}
                >
                  <Typography
                    variant="blockTitle"
                    component="h3"
                    fontSize={pxToRem(25)}
                    mb={pxToRem(20)}
                  >
                    Get in Touch Directly
                  </Typography>
                  <Stack flexDirection="row" alignItems="baseline">
                    <Typography
                      width={pxToRem(70)}
                      variant="large"
                      fontWeight="500"
                      color="#BFBEBB"
                    >
                      Phone:
                    </Typography>
                    <MuiLink
                      underline="hover"
                      href="tel:2126665433"
                      color="#fff"
                      fontWeight={700}
                      fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                    >
                      212 666 5433 &gt; 1 &gt; 5
                    </MuiLink>
                  </Stack>
                  <Stack flexDirection="row" alignItems="baseline">
                    <Typography
                      width={pxToRem(70)}
                      variant="large"
                      fontWeight="500"
                      color="#BFBEBB"
                    >
                      Email:
                    </Typography>
                    <MuiLink
                      underline="hover"
                      href="mailTo:mosesganz@gmail.com"
                      color="#FCE181"
                      sx={{ wordBreak: "break-all" }}
                      fontWeight={700}
                      fontSize={{ xs: pxToRem(16), ssm: pxToRem(18) }}
                    >
                      mosesganz@gmail.com
                    </MuiLink>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  padding: {
                    xs: `${pxToRem(50)} ${pxToRem(20)}`,
                    ssm: pxToRem(50),
                  },
                  borderRadius: pxToRem(20),
                  mb: { xs: pxToRem(70), ssm: pxToRem(80), lg: pxToRem(120) },
                  mt: { xs: pxToRem(60), lg: 0 },
                }}
              >
                <form noValidate>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: { xs: pxToRem(30), ssm: pxToRem(20) },
                    }}
                  >
                    <Stack
                      flexDirection={{ xs: "column", ssm: "row" }}
                      alignItems={{ xs: "flex-start", ssm: "center" }}
                      gap={{ xs: pxToRem(10), ssm: 0 }}
                    >
                      <Typography
                        width={{
                          xs: "auto",
                          ssm: pxToRem(160),
                          lg: pxToRem(137),
                        }}
                        variant="medium"
                      >
                        Full Name
                      </Typography>
                      <FormInputText
                        name="fullName"
                        control={control}
                        setValue={setValue}
                        sx={{
                          flexGrow: "1",
                          width: { xs: "100%", ssm: "auto" },
                          "& input": {
                            "&:focus": { outline: "2px solid #333" },
                          },
                        }}
                        rules={{
                          required: "Field can't be empty",
                          minLength: {
                            value: 3,
                            message: "Minimum 3 characters",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum 50 characters",
                          },
                        }}
                        muiProps={{
                          type: "text",
                        }}
                      />
                    </Stack>
                    <Stack
                      flexDirection={{ xs: "column", ssm: "row" }}
                      alignItems={{ xs: "flex-start", ssm: "center" }}
                      gap={{ xs: pxToRem(10), ssm: 0 }}
                    >
                      <Typography
                        width={{
                          xs: "auto",
                          ssm: pxToRem(160),
                          lg: pxToRem(137),
                        }}
                        variant="medium"
                      >
                        Email Address
                      </Typography>
                      <FormInputText
                        name="email"
                        control={control}
                        setValue={setValue}
                        sx={{
                          flexGrow: "1",
                          width: { xs: "100%", ssm: "auto" },
                          "& input": {
                            "&:focus": { outline: "2px solid #333" },
                          },
                        }}
                        rules={{
                          required: "Field can't be empty",
                          minLength: {
                            value: 3,
                            message: "Minimum 3 characters",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum 50 characters",
                          },
                        }}
                        muiProps={{
                          type: "email",
                        }}
                      />
                    </Stack>
                    <Stack
                      flexDirection={{ xs: "column", ssm: "row" }}
                      alignItems={{ xs: "flex-start", ssm: "center" }}
                      gap={{ xs: pxToRem(10), ssm: 0 }}
                    >
                      <Typography
                        width={{
                          xs: "auto",
                          ssm: pxToRem(160),
                          lg: pxToRem(137),
                        }}
                        variant="medium"
                      >
                        Subject
                      </Typography>
                      <FormInputText
                        name="subject"
                        control={control}
                        setValue={setValue}
                        sx={{
                          flexGrow: "1",
                          width: { xs: "100%", ssm: "auto" },
                          "& input": {
                            "&:focus": { outline: "2px solid #333" },
                          },
                        }}
                        rules={{
                          required: "Field can't be empty",
                          minLength: {
                            value: 3,
                            message: "Minimum 3 characters",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum 50 characters",
                          },
                        }}
                        muiProps={{
                          type: "text",
                        }}
                      />
                    </Stack>
                    <Stack
                      flexDirection={{ xs: "column", ssm: "row" }}
                      alignItems="flex-start"
                      gap={{ xs: pxToRem(10), ssm: 0 }}
                    >
                      <Typography
                        width={{
                          xs: "auto",
                          ssm: pxToRem(160),
                          lg: pxToRem(137),
                        }}
                        pt={{ ssm: pxToRem(16) }}
                        variant="medium"
                      >
                        Message
                      </Typography>
                      <FormInputText
                        name="message"
                        control={control}
                        setValue={setValue}
                        sx={{
                          flexGrow: "1",
                          width: { xs: "100%", ssm: "auto" },
                          "& .MuiInputBase-root": {
                            height: "auto",
                            borderRadius: pxToRem(10),
                            padding: 0,
                          },
                        }}
                        rules={{
                          required: "Field can't be empty",
                          minLength: {
                            value: 3,
                            message: "Minimum 3 characters",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximum 50 characters",
                          },
                        }}
                        muiProps={{
                          type: "text",
                          multiline: true,
                          rows: 7,
                        }}
                      />
                    </Stack>
                    <Box sx={{ textAlign: { ssm: "center" } }}>
                      <Button
                        variant="yellow"
                        sx={{ mt: { md: pxToRem(20) }, height: pxToRem(54) }}
                      >
                        Send Email
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Contact;
