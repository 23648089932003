export default function transformArrayToCloud(arrayA, arrayBTemplate) {
  const fontSizes = arrayBTemplate.map((item) => item.style.fontSize);
  const sortedIndices = [...fontSizes.keys()].sort(
    (a, b) => fontSizes[b] - fontSizes[a]
  );
  const arrayB = arrayBTemplate.map((item) => ({ ...item }));
  sortedIndices.forEach((sortedIndex, i) => {
    if (i < arrayA.length) {
      arrayB[sortedIndex].text = arrayA[i];
    }
  });
  return arrayB;
}
