import {
  Avatar,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../images/search.svg";
import { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import { Box } from "@mui/system";
import { isTodayBetweenDates } from "../../utils/format";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { pxToRem } from "px2rem2px";

const Search = ({
  onSearch,
  courses,
  setCourses,
  setIsOnSearchMode,
  isOnSearchMode,
  setPage,
  setHasMoreActive,
  setFilterMode,
}) => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const debouncedSearch = debounce((searchQuery) => {
    if (onSearch) {
      onSearch(searchQuery);
    }
  }, 200);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  const cleanInputChanges = () => {
    setQuery("");
    setCourses([]);
    setPage(1);
    setIsOnSearchMode(false);
    setHasMoreActive(true)
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.trim() === "") {
      cleanInputChanges();
    } else {
      debouncedSearch(value);
      setIsOnSearchMode(true);
    }
    setFilterMode(false)
  };

  const handleClearInput = () => {
    cleanInputChanges();
  };
  useEffect(() => {
    if (isOnSearchMode) {
      cleanInputChanges();
    }
  }, [location.pathname]);

  const handleCourseClick = (isSubscribed, slug) => {
    navigate(isSubscribed ? `/course/${slug}` : `/course/${slug}/presubscribe`);
  };

  return (
    <Box sx={{ position: "relative", width: "100%", maxWidth: 460, margin: "0 auto" }}>
      <Paper
        component="form"
        elevation={0}
        sx={{
          p: "16px 30px 16px 40px",
          display: "flex",
          alignItems: "center",
          maxWidth: 460,
          width: "100%",
          height: 54,
          borderRadius: "calc(infinity * 1px)",
        }}
      >
        <InputBase
          value={query}
          onChange={handleInputChange}
          sx={{ flex: 1 }}
          inputProps={{ style: { fontSize: "16px", fontWeight: 600 } }}
          placeholder="Search courses"
        />
        {query ? (
          <IconButton
            sx={{ p: "10px", color: "#026670" }}
            onClick={handleClearInput}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton sx={{ p: "10px" }}>
            <SearchIcon style={{color: "#026670"}} />
          </IconButton>
        )}
      </Paper>
      {isOnSearchMode && courses?.length > 0 && (
        <Paper
          sx={{
            position: "absolute",
            top: "60px",
            width: "100%",
            maxHeight: 300,
            overflowY: "auto",
            zIndex: 10,
            borderRadius: pxToRem(20)
          }}
        >
          <List>
            {courses?.map((result, index) => {
              const isOnSale =
                result?.is_on_sale &&
                isTodayBetweenDates(
                  result?.sale_start_date,
                  result?.sale_end_date
                );

              return (
                <ListItem
                  button
                  key={index}
                  sx={{ alignItems: "flex-start" }}
                  onClick={() =>
                    handleCourseClick(result.is_subscribed, result.slug)
                  }
                >
                  <Avatar
                    variant="square"
                    src={result.thumb_nail}
                    alt={result.name}
                    sx={{ width: 60, height: 60, mr: 2 }}
                  />
                  <Box sx={{ maxWidth: "calc(100% - 80px)" }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {result.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {result.subtitle}
                    </Typography>
                    {isOnSale ? (
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Typography
                          fontSize="20px"
                          lineHeight="25px"
                          fontWeight="700"
                          color="#026670"
                        >
                          {result.sale_price <= 0
                            ? "Free"
                            : `$${result.sale_price}`}
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight="600"
                          color="#BFBEBB"
                          sx={{
                            textDecoration: "line-through",
                            transform: "translateY(3px)",
                          }}
                        >
                          {result.subscription_price > 0 &&
                            `$${result.subscription_price}`}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography
                        fontSize="20px"
                        lineHeight="25px"
                        fontWeight="700"
                        color="#026670"
                      >
                        {result.subscription_price <= 0
                          ? "Free"
                          : `$${result.subscription_price}`}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default Search;
