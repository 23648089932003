import React from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/system";
import GradientAnimation from "../../components/GradientAnimation";
import Header from "../../components/Header";
import DonationAmount from "./DonationAmount";
import SustainerOptions from "./SustainerOptions";
import TreeImg from "../../images/footer-tree.svg";
import PersonalDetails from "./PersonalDetails";
import { useModal } from "mui-modal-provider";
import ThankModal from "./ThankModal";
import { useEffect } from "react";
const PageTitleHighlighted = styled(Typography)(({ theme }) => {
  return {
    position: "relative",
    fontFamily: "PloniBold",
    fontSize: pxToRem(70),
    lineHeight: pxToRem(65),
    "&::after": {
      content: "''",
      width: "104%",
      height: pxToRem(20),
      position: "absolute",
      left: "50%",
      bottom: pxToRem(12),
      transform: "translateX(-50%)",
      backgroundColor: "rgba(252,225,129,0.5)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      lineHeight: "50px",
      "&::after": {
        bottom: "6px",
      },
    },
  };
});

const TotalAmount = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "400px",
    height: "72px",
    borderRadius: "calc(infinity * 1px)",
    backgroundColor: "#EDECE8",
    fontSize: "40px",
    fontWeight: 700,
    color: "#026670",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      height: "48px",
    },
    [theme.breakpoints.down("ssm")]: {
      width: "100%",
    },
  };
});

const Donate = () => {
  const { showModal } = useModal();
  useEffect(() => {
    document.title = "Make a Donation - Reb Yakov Moshe";
  }, []);
  return (
    <>
      <Box
        position="relative"
        pt={pxToRem(30)}
        pb={pxToRem(40)}
        minHeight="540px"
        sx={{
          borderRadius: { xs: 0, md: pxToRem(40) },
          "&::before": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-125%)",
            zIndex: 2,
            background: `url(${TreeImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: pxToRem(520),
            height: pxToRem(600),
            opacity: 0.4,
            "@media(max-width: 1300px)": {
              left: "30px",
              transform: "none",
            },
            "@media(max-width: 899px)": {
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
      >
        <GradientAnimation />
        <Header menuVisible />
        <Stack
          className="header-padding"
          position="relative"
          maxWidth="683px"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          zIndex={2}
          margin={{ xs: "20px auto 0", md: "64px auto 0" }}
          padding="0 25px"
        >
          <Typography
            component="h1"
            fontFamily="PloniBold"
            fontSize={{ xs: pxToRem(50), sm: pxToRem(70) }}
            lineHeight={{ xs: "50px", sm: pxToRem(60) }}
            mb={{ xs: "70px", md: pxToRem(100) }}
          >
            לאמיר{" "}
            <PageTitleHighlighted component="span">העלפן</PageTitleHighlighted>{" "}
            ר' יעקב משה העלפן אידן
          </Typography>
          <Button variant="green" sx={{ color: "white" }}>
            Donate
          </Button>
        </Stack>
      </Box>

      <Container disableGutters sx={{ my: { xs: "50px", sm: "100px" } }}>
        <DonationAmount />
        <SustainerOptions />
        <PersonalDetails />
        <Stack
          flexDirection={{ xs: "column", ssm: "row" }}
          justifyContent="flex-end"
          gap="20px"
          mt={{ xs: "37px", smm: "60px" }}
        >
          <TotalAmount>$100</TotalAmount>
          <Button
            variant="green"
            onClick={() => showModal(ThankModal)}
            sx={{
              borderRadius: "calc(infinity * 1px)",
              fontSize: { xs: "18px", sm: "20px" },
              height: { xs: "48px", sm: "auto" },
              flexShrink: 0,
            }}
          >
            Donate Now!
          </Button>
        </Stack>
      </Container>
    </>
  );
};

export default Donate;
