import { Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { pxToRem } from "px2rem2px";

export const ButtonLogin = styled(Button)({
  width: pxToRem(300),
  height: `${pxToRem(54)} !important`,
  fontSize: pxToRem(14),
  fontWeight: 600,
  boxShadow: "none",
  margin: `${pxToRem(20)} 0`,
});

export const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)}`,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  "@media(max-width: 380px)": {
    "& .MuiInputBase-root": {
      height: pxToRem(44),
    },
    "& input": {
      padding: `${pxToRem(10)} ${pxToRem(30)}`,
    },
  },
});
