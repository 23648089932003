
export const getTruncatedText = (container, text, fontSize, fontWeight, buffer = 0, diff = 0) =>  {
    if (!container || !text) return text;
  
    const containerWidth = container.offsetWidth;
  
    
    const tempElement = document.createElement('span');
    tempElement.style.visibility = 'hidden';
    tempElement.style.whiteSpace = 'nowrap';
    tempElement.style.fontSize = `${fontSize}px`;
    tempElement.style.fontWeight = fontWeight.toString();
    tempElement.innerText = text;
    document.body.appendChild(tempElement);
  
    const textWidth = tempElement.offsetWidth;
    document.body.removeChild(tempElement);
  
    
    let charWidth = textWidth / text.length; 
    let maxChars = Math.floor((containerWidth - buffer) / charWidth) - diff; 
  
    if (text.length > maxChars) {
      return `${text.substring(0, maxChars)}...`;
    } else {
      return text;
    }
  };