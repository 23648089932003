import React from "react";
import { Link, Box, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { pxToRem } from "px2rem2px";

const LinkBtn = (props) => {
  const {
    title,
    footerLink,
    component,
    useFirstStyle = false,
    newTab = false,
    submenus = [],
    isSideBar,
    ...rest
  } = props;

  const sideBarStyle = isSideBar
    ? { color: "secondary.main", fontWeight: 700, fontSize: "24px" }
    : {};
  const location = useLocation(); // Get current path
  const isActive =
    location.pathname === rest.url ||
    (submenus.length > 0) &
      submenus.some((item) => item.url === location.pathname);

  return (
    <Typography
      {...rest}
      onClick={() => {
        if (newTab && rest.url) {
          window.open(rest.url, "_blank"); // Open in a new tab
        } else if (!newTab && rest.url) {
          window.open(rest.url, "_self");
        }
      }}
      //component={component ?? NavLink}
      underline="none"
      sx={{
        textDecoration: "none",
        color: footerLink
          ? isActive
            ? "#fff" // Active footer link color
            : "#BFBEBB" // Inactive footer link color
          : isActive
          ? "secondary.main" // Active primary link color
          : "#333", // Inactive primary link color
        fontSize: pxToRem(16),
        fontWeight: 600,
        position: "relative",
        width: useFirstStyle ? "100%" : undefined,
        display: useFirstStyle ? "block" : undefined,
        cursor: "pointer",
        userSelect: "none",
        caretColor: "transparent",
        "&:hover": {
          color: footerLink ? "#fff" : "primary.main",
        },
        ...(useFirstStyle
          ? {
              "&:hover span::after": {
                width: "100%",
              },
              "&.active": {
                color: "#333",
              },
            }
          : {
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                bottom: pxToRem(-8),
                height: "2px",
                width: 0,
                bgcolor: footerLink ? "#fff" : "primary.main",
                transition: "0.2s ease",
              },
              "&:hover::before": {
                width: "100%",
              },
              "&.active": {
                color: footerLink ? "#BFBEBB" : "#333",
              },
            }),
        ...sideBarStyle,
      }}
    >
      {useFirstStyle ? (
        <Box
          component="span"
          sx={{
            position: "relative",
            display: "inline-block",
            color: "inherit",
            "&::after": {
              content: '""',
              position: "absolute",
              left: 0,
              bottom: pxToRem(-2),
              height: "2px",
              width: "0%",
              backgroundColor: footerLink ? "#fff" : "primary.main",
              transition: "width 0.2s ease",
            },
          }}
        >
          {title}
        </Box>
      ) : (
        title
      )}
    </Typography>
  );
};

export default LinkBtn;
