import { Box, Button, Stack, Typography } from "@mui/material";
import ModalLayout from "../preSubscribe/ModalLayout";
import { pxToRem } from "px2rem2px";

import { ReactComponent as Check } from "../../images/check.svg";

const ThankModal = (props) => {
  return (
    <ModalLayout {...props} headerTitle="Thank you for your generous donation">
      <Box textAlign="center">
        <Stack
          position="absolute"
          top="-27px"
          left="50%"
          alignItems="center"
          justifyContent="center"
          bgcolor="#026670"
          width={pxToRem(54)}
          height={pxToRem(54)}
          borderRadius="100%"
          sx={{ transform: "translateX(-50%)" }}
        >
          <Check width={16} height={16} color="#FCE181" />
        </Stack>
        <Typography
          component="p"
          fontSize={pxToRem(20)}
          fontWeight={700}
          sx={{ marginTop: pxToRem(10) }}
        >
          Thank you.
          <br /> Payment Successful!
        </Typography>
        <Typography
          variant="medium"
          component="p"
          fontSize={pxToRem(16)}
          mt={pxToRem(20)}
        >
          An email confirmation was sent.
        </Typography>
        <Button
          variant="yellow"
          onClick={() => props.onClose()}
          sx={{
            width: { xs: "100%", ssm: "300px" },
            maxWidth: { xs: "300px", ssm: "none" },
            mt: pxToRem(40),
            height: pxToRem(54),
          }}
        >
          Done
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default ThankModal;
