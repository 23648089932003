import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import { useEffect } from "react";
import PresentationImg from "../../images/presentation-img.jpg";
import HeaderBgrImg from "../../images/bgr-short.svg";
import LinkWithIcon from "../../components/LinkWithIcon";
import { Stack, styled } from "@mui/system";

const SingleBlog = () => {
  useEffect(() => {
    document.title = "Blog";
  }, []);

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const Label = styled(Typography)(({ theme }) => {
    return {
      display: "flex",
      alignItems: "center",
      height: "20px",
      fontSize: "12px",
      fontWeight: 600,
      color: "#026670",
      backgroundColor: "#FFFFFF",
      // border: "2px solid #f5e093",
      borderRadius: "calc(infinity * 1px)",
      padding: "0 15px",
    };
  });

  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Box
        display="flex"
        height={{ xs: "100px", md: pxToRem(130) }}
        borderRadius={{ xs: 0, md: "40px 40px 0 0" }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url("${HeaderBgrImg}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <Header menuVisible hideUserProfile />
        <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
      </Box>
      <Container
        maxWidth="md"
        sx={{ px: 0, py: { xs: pxToRem(77), md: pxToRem(80) } }}
        disableGutters
      >
        <LinkWithIcon
          to="/courses"
          className="rtl-section"
          sx={{ marginTop: pxToRem(14) }}
          style={{ display: tablet ? "none" : "" }}
        >
          All Blogs
        </LinkWithIcon>
        <Box sx={{ marginTop: pxToRem(40), marginBottom: pxToRem(20) }}>
          <Label
            sx={{
              height: "24px",
              border: "2px solid #f5e093",
              backgroundColor: "#F9EBB9",
              display: "inline-block",
              minWidth: pxToRem(122),
              textAlign: "center",
              fontSize: pxToRem(14)
            }}
          >
            Blog
          </Label>
        </Box>
        <Typography
          component="h2"
          sx={{
            fontSize: pxToRem(30),
            fontWeight: 700,
            lineHeight: pxToRem(40),
            marginBottom: pxToRem(20),
          }}
        >
          The Power of Consistency in Learning: How Small Daily Actions Lead to
          Big Changes Over Time
        </Typography>
        <Typography
          component="p"
          fontSize={pxToRem(16)}
          lineHeight={pxToRem(25)}
          fontWeight={600}
          color="#BFBEBB"
          marginBottom={pxToRem(90)}
        >
          Reb Yakov Moshe • 11/24/2024
        </Typography>
        <Box
          paddingLeft={{ xs: "25px", sm: "30px", md: pxToRem(40) }}
          paddingRight={{ xs: "25px", sm: "30px", md: pxToRem(40) }}
        >
          <Box
            position="relative"
            // maxWidth={{ xs: "300px", sm: "310px", md: pxToRem(500) }}
            width="100%"
            // height={{ xs: "310px", md: "auto" }}
            display="flex"
            borderRadius={pxToRem(20)}
            // boxShadow={`${pxToRem(30)} ${pxToRem(30)} ${pxToRem(
            //   50
            // )} rgba(0,0,0,0.2)`}
            flexShrink={0}
            sx={{
              "&::before": {
                content: "''",
                position: "absolute",
                left: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
                top: { xs: "-30px", md: pxToRem(-40) },
                width: { xs: "200px", sm: "310px", md: "88%" },
                height: { xs: "310px", md: "74.1%" },
                bgcolor: "#FCE181",
                borderRadius: pxToRem(20),
                zIndex: -1,
              },
            }}
          >
            <img
              src={PresentationImg}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: pxToRem(20),
              }}
            />
          </Box>

        </Box>
        <Stack gap={pxToRem(30)} sx={{marginTop: pxToRem(60), paddingRight: { xs: "25px", sm: "30px", md: pxToRem(40) }}}>
          <Typography sx={{fontSize: pxToRem(20), lineHeight: pxToRem(32)}}>In a world full of distractions and quick fixes, consistency is the true key to achieving lasting knowledge. Whether you are learning a new language, mastering a musical instrument, or deepening your understanding of Torah, steady progress over time always trumps sporadic bursts of enthusiasm.</Typography>
          <Typography sx={{fontSize: pxToRem(20), lineHeight: pxToRem(32)}}>Reb Yakov Moshe often emphasizes the value of small, consistent steps. It's not about making giant leaps in a day but about taking deliberate, meaningful steps every day. Imagine your mind as a garden—you cannot water it once a month and expect it to flourish. The daily nurturing, the constant care, makes the difference between a barren field and a vibrant, fruitful one.</Typography>
          <Typography sx={{fontSize: pxToRem(20), lineHeight: pxToRem(32)}}>Take, for example, the practice of studying Torah. Setting aside even just 15 minutes every day can lead to incredible growth over time. It is during these small moments of learning that you build not only your knowledge but also your character. These moments turn into habits, and habits form who we are.</Typography>
          <Typography sx={{fontSize: pxToRem(20), lineHeight: pxToRem(32)}}>So, next time you feel overwhelmed or feel like you aren't making enough progress, remember that the real secret lies in being consistent, even when it feels like the gains are small. Trust the process, nurture the garden of your mind, and watch how, over time, it blossoms into something beautiful.</Typography>
          <Typography sx={{fontSize: pxToRem(20), lineHeight: pxToRem(32)}}>In a world full of distractions and quick fixes, consistency is the true key to achieving lasting knowledge. Whether you are learning a new language, mastering a musical instrument, or deepening your understanding of Torah, steady progress over time always trumps sporadic bursts of enthusiasm.</Typography>
          <Typography sx={{fontSize: pxToRem(20), lineHeight: pxToRem(32)}}> Reb Yakov Moshe often emphasizes the value of small, consistent steps. It's not about making giant leaps in a day but about taking deliberate, meaningful steps every day. Imagine your mind as a garden—you cannot water it once a month and expect it to flourish. The daily nurturing, the constant care, makes the difference between a barren field and a vibrant, fruitful one.</Typography>
          <Typography sx={{fontSize: pxToRem(20), lineHeight: pxToRem(32)}}>Take, for example, the practice of studying Torah. Setting aside even just 15 minutes every day can lead to incredible growth over time. It is during these small moments of learning that you build not only your knowledge but also your character. These moments turn into habits, and habits form who we are.</Typography>
        </Stack>
      </Container>
    </Box>
  );
};
export default SingleBlog;
