import { useEffect, useState } from "react";
import Courses from "./Courses";
import Questions from "./Questions";
import { Skeleton, Typography } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { ReactComponent as HeartEmpty } from "../../../images/heart-empty.svg";
import { Box } from "@mui/system";

const Favorites = () => {
  const [noFavorites, setNoFavorites] = useState(false);
  const [noFavCourses, setNoFavCourses] = useState(false);
  const [noFavQuestion, setNoFavQuestion] = useState(false);
  const [favCoursesLoaded, setFavCoursesLoaded] = useState(false);
  // const [isQuestionLoading, setIsQuestionLoading] = useState(false);

  useEffect(() => {
    if (noFavQuestion && noFavCourses) {
      setNoFavorites(true);
    } else {
      setNoFavorites(false);
    }
  }, [noFavQuestion, noFavCourses]);

  return (
    <>
     {noFavorites && (
  favCoursesLoaded ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 1,
      }}
    >
      <Typography component="h3" variant="blockTitle" textAlign="center">
        No Favorites Yet.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 0.5,
          marginTop: "10px",
        }}
      >
        <Typography
          component="time"
          fontSize="14px"
          fontWeight={600}
          color="#BFBEBB"
        >
          You can add a course or Q&A to your Favorites by clicking the
        </Typography>
        <Box component="span">
          <HeartEmpty style={{ width: pxToRem(19) }} />
        </Box>
        <Typography
          component="time"
          fontSize="14px"
          fontWeight={600}
          color="#BFBEBB"
        >
          icon.
        </Typography>
      </Box>
    </Box>
  ) : null 
)}

      <Courses
        setNoFavCourses={setNoFavCourses}
        setFavCoursesLoaded={setFavCoursesLoaded}
      />
      <Questions
        setNoFavQuestion={setNoFavQuestion}
        // setIsQuestionLoading={setIsQuestionLoading}
      />
    </>
  );
};

export default Favorites;
