import { Box, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { verifyphone  } from "../../api/student";
import FormInputText from "../../components/FormInputText";

export default function VerifyPhone() {

    const [isloading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState()
    const [successMsg, setSuccessMsg] = useState()

    const verificationForm = useForm({
        values: {
          phone: "",
          code: "",
        },
    });
    
    function handleVerify(data, e) {
        setIsLoading(true)
        verifyphone(data)
        .then((res) => {
            if(res.success) {
                setSuccessMsg(res.success)
            }
            if(res.error) {
                setErrorMsg(res.error)
            }
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
            console.log(err)
        })
    }

    return (
        <Box
        sx={{
            backgroundColor: "#f7f6f2",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "200px",
            gap: "40px",
        }}
        >
        <Typography sx={{ mb: "20px", fontWeight: 600, fontSize: "20px" }}>
            Enter your phone number and your verification code
        </Typography>
        <Typography sx={{ my: 0, fontSize: '1rem' }} color={"red"}>
            { errorMsg }
        </Typography>
        <Typography sx={{ my: 0, fontSize: '1rem' }} color={"green"}>
            { successMsg }
        </Typography>
        <FormInputText
            name="phone"
            control={verificationForm.control}
            setValue={verificationForm.setValue}
            placeholder="Phone number"
            muiProps={{ type: "text" }}
            sx={{ border: "1px solid transparent", borderRadius: "25px", background: '#EDECE8' }}
            rules={{
                required: "Field can't be empty",
              }}
            />
        <FormInputText
            name="code"
            control={verificationForm.control}
            setValue={verificationForm.setValue}
            placeholder="Code"
            muiProps={{ type: "text" }}
            sx={{ border: "1px solid transparent", borderRadius: "25px", background: '#EDECE8' }}
            rules={{
                required: "Field can't be empty",
            }}
            />
        <Button desabled={ isloading } onClick={verificationForm.handleSubmit(handleVerify)} color="info" variant="outlined">
            Verify Phone number
        </Button>

        {successMsg && <Button onClick={() => window.location.href = '/login'} color="info" variant="outlined">
            Go to sign in page
        </Button>}
        </Box>
    );
}
 