import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { pxToRem } from "px2rem2px"; // Adjust the path as needed
import IconBtnCircular from "../../components/IconBtnCircular"; // Adjust the path as needed
import { Close, ExpandMore } from "@mui/icons-material";
import Review from "./RatingOld";
import { getRatings } from "../../api/course";
import { useParams, useSearchParams } from "react-router-dom";
import Testimonial from "../Testimonial";

export default function DisplayReviewsModal({
  open,
  setOpen,
  course,
  starRatingFilter,
  setIsFiltering,
  isFiltering,
  ...props
}) {
  const { id } = useParams();
  const handleClose = () => {
    setOpen(false);
    setIsFiltering(false);
    setSearchParams({});
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const currentRating = searchParams.get("rating");

  const [reviews, setReviews] = useState([]);
  const [displayedReviewsCount, setDisplayedReviewsCount] = useState(5); // Start with 5 reviews

  const fetchReviews = () => {
    console.log("fired");
    getRatings(id)
      .then((res) => {
        setReviews(res.data.results);
        if (currentRating) {
          setOpen(true);
          setReviews(
            res.data.results.filter(
              (review) => review.id === Number(currentRating)
            )
          );
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    fetchReviews();
  }, [searchParams]);

  const handleShowMore = () => {
    // Incrementally show 5 more reviews
    setDisplayedReviewsCount((prevCount) =>
      Math.min(prevCount + 5, reviews.length)
    );
  };

  const filteredReviews = isFiltering
    ? reviews.filter((review) => review.rating === starRatingFilter)
    : reviews;

  return (
    <Dialog
      {...props}
      open={open}
      onClose={handleClose}
      fullScreen={false}
      maxWidth="xs"
      scroll="body"
      disableEscapeKeyDown
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: {
            xs: "calc(100% - 40px) !important",
            ssm: "calc(100% - 64px) !important",
            md: `${pxToRem(920)} !important`,
          },
          borderRadius: pxToRem(20),
          height: "auto",
          margin: { xs: "20px !important", ssm: `${pxToRem(32)} !important` },
          backgroundColor: "#F7F6F2",
        },
        zIndex: 1500,
      }}
    >
      {/* Header Section */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        bgcolor="#EDECE8"
        p={{
          xs: `${pxToRem(18)}`,
          ssm: `${pxToRem(25)}`,
          md: `${pxToRem(40)} ${pxToRem(60)}`,
        }}
      >
        <Typography
          fontSize={{ xs: pxToRem(23), ssm: pxToRem(35) }}
          fontWeight={700}
        >
          {isFiltering
            ? `Rated with ${starRatingFilter} ${
                starRatingFilter === 1 ? "star" : "stars"
              }`
            : currentRating
            ? "Rating and Review"
            : "Ratings & Reviews"}
        </Typography>
        <IconBtnCircular
          onClick={handleClose}
          sx={{
            width: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
            height: { xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48) },
          }}
        >
          <Close />
        </IconBtnCircular>
      </Stack>

      {/* Body Section */}
      <Box sx={{ p: "40px" }}>
        <Stack gap={pxToRem(20)}>
          {filteredReviews
            .slice(0, displayedReviewsCount)
            .map((review, idx) => (
              <Testimonial
                avatar
                key={idx}
                rating={review}
                course={course}
                allReviewsModal={true}
                updateRatings={fetchReviews}
              />
            ))}
        </Stack>
        {displayedReviewsCount < filteredReviews.length && (
          <Box mt={pxToRem(20)} textAlign="center">
            <Button
              variant="text"
              onClick={handleShowMore}
              endIcon={<ExpandMore />}
              sx={{
                fontSize: pxToRem(16),
                textTransform: "none",
                color: "secondary.main",
                fontWeight: 600,
              }}
            >
              Show More
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
