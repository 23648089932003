import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { styled } from "@mui/system";
import { pxToRem } from "px2rem2px";
export const LinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
  height: pxToRem(4),
  borderRadius: pxToRem(10),
  marginLeft: pxToRem(20),
  marginRight: pxToRem(20),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(191,190,187,0.5)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: pxToRem(10),
    backgroundColor: "#026670",
  },
}));
