import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";
import { pxToRem } from "px2rem2px";

const CardSkeleton = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        position="relative"
        bgcolor="#fff"
        borderRadius={pxToRem(20)}
        sx={{
          transition: "0.2s ease",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "&::before": {
            content: "''",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: `calc(100% - ${pxToRem(40)})`,
            height: "100%",
            backgroundColor: "#FCE181",
            borderRadius: pxToRem(20),
            transform: "translate(-50%,-50%)",
            zIndex: -1,
            transition: "0.2s ease",
          },
          "& .MuiButton-root": {
            position: "static",
            fontSize: "16px",
            height: { xs: "44px", ssm: pxToRem(54) },
            opacity: 0,
            transition: "0.2s ease",
            zIndex: 1,
          },
          "&:hover": {
            boxShadow: {
              xs: "none",
              sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
            },
            "&::before": {
              height: `calc(100% + ${pxToRem(40)})`,
            },
            "& .slideTitle": {
              color: "primary.main",
            },
            "& .MuiButton-root, .duration": {
              opacity: 1,
            },
          },
        }}
      >
        <Box borderRadius={`${pxToRem(20)} ${pxToRem(20)}`}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            sx={{ borderRadius: "20px 20px 0px 0px" }}
          />

          <Stack spacing={1} p="26px 36px">
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="50%" />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CardSkeleton;
