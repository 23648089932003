import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  TextField,
  Typography,
  FormHelperText,
  Avatar,
} from "@mui/material";

import { useEffect, useState } from "react";

import ModalHeader from "../../../components/ModalHeader";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/material/styles";
import getErrorsArray from "../../../utils/getErrorsArray";
import { editProfile, verifyResetEmailCode } from "../../../api/student";
import { isValidEmail } from "../../../utils/validation";
import { inviteAssociate } from "../../../api/student";
import Toast from "../../../components/Toast";
import { Stack } from "@mui/system";

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)}`,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

const titleStyle = {
  fontWeight: "600", // Lighter font weight for titles
  fontSize: "1.1rem", // Smaller font size for titles
  color: "gray",
};

const dataStyle = {
  fontSize: "1rem", // Smaller font size for data
  fontWeight: "500",
};

export default function InviteAssociateModal({
  courseId,
  open,
  setOpen,
  associate,
  setFetchCourseAgain = () => {},
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [touched, setTouched] = useState(false);

  // alert(associate);
  const handleBlur = () => {
    setTouched(true);
  };
  const [email, setEmail] = useState("");

  useEffect(() => {
    console.log("maaaaaaaail", email);
  }, [email]);

  function handleSubmit() {
    if (!email) {
      setErrorMessage(["Email cannot be empty"]);
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage(["Please enter a valid email address"]);
      return;
    }

    inviteAssociate({ course: courseId, email: email }).then((res) => {
      if (res.success) {
        setOpenSuccess(true);
        handleClose();
      } else {
        setErrorMessage(res.message);
      }
    });
  }

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setTouched(false);
    setErrorMessage("");
    setFetchCourseAgain(false);
  };

  return (
    <>
      <Toast
        open={openSuccess}
        onClose={() => {
          setOpenSuccess(false);
        }}
        message="An invitation has been successfully sent."
      />
      <Dialog
        fullWidth
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px",
            minHeight: "300px",
            borderRadius: "15px",
            margin: {xs: "20px !important" , ssm: `${pxToRem(32)} !important`}
          },
        }}
      >
        <ModalHeader
          title={associate ? "Partner Info" : "Invite a Partner "}
          inModal
          onClose={handleClose}
        />

        <DialogContent sx={{ backgroundColor: "#f7f6f2", p: {xs: pxToRem(30), ssm: pxToRem(50) }}}>
          {associate ? (
            <>
              <Stack
                position="absolute"
                top="64px"
                left="50%"
                alignItems="center"
                justifyContent="center"
                width={pxToRem(54)}
                height={pxToRem(54)}
                borderRadius="100%"
                sx={{ transform: "translate(-50%,50%)" }}
              >
                <Avatar
                  //  alt={associate?.full_name}
                  src={`https://rymapi.webaryco.com/${associate?.avatar}`}
                  sx={{ width: 78, height: 78, margin: "auto", mb: 2 }}
                />
              </Stack>
              <Box
                sx={{
                  backgroundColor: "#f7f6f2",
                  textAlign: "left",
                  maxWidth: 300,

                  margin: "15px 0 0 18px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="body1" style={titleStyle}>
                  Name: <span style={dataStyle}>{associate?.full_name}</span>
                </Typography>
                <Typography variant="body1" style={titleStyle}>
                  Email: <span style={dataStyle}>{associate?.email}</span>
                </Typography>
                <Typography variant="body1" style={titleStyle}>
                  Invitation: <span style={dataStyle}>{associate?.status}</span>
                </Typography>
                <Typography variant="body1" style={titleStyle}>
                  Amount: <span style={dataStyle}>${associate?.price}</span>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                  flexDirection: "column",
                  marginBottom: "1rem",
                }}
              >
                <Typography color="gray" sx={{ textAlign: "center" }}>
                  Please enter your partner's email address
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "18px",
                }}
              >
                <TextFieldStyled
                  placeholder={"Partner's email address"}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onBlur={handleBlur}
                  type="email"
                  error={!isValidEmail(email) && touched}
                  helperText={
                    <FormHelperText error>
                      {(!isValidEmail(email) && touched
                        ? "Please enter a valid email address"
                        : "") || errorMessage}
                    </FormHelperText>
                  }
                />
              </Box>
            </>
          )}
        </DialogContent>
        {!associate && (
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: "30px",
              backgroundColor: "#f7f6f2",
              px: {xs: pxToRem(30), ssm: pxToRem(50) }
            }}
            // sx={{ backgroundColor:"#f7f6f2"}}
          >
            <Button
              disabled={loading}
              onClick={() => {
                handleSubmit();
              }}
              variant="yellow"
              sx={{width: {xs: "100%", sssm: "auto"}, height: pxToRem(54)}}
            >
              Send Invitation
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
