import { useEffect, useState } from "react";
import { Stack, Box, Typography, Button, Tooltip } from "@mui/material";
import { ReactComponent as Info } from "../images/info.svg";
import Star from "@mui/icons-material/Star";
import { pxToRem } from "px2rem2px"; // Ensure this module exists or replace with inline utility
import Testimonial from "./Testimonial";
import DisplayReviewsModal from "./commentModal/DisplayReviewsModal";
import ReviewModal from "./commentModal/ReviewModal";
import { LinearProgressStyled } from "./LinearProgressStyled";
import Rating from "./Rating";
import { getRatings } from "../api/course";
import { useParams, useSearchParams } from "react-router-dom";

export default function CourseRatingsComponent({ course, subscribed = false }) {
  const { id } = useParams();

  const [showReviewsModal, setShowReviewsModal] = useState(false);
  const [ratingsDialogOpen, setRatingsDialogOpen] = useState(false);
  const [starRatingFilter, setStarRatingFilter] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isReviewedByMe, setIsReviewedByMe] = useState(false);
  const [myReviewId, setMyReviewId] = useState(null);
  const [currentReview, setCurrentReview] = useState({});
  const fetchRatings = () => {
    getRatings(id)
      .then((res) => {
        setReviews(res.data.results);
        res.data.results.forEach((review) => {
          if (review.is_mine === true) {
            setIsReviewedByMe(true);
            setMyReviewId(review.id);
            setCurrentReview(review);
          }
        });
      })
      .catch((err) => {
        console.log("err");
      });
  };

  useEffect(() => {
    fetchRatings();
  }, []);

  return (
    <Box>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap={pxToRem(15)}
        mb={pxToRem(15)}
        sx={{ mt: "60px" }}
      >
        <Typography
          fontSize={pxToRem(20)}
          lineHeight={pxToRem(30)}
          fontWeight={700}
        >
          Ratings & Reviews
        </Typography>

        {!subscribed ? (
          <Tooltip
            title="The overall rating is calculated using the average of submitted ratings. Ratings and reviews can only be submitted when learners complete at least 40% of the course. This helps us avoid fake reviews and spam."
            enterDelay={0}
            enterTouchDelay={0}
            PopperProps={{ disablePortal: true }}
            placement="top"
            sx={{
              "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
                py: pxToRem(4),
              },
            }}
          >
            <Box>
              <Button
                startIcon={<Info />}
                sx={{
                  fontSize: pxToRem(12),
                  fontWeight: 600,
                  color: "#BFBEBB",
                }}
              >
                <Typography
                  sx={{
                    display: { xs: "none", ssm: "inline-block" },
                    fontSize: pxToRem(12),
                    fontWeight: 600,
                    color: "#BFBEBB",
                  }}
                >
                  How are ratings calculated?
                </Typography>
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <></>
        )}
      </Stack>
      {course?.rating ? (
        <>
          <Box mt={pxToRem(10)}>
            <Stack flexDirection="row" alignItems="center" gap={pxToRem(10)}>
              <Typography sx={{ fontWeight: 700, mt: "2px" }}>
                {course?.rating.toFixed(1)}
              </Typography>
              {course?.rating && (
                <Rating value={course?.rating} precision={0.1} readOnly />
              )}
              {reviews && (
                <Typography fontWeight={700} mt={"2px"}>
                  {reviews.length} {reviews.length === 1 ? "Review" : "Reviews"}
                </Typography>
              )}
            </Stack>
          </Box>{" "}
          <Box
            mt={pxToRem(40)}
            sx={{
              display: "grid",
              gridTemplateColumns: { sm: "1fr 1fr" },
              gap: pxToRem(20),
              flexWrap: "wrap",
            }}
          >
            <Stack
              flexDirection="column-reverse"
              justifyContent="space-between"
              bgcolor="#EDECE8"
              borderRadius={pxToRem(20)}
              p={`${pxToRem(40)} ${pxToRem(20)}`}
              height="300px"
            >
              {course?.ratings_count_by_stars &&
                Object.values(course?.ratings_count_by_stars).map(
                  (val, idx) => {
                    return (
                      <Stack
                        key={idx}
                        flexDirection="row"
                        alignItems="center"
                        borderRadius={pxToRem(30)}
                        p={`${pxToRem(11)} ${pxToRem(20)}`}
                        sx={{
                          "&:hover": { backgroundColor: "#F7F6F2" },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          {
                            if (val > 0) {
                              setIsFiltering(true);
                              setStarRatingFilter(idx + 1);
                              setShowReviewsModal(true);
                            }
                          }
                        }}
                      >
                        <Typography
                          fontWeight={700}
                          lineHeight={pxToRem(18)}
                          mr={pxToRem(10)}
                          mt={pxToRem(1)}
                        >
                          {idx + 1}
                        </Typography>
                        <Star style={{ color: "#FCE181" }} />
                        <Box sx={{ flexGrow: 1 }}>
                          <LinearProgressStyled
                            variant="determinate"
                            value={(val / reviews.length) * 100}
                          />
                        </Box>
                        <Typography fontWeight={700} lineHeight={pxToRem(18)}>
                          {val}
                        </Typography>
                      </Stack>
                    );
                  }
                )}
            </Stack>
            {reviews &&
              reviews.length > 0 &&
              reviews
                .slice(0, 5)
                .map((rating, index) => (
                  <Testimonial
                    avatar
                    key={index}
                    rating={rating}
                    course={course}
                    updateRatings={fetchRatings}
                  />
                ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              mt: pxToRem(40),
            }}
          >
            <Button
              onClick={() => setShowReviewsModal(true)}
              variant="yellow"
              sx={{ height: pxToRem(54) }}
            >
              See all Reviews
            </Button>
            {course?.progress >= 40 && (
              <>
                <Button
                  variant="yellow"
                  sx={{ height: pxToRem(54) }}
                  onClick={() => {
                    setRatingsDialogOpen(true);
                  }}
                  //disabled={isReviewedByMe}
                >
                  {isReviewedByMe
                    ? "Edit your Rating & Review"
                    : "Rate This Course"}
                </Button>
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          {!subscribed ? (
            <Typography sx={{ fontWeight: 500 }}>
              No ratings yet. Ratings will appear once students start sharing
              their feedback.
            </Typography>
          ) : (
            <>
              {course?.progress >= 40 ? (
                <Button
                  variant="yellow"
                  sx={{ height: pxToRem(54) }}
                  onClick={() => {
                    setRatingsDialogOpen(true);
                  }}
                  disabled={isReviewedByMe}
                >
                  {isReviewedByMe
                    ? "You’ve rated this course."
                    : "Rate This Course"}
                </Button>
              ) : (
                <Typography sx={{ fontWeight: 500 }}>
                  No ratings yet. Your rating and review can be submitted upon
                  reaching 40% progress in the course.
                </Typography>
              )}
            </>
          )}
        </>
      )}

      <DisplayReviewsModal
        open={showReviewsModal}
        setOpen={setShowReviewsModal}
        course={course}
        starRatingFilter={starRatingFilter}
        isFiltering={isFiltering}
        setIsFiltering={setIsFiltering}
      />
      <ReviewModal
        open={ratingsDialogOpen}
        setOpen={setRatingsDialogOpen}
        course={course}
        updateRatings={fetchRatings}
        isEditing={isReviewedByMe}
        currentReview={isReviewedByMe ? currentReview : {}}
      />
    </Box>
  );
}
