import { Box, Stack, styled } from "@mui/system";
import { pxToRem } from "px2rem2px";
import React from "react";
import LikeBtn from "../../components/courses/LikeBtn";
import { useAuth } from "../../utils/AuthContext";
import { NavLink } from "react-router-dom";
import { Button, Typography } from "@mui/material";

// import {LikeIcon} from "../../images/like.svg"
import Likes from "../../components/Likes";

const Duration = styled(Typography)`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #026670;
  border-radius: 0 5px 0 0;
  padding: 3px 11px;
  transition: 0.2s ease;
  opacity: 0;

  @media (max-width: 899px) {
    opacity: 1;
  }
`;

const Label = styled(Typography)(({ children }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "24px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#026670",
    backgroundColor: "#f8eab9",
    border: "2px solid #f5e093",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 25px",
  };
});

export default function Parsha({
  image,
  title,
  text,
  linkUrl,
  date,
  subscribers,
  duration,
  labels,
  likes,
}) {
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();

  return (
    <div>
      <Box
        sx={{
          height: "100%",
          width: "360px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          // ref={passRef}
          position="relative"
          bgcolor="#fff"
          borderRadius={pxToRem(20)}
          sx={{
            transition: "0.2s ease",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            "&::before": {
              content: "''",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: `calc(100% - ${pxToRem(40)})`,
              height: "100%",
              backgroundColor: "#FCE181",
              borderRadius: pxToRem(20),
              transform: "translate(-50%,-50%)",
              zIndex: -1,
              transition: "0.2s ease",
            },
            "& .MuiButton-root": {
              position: "static",
              fontSize: "16px",
              height: "54px",
              width: "158px",
              opacity: 0,
              transition: "0.2s ease",
              zIndex: 1,
              "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              },
            },
            "&:hover": {
              boxShadow: {
                xs: "none",
                sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
              },
              "&::before": {
                height: `calc(100% + ${pxToRem(40)})`,
              },
              "& .slideTitle": {
                color: "primary.main",
              },
              "& .MuiButton-root, .duration": {
                opacity: 1,
              },
            },
          }}
        >
          <Box>
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                "& > *": { gridRow: "1 / -1", gridColumn: "1 / -1" },
              }}
            >
              <Button to="/parsha" component={NavLink} variant="yellow">
                View
              </Button>

              <Box
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={`${pxToRem(20)} ${pxToRem(20)} 0 0`}
                height="200px"
                width="100%"
                overflow="hidden"
              >
                {isAuthenticated ? (
                  <LikeBtn
                  courseId={null}
                  isFavorite={false}
                  onFavoriteClick={()=>{}}
                  />
                ) : (
                  <></>
                )}

                <Duration className="duration">{duration}</Duration>
                <img
                  src={image}
                  alt={title}
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                />
              </Box>
              <Stack
                width="calc(100% - 60px)"
                flexDirection="row"
                alignContent="center"
                justifyContent="flex-end"
                overflow="hidden"
                flexWrap="nowrap"
                gap="8px"
                position="absolute"
                top="190px"
              >
                {labels.map((label, labelIndex) => (
                  <Label key={labelIndex}>{label}</Label>
                ))}
              </Stack>
            </Box>
            <Box
              p="26px 36px"
              bgcolor="#fff"
              borderRadius={`0 0 ${pxToRem(20)} ${pxToRem(20)}`}
              overflow="hidden"
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <Typography
                className="slideTitle"
                fontSize={pxToRem(22)}
                fontWeight={700}
                lineHeight={pxToRem(30)}
                color="secondary.main"
                mb={pxToRem(20)}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",

                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",

                    flexGrow: 1,
                    marginTop: "2px",
                  }}
                >
                  <Typography
                    component="p"
                    fontSize="12px"
                    fontWeight={600}
                    color="#BFBEBB"
                    whiteSpace="nowrap"
                  >
                    Posted {new Date(date).toLocaleDateString()}&nbsp; • &nbsp;
                    {subscribers}{" "}
                    {subscribers === 1 ? "Subscriber" : "Subscribers"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="p"
                    fontSize="12px"
                    fontWeight={600}
                    color="#BFBEBB"
                    whiteSpace="nowrap"
                    // marginRight="2px"
                    marginTop="2px"
                  >
                    {likes}
                  </Typography>

                  <Box sx={{marginTop:"-6px",marginLeft:"-3px"}}>
                    <Likes  />
                  </Box>
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

// import React from 'react';
// import { Card, CardContent, CardMedia, Typography, Button, Stack } from '@mui/material';

// const Parsha = ({ image, title, text, linkUrl, date, subscribers, duration, labels }) => {
//   return (
//     <Card>
//       <CardMedia
//         component="img"
//         height="300"
//         image={image}
//         alt={title}
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//           {title}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           {text}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Date: {date}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Subscribers: {subscribers}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Duration: {duration}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//           Labels: {labels.join(', ')}
//         </Typography>
//         <Button size="small" href={linkUrl}>
//           Learn More
//         </Button>
//       </CardContent>
//     </Card>
//   );
// };

// export default Parsha;
