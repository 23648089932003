import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';

const ResendCode = ({
  startTimer, 
  setStartTimer, 
  handleResend, 
  initialTimer = 60, 
  text = "Didn't receive code?",
  buttonText = "Resend",
  isLoading,
}) => {
  const [resendTimer, setResendTimer] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    if (startTimer && resendTimer === 0) {
      setResendTimer(initialTimer);
    }
  }, [startTimer, initialTimer]);

  useEffect(() => {
    if (resendTimer > 0) {
      timerRef.current = setTimeout(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearTimeout(timerRef.current);
      if (startTimer) {
        setStartTimer(false); 
      }
    }

    return () => clearTimeout(timerRef.current); 
  }, [resendTimer, startTimer, setStartTimer]);

  const handleResendCode = () => {
    handleResend()
  };


  // useEffect(()=>{
  //   console.log("isLoading",isLoading)
  // },[isLoading])

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ fontSize: "0.875rem", fontWeight: 500 }}
      >
        {text}
      </Typography>
      <Button
        variant="text"
        onClick={handleResendCode}
        disabled={startTimer || resendTimer > 0 || isLoading}
        sx={{ fontSize: "0.875rem", fontWeight: 500 }}
      >
        {buttonText}
      </Button>
      {resendTimer > 0 && (
        <Typography variant="body2" color="textSecondary">
          {resendTimer}s
        </Typography>
      )}
    </Box>
  );
};

export default ResendCode;
