import { useState } from "react";
import {
  Box,
  Collapse,
  ListItemButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import DeleteBtn from "./DeleteBtn";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { useTheme } from "@emotion/react";
import { getLanguageClass } from "../../utils/languageClass";

const Note = (props) => {
  const theme = useTheme();
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const [open, setOpen] = useState(false);

  const formatTimestamp = (timestamp) => {
    const minutes = Math.floor(timestamp / 60);
    const seconds = timestamp % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleClick = () => {
    setOpen(!open);
  };

  // console.log("props notes", props);

  return (
    <Box borderTop="1px solid rgba(191,190,187,0.5)">
      <ListItemButton
        onClick={handleClick}
        sx={{
          alignItems: "flex-start",
          p: `${pxToRem(10)} 0`,
          "&:hover": {
            backgroundColor: "transparent",
            "& .deleteBtn": {
              display: "flex",
            },
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="10px"
          flex={1}
        >
          <Box
            sx={{
              width: "50%",
              display: "grid",
              // gridTemplateColumns: "1fr auto auto",
              alignItems: "left",
              overflow: "hidden",
            }}
          >
            <Typography
              component="span"
              display="block"
              textAlign="left"
              fontSize={pxToRem(16)}
              fontWeight={600}
              color="secondary"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              className={getLanguageClass(props?.note?.title)}
            >
              {props?.note?.title}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginRight: "5px" }}>
              <DeleteBtn onDelete={props.onDelete} isNote />
            </Box>
            <Tooltip
              title={!mobile_ssm ? props?.note?.lesson?.name : ""}
              enterDelay={0}
              enterTouchDelay={0}
              placement="top"
              sx={{
                mt: "8px",
                transform: "translateY(8px)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="span"
                  fontSize={pxToRem(16)}
                  fontWeight={600}
                  color="#333333"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    minWidth: "110px",
                    // backgroundColor: "yellow"
                  }}
                >
                  {(() => {
                    const lessonIndex = props?.lessons?.findIndex(
                      (lesson) => lesson.id === props?.note?.lesson?.id
                    );
                    return lessonIndex !== -1
                      ? `Lecture ${lessonIndex + 1}`
                      : "Unknown Lecture";
                  })()}

                  <span>&nbsp;•&nbsp;</span>

                  <span>{formatTimestamp(props?.note?.timestamp)}</span>
                </Typography>


              </Box>
            </Tooltip>
          </Box>
        </Stack>

        <Box
          sx={{
            "& svg": {
              transform: open ? "rotate(-90deg)" : "rotate(90deg)",
              mr: pxToRem(2),
              ml: pxToRem(20),
              transition: "0.1s ease",
            },
          }}
        >
          <Chevron color={open ? "#333" : "#BFBEBB"} />
        </Box>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props?.children}
      </Collapse>
    </Box>
  );
};

export default Note;
