export const formatDuration = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  if (h > 0 && m > 0) {
    return `${h} hr ${m} min`;
  } else if (h > 0) {
    return `${h} hr`;
  } else if (m > 0) {
    return `${m} min`;
  } else {
    return `${s} sec`;
  }
};

export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

export const isTodayBetweenDates = (startDate, endDate) => {
  const parseDate = (dateString) => new Date(dateString);
  const today = new Date();
  return today >= parseDate(startDate) && today <= parseDate(endDate);
};


export const formatDateDuration = (days) => {
  const years = Math.floor(days / 365);
  const remainingDaysAfterYears = days % 365;
  const months = Math.floor(remainingDaysAfterYears / 30);
  const remainingDays = remainingDaysAfterYears % 30;

  const yearText = years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "";
  const monthText =
    months > 0 ? `${months} month${months > 1 ? "s" : ""}` : "";
  const dayText =
    remainingDays > 0
      ? `${remainingDays} day${remainingDays > 1 ? "s" : ""}`
      : "";

  const durationArray = [yearText, monthText, dayText].filter(Boolean);
  return durationArray.join(", ");
};



export const dateToDays = (expires_at) => {
  const expiryDate = new Date(expires_at);
  const currentDate = new Date();

  const timeDifference = expiryDate - currentDate;

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}



export const isWithin21Days = (targetDate) => {
  if (!targetDate || isNaN(new Date(targetDate).getTime())) {
    return false;
  }

  const target = new Date(targetDate);
  const today = new Date();
  const timeDifference = target.getTime() - today.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  return daysDifference <= 21;
};

export const formatTimeAgo=(date)=> {
  const now = new Date();
  const givenDate = new Date(date);
  const secondsAgo = Math.floor((now - givenDate) / 1000);

  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);
  const daysAgo = Math.floor(hoursAgo / 24);
  const weeksAgo = Math.floor(daysAgo / 7);
  const monthsAgo = Math.floor(daysAgo / 30);
  const yearsAgo = Math.floor(daysAgo / 365);

  if (secondsAgo < 60) {
    return 'Just now';
  } else if (minutesAgo < 60) {
    return `${minutesAgo} minute${minutesAgo > 1 ? 's' : ''} ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else if (daysAgo < 7) {
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  } else if (weeksAgo < 4) {
    return `${weeksAgo} week${weeksAgo > 1 ? 's' : ''} ago`;
  } else if (monthsAgo < 12) {
    return `${monthsAgo} month${monthsAgo > 1 ? 's' : ''} ago`;
  } else {
    return `${yearsAgo} year${yearsAgo > 1 ? 's' : ''} ago`;
  }
}




export const formatDayMonthYear=(dateString)=> {
  const [year, month, day] = dateString.split("-");
  return `${day}/${month}/${year.slice(2)}`; 
}