import { Grid, Typography } from "@mui/material";

const TermsBlock = (props) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={4}>
      <Grid item xs={12} md={3}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "22px", md: "26px" },
            lineHeight: { xs: "26px", md: "32px" },
          }}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9} sx={{ pt: "3px" }}>
        {props.children}
      </Grid>
    </Grid>
  );
};

export default TermsBlock;
