import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useRouter from "./routing";
import theme from "./theme";
import ModalProvider from "mui-modal-provider";
import { PathProvider } from "./utils/PathContext";
import { AuthProvider } from "./utils/AuthContext";
import { CoursesProvider } from "./utils/CoursesContext";

const App = () => {
  const router = useRouter();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <ModalProvider>
            <PathProvider>
            <CoursesProvider>
              <RouterProvider router={router} />
            </CoursesProvider>
            </PathProvider>
        </ModalProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
