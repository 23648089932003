import {
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import {
  completeGoogleSignUp,
  getStudent,
  getUserInfo,
} from "../../api/student";
import getErrorsArray from "../../utils/getErrorsArray";
import { saveStudentLocal, useAuth } from "../../utils/AuthContext";
import FormInputPhone from "../../components/FormInputPhone";
import ModalHeader from "../../components/ModalHeader";

const TextFieldStyled = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: pxToRem(30),
    backgroundColor: "#EDECE8",
    height: pxToRem(54),
  },
  "& input": {
    borderRadius: `${pxToRem(30)} !important`,
    padding: `${pxToRem(15)} ${pxToRem(40)}`,
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

const ButtonLogin = styled(Button)({
  width: pxToRem(300),
  height: `${pxToRem(54)} !important`,
  fontSize: pxToRem(14),
  fontWeight: 600,
  boxShadow: "none",
  margin: `${pxToRem(40)} 0`,
});

export default function CompleteGoogleSignUp() {
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errors, setErrors] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const { deleteStudent } = useAuth();

  const [accountForm, setAccountForm] = useState({
    firstName: "",
    lastName: "",
    nickname: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    keepMeSignedIn: true,
    is_email_subscribed: true,
    email: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: { required: false, pattern: false },
    lastName: { required: false, pattern: false },
    email: false,
    phoneNumber: { required: false, length: false },
    password: false,
    confirmPassword: { length: false, match: false },
  });

  useEffect(() => {
    getStudent().then((res) => {
      setAccountForm({
        ...accountForm,
        firstName: res.data.user.first_name || "",
        lastName: res.data.user.last_name || "",
        email: res.data.user.email || "",
      });
    });
  }, []);

  const validateForm = () => {
    const namePattern = /^[a-zA-Z\s'-]+$/;
    const newErrors = {
      firstName: {
        required: !accountForm.firstName,
        pattern:
          accountForm.firstName && !namePattern.test(accountForm.firstName),
      },
      lastName: {
        required: !accountForm.lastName,
        pattern:
          accountForm.lastName && !namePattern.test(accountForm.lastName),
      },
      email: !accountForm.email,
      phoneNumber: {
        required: !accountForm.phoneNumber,
        length: accountForm.phoneNumber.length < 12,
      },
      password: accountForm.password.length < 6,
      confirmPassword: {
        length: accountForm.confirmPassword.length < 6,
        match: accountForm.password !== accountForm.confirmPassword,
      },
    };
    setFormErrors(newErrors);
    return !Object.values(newErrors).some(
      (error) => error.required || error.pattern || error.length || error.match
    );
  };

  const handleClose = async () => {
    setOpen(false);
    const email = accountForm?.email;
    try {
      await deleteStudent(email);
    } catch (error) {
      console.error("Failed to delete student:", error);
    }
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("studentData");
    localStorage.removeItem("user");
    const redirectUrl = localStorage.getItem("redirectAfterLogin") || "/";
    window.location.href = redirectUrl;
  };

  function handleVerify() {
    const isValid = validateForm();
    if (!isValid) {
      setPhoneNumberError(true);
      return;
    }

    const payload = {
      user: {
        first_name: accountForm.firstName,
        last_name: accountForm.lastName,
        password: accountForm.password,
        email: accountForm.email.toLowerCase(),
      },
      nickname: accountForm.nickname ? accountForm.nickname.toLowerCase() : null,
      phone_number: accountForm.phoneNumber,
      keep_me_signed_in: accountForm.keepMeSignedIn,
      is_email_subscribed: accountForm.is_email_subscribed,
      confirm_password: accountForm.confirmPassword,
    };

    console.log("payload is", payload);
    setIsLoading(true);
    completeGoogleSignUp(payload)
      .then((res) => {
        setIsLoading(false);
        saveStudentLocal().then((res) => {
          const userInfo = getUserInfo();
          window.localStorage.setItem(
            "studentData",
            JSON.stringify({ ...userInfo, is_password_set: true })
          );
          // Redirect to the original page
          const redirectUrl = localStorage.getItem("redirectAfterLogin") || "/";
          window.location.href = redirectUrl;
        });
      })
      .catch((e) => {
        console.log("ERROR IS", e.response.data);
        setErrors(getErrorsArray(e.response.data));
        setIsLoading(false);
      });
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        maxWidth="xs"
        scroll="body"
        disableEscapeKeyDown
        sx={{
          backgroundColor:"#f7f6f2",
          "& .MuiPaper-root": {
            width: "400px",
            maxWidth: pxToRem(600),
            borderRadius: fullScreen ? 0 : pxToRem(20),
            verticalAlign: "top",
          },
          "& .MuiDialog-container": {
            overflowY: "scroll",
          },
          zIndex: 1500,
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <ModalHeader
          title={"Welcome! Finalize Your Account Setup."}
          inModal={true}
          onClose={handleClose}
        />
        <Box
          sx={{
            backgroundColor: "#f7f6f2",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "20px 10px",
              sm: "30px 60px",
            },
            gap: "10px",
          }}
        >
          <Typography>{accountForm.email}</Typography>
          <Box sx={{ mb: "10px" }}>
            {errors.map((error) => {
              return (
                <Typography
                  key={error}
                  sx={{ my: 0, fontSize: "1rem" }}
                  color={"red"}
                >
                  {error}
                </Typography>
              );
            })}
          </Box>

          <TextFieldStyled
            placeholder={"First Name"}
            value={accountForm.firstName}
            onChange={(e) => {
              setAccountForm({ ...accountForm, firstName: e.target.value });
              const namePattern = /^[a-zA-Z\s'-]+$/;
              setFormErrors({
                ...formErrors,
                firstName: {
                  required: !e.target.value,
                  pattern: e.target.value && !namePattern.test(e.target.value),
                },
              });
            }}
            autoComplete="off"
            error={
              formErrors.firstName.required || formErrors.firstName.pattern
            }
            helperText={
              formErrors.firstName.required
                ? "First name is required"
                : formErrors.firstName.pattern
                ? "First name should only include letters, spaces, hyphens, and apostrophes"
                : ""
            }
          />
          <TextFieldStyled
            placeholder={"Last Name"}
            value={accountForm.lastName}
            onChange={(e) => {
              setAccountForm({ ...accountForm, lastName: e.target.value });
              const namePattern = /^[a-zA-Z\s'-]+$/;
              setFormErrors({
                ...formErrors,
                lastName: {
                  required: !e.target.value,
                  pattern: e.target.value && !namePattern.test(e.target.value),
                },
              });
            }}
            autoComplete="off"
            error={formErrors.lastName.required || formErrors.lastName.pattern}
            helperText={
              formErrors.lastName.required
                ? "Last name is required"
                : formErrors.lastName.pattern
                ? "Last name should only include letters, spaces, hyphens, and apostrophes"
                : ""
            }
          />
          <TextFieldStyled
            placeholder={"Nickname (optional)"}
            value={accountForm.nickname}
            onChange={(e) => {
              setAccountForm({ ...accountForm, nickname: e.target.value });
            }}
            autoComplete="off"
          />
          <FormInputPhone
            value={accountForm.phoneNumber}
            onChange={(data) => {
              setAccountForm({ ...accountForm, phoneNumber: data.phone });
              setFormErrors({
                ...formErrors,
                phoneNumber: {
                  required: !data.phone,
                  length: data.phone.length < 12,
                },
              });
            }}
            error={
              phoneNumberError &&
              (formErrors.phoneNumber.required || formErrors.phoneNumber.length)
            }
            placeholder="Phone"
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              width: "90%",
            }}
          >
            <Typography
              sx={{
                color: "#d32f2f",
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 1.66,
                textAlign: "left",
              }}
            >
              {phoneNumberError &&
                (formErrors.phoneNumber.required
                  ? "Phone number is required"
                  : formErrors.phoneNumber.length
                  ? "Phone number must be at least 10 digits"
                  : "")}
            </Typography>
          </Box>

          <TextFieldStyled
            placeholder={"Password"}
            value={accountForm.password}
            onChange={(e) => {
              setAccountForm({ ...accountForm, password: e.target.value });
              setFormErrors({
                ...formErrors,
                password: e.target.value.length < 6,
              });
              if (confirmPasswordTouched) {
                setFormErrors((prevErrors) => ({
                  ...prevErrors,
                  confirmPassword: {
                    ...prevErrors.confirmPassword,
                    match: e.target.value !== accountForm.confirmPassword,
                  },
                }));
              }
            }}
            type="password"
            autoComplete="new-password"
            error={formErrors.password}
            helperText={
              formErrors.password
                ? "Password must be at least 6 characters"
                : ""
            }
          />
          <TextFieldStyled
            placeholder={"Confirm Password"}
            value={accountForm.confirmPassword}
            onChange={(e) => {
              setConfirmPasswordTouched(true);
              setAccountForm({
                ...accountForm,
                confirmPassword: e.target.value,
              });
              const confirmPasswordError =
                e.target.value.length < 6 ||
                accountForm.password !== e.target.value;
              setFormErrors({
                ...formErrors,
                confirmPassword: {
                  length: e.target.value.length < 6,
                  match: confirmPasswordError,
                },
              });
            }}
            type="password"
            autoComplete="new-password"
            error={
              formErrors.confirmPassword.length ||
              formErrors.confirmPassword.match
            }
            helperText={
              formErrors.confirmPassword.match
                ? "Passwords do not match"
                : formErrors.confirmPassword.length
                ? "Password must be at least 6 characters"
                : ""
            }
          />
          <FormControlLabel
            name="keepMeLogged"
            sx={{ mt: "15px" }}
            control={
              <Checkbox
                checked={accountForm.keepMeSignedIn}
                onChange={(e) => {
                  setAccountForm({
                    ...accountForm,
                    keepMeSignedIn: e.target.checked,
                  });
                }}
              />
            }
            label="Keep me logged in?"
          />
          <FormControlLabel
            name="is_email_subscribed"
            control={
              <Checkbox
                checked={accountForm.is_email_subscribed}
                onChange={(e) =>
                  setAccountForm({
                    ...accountForm,
                    is_email_subscribed: e.target.checked,
                  })
                }
              />
            }
            label="Send me emails about updates and news."
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "30px",
            backgroundColor:"#f7f6f2"
          }}
        >
          <Button
            disabled={isLoading}
            onClick={handleVerify}
            variant="yellow"
            type="submit"
            sx={{
              width: pxToRem(300),
              height: `${pxToRem(54)} !important`,
              fontSize: pxToRem(14),
              fontWeight: 600,
              boxShadow: "none",
              marginTop: `${pxToRem(40)} `,
              "@media(max-width: 380px)": {
                width: "100%",
                height: `${pxToRem(44)} !important`,
                fontSize: pxToRem(12),
              },
            }}
          >
            Create your Account
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}
