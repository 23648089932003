import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { getStudent, signUp, logIn, verifyemail, logOut } from "../api/student";
import { checkAuth } from "./checkAuth";
import { url } from "../api/config";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

const AuthContext = createContext();

export function saveStudentLocal() {
  return new Promise((resolve, reject) => {
    getStudent()
      .then((res) => {
        window.localStorage.setItem("studentData", JSON.stringify(res.data));
        resolve();
      })
      .catch((err) => {
        console.error("Error fetching student data:", err);
        reject(err);
      });
  });
}

export const AuthProvider = ({ children }) => {
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [loginError, setLoginError] = useState();
  const [signUpError, setSignUpError] = useState([]);
  const [verificationErr, setVerificationErr] = useState();
  const [emailVerificationMsg, setEmailVerificationMsg] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  async function login(
    username,
    password,
    captchaValue,
    keepMeLogged,
    resetCaptcha,
    data,
    setVerifyEmail,
    setTempEmail,
    setTempPass,
    setAction
  ) {
    setIsLogin(true);

    const fpPromise = FingerprintJS.load();

    const fp = await fpPromise;
    const result = await fp.get();

    // visitor identifier:
    const visitorId = result.visitorId;
    console.log(visitorId);

    await logIn(
      username,
      password,
      captchaValue,
      keepMeLogged,
      visitorId
    )
      .then((success) => {
        console.log(success);
        setIsLogin(false);
        console.log("success");
        window.localStorage.setItem("accessToken", success.data.access);
        window.localStorage.setItem("refreshToken", success.data.refresh);
        window.localStorage.setItem("visitorId", visitorId);
        if (success) {
          saveStudentLocal().then((res) => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        setIsLogin(false);
        resetCaptcha();
        console.log("Login error", err);
        if (
          err?.response?.data?.detail ===
          "Please verify your email to activate your account."
        ) {
          // Redirect to enter the verification code
          setVerifyEmail(data?.loginEmail);
          setAction("verifyEmail");
        } else if (
          err?.response?.data?.detail ===
          "You must change your password before logging in."
        ) {
          // Redirect to change password
          setTempEmail(data?.loginEmail);
          setTempPass(data?.loginPassword);
          setAction("changeTempPassword");
        } else {
          if (err?.response?.data?.detail) {
            setLoginError(err?.response?.data?.detail);
          }
          if (err?.response?.data?.non_field_errors) {
            setLoginError(err?.response?.data?.non_field_errors);
          }
          if (err?.response?.data?.captcha) {
            setLoginError(err?.response?.data?.captcha);
          }
        }
      });
  }

  async function logout() {
    const refreshToken = localStorage.getItem("refreshToken");
    const visitorId = localStorage.getItem("visitorId");

    if (refreshToken) {
      try {

        // const response = await getStudent();

        await logOut(visitorId);

        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("studentData");
        localStorage.removeItem("user");
        localStorage.removeItem("visitorId");

        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);

        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("studentData");
        localStorage.removeItem("user");

        window.location.reload();
      }
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("studentData");
      localStorage.removeItem("user");

      window.location.reload();
    }
  }

  async function signup(data) {
    setIsSigningUp(true);
    const payload = {
      user: {
        email: data.signupEmail.toLowerCase(),
        first_name: data.first_name,
        last_name: data.last_name,
        password: data.signupPassword,
      },
      captcha_value: data.captchaValue,
      phone_number: data.phone,
      nickname: data.nickname ? data.nickname.toLowerCase() : null,
      confirm_password: data.signupRePassword,
      is_email_subscribed: data.isEmailSubscribed,
    };
    try {
      const res = await signUp(payload); // Assuming this is an async operation
      if (res) {
        setIsSignedUp(true);
        setSignUpError([]);
        // No navigate call here since it's not accessible
        return res; // Resolve the promise with the response
      }
    } catch (err) {
      setSignUpError(err.response.data);
      setIsSignedUp(false);
      throw err; // Rethrow the error to be caught by the caller
    } finally {
      setIsSigningUp(false);
    }
  }



  async function verifyEmail(token, email) {
    await verifyemail(token, email)
      .then((res) => {
        if (res?.data?.error) {
          console.log(typeof res?.data?.error === "string");
          setVerificationErr(res.data.error);
        }
        if (res?.data?.message) {
          setEmailVerificationMsg(res.data.message);
        }
      })
      .catch((err) => {
        if (err?.message) setVerificationErr(err.message);
      });
  }


  async function oauth(state, code) {
    console.log("state", state);

    const fpPromise = FingerprintJS.load();

    const fp = await fpPromise;
    const result = await fp.get();

    // visitor identifier:
    const visitorId = result.visitorId;

    try {
      const response = await axios.post(
        `${url}/api/authentication/google_auth_response/`,
        {
          code: code,
          state: state,
          device_fingerprint: visitorId,
        }
      );
      window.localStorage.setItem("accessToken", response.data.access);
      window.localStorage.setItem("refreshToken", response.data.refresh);
      window.localStorage.setItem("visitorId", visitorId);

      saveStudentLocal().then((res) => {
        // Redirect to the original page
        const redirectUrl = localStorage.getItem("redirectAfterLogin") || "/";
        window.location.href = redirectUrl;
      });
    } catch (err) {
      console.log("google login error", err);
      setLoginError(err?.response?.data?.error);

      const redirectUrl = localStorage.getItem("redirectAfterLogin") || "/";
      window.location.href = redirectUrl;
    }
  }

  async function requestOauth() {
    try {
      const response = await axios.get(
        `${url}/api/authentication/google_auth_request`
      );
      if (response) {
        window.location.href = response.data.url;
      }
    } catch (err) {
      console.log(err);
      setLoginError(err?.response?.data?.detail);
    }
  }

  async function deleteStudent(email) {
    try {
      const response = await axios.delete(
        `${url}/api/authentication/delete_student_from_email/?email=${encodeURIComponent(
          email
        )}`
      );
      console.log("Student deleted successfully:", response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response error:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
      throw error;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        checkAuth,
        isSignedUp,
        login,
        logout,
        signup,
        loginError,
        signUpError,
        oauth,
        requestOauth,
        verifyEmail,
        verificationErr,
        emailVerificationMsg,
        isLogin,
        isSigningUp,
        deleteStudent,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
