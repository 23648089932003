import { Button, Typography } from "@mui/material";
import { ReactComponent as Chevron } from "../images/chevron.svg";
import { ReactComponent as Play } from "../../src/images/play.svg";

const TextLink = (props) => {
  const { showFullDescription, ...buttonProps } = props;


  const iconStyles = {
    transform: showFullDescription !== undefined ? (showFullDescription ? "rotate(-90deg)" : "rotate(90deg)") : "none",
    transition: showFullDescription !== undefined ? "transform 0.3s" : "none",
  };

  
  return (
    <Button
      {...buttonProps}
      variant="text"
      endIcon={
        props?.isBlog ? (
          // <Play style={iconStyles} />
          <Chevron style={iconStyles} />
        ) : (
          <Chevron style={iconStyles} />
        )
      }
    >
      <Typography component="span">{props.children}</Typography>
    </Button>
  );
};

export default TextLink;
