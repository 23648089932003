import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import { useState, useEffect } from "react";
import { getContactSection } from "../../api/cmsApi";
// import PhilosophyImg from "../../images/philosophy-img.png";
import PhilosophyImg from "../../images/philosophy-img1.jpg";
import { get } from "react-hook-form";
import highlightHTML from "../../utils/highlightHTML";
import { getLanguageClass } from "../../utils/languageClass";
const Philosophy = () => {
  const [contactSection, setContactSection] = useState({
    title: "",
    subtitle: "",
    button_1_text: "",
    button_1_link: "",
    target_new_tab_1: false,
    button_2_text: "",
    button_2_link: "",
    target_new_tab_2: false,
    is_single_button: false,
    image: "",
  });
  const fetchContactSection = () => {
    getContactSection()
      .then((res) => {
        setContactSection(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchContactSection();
  }, []);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const SectionTitle = () => {
    return (
      <>
        {contactSection && contactSection.title && (
          <Box
            sx={{
              display: "flex", // Ensures layout flexibility
            }}
          >
            <Box
              maxWidth={pxToRem(460)}
              sx={{
                fontSize: { xs: "1.4rem", sm: "2.5rem" }, // Responsive font size
                fontWeight: 500,
                lineHeight: { xs: "40px", sm: pxToRem(60) }, // Responsive line height
                color: "inherit",
                marginBottom: { xs: "0px", sm: "24px" }, // Responsive bottom margin
                // Optional: Centers the text
              }}
              fontFamily="PloniBold"
              dangerouslySetInnerHTML={{
                __html: highlightHTML(contactSection.title),
              }} // Render raw HTML
             
            />
          </Box>
        )}
      </>
    );
  };
  return (
    <Box
      position="relative"
      sx={{
        "@media(max-width: 767px)": {
          "&::before": {
            content: "''",
            position: "absolute",
            inset: 0,
            background:
              "linear-gradient(to bottom, rgba(255,255,255,0) 50%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 100%)",
            zIndex: -1,
          },
        },
      }}
    >
      <Container
        disableGutters
        sx={{
          pb: { xs: "80px", sm: "120px", md: pxToRem(190) },
        }}
      >
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          gap={{ xs: "60px", sm: "72px", md: "80px" }}
        >
          <Box maxWidth={340} flexShrink={0}>
            {mobile ? <SectionTitle /> : null}
            <Box
              position="relative"
              maxWidth={{ xs: "300px", sm: "310px", md: pxToRem(500) }}
              width="100%"
              height={{ xs: "310px", md: "auto" }}
              display="flex"
              borderRadius={pxToRem(20)}
              boxShadow={`${pxToRem(30)} ${pxToRem(30)} ${pxToRem(
                50
              )} rgba(0,0,0,0.2)`}
              flexShrink={0}
              sx={{
                "&::before": {
                  content: "''",
                  position: "absolute",
                  left: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
                  bottom: { xs: "-30px", md: pxToRem(-40) },
                  width: { xs: "200px", sm: "310px", md: "88%" },
                  height: { xs: "310px", md: "74.1%" },
                  bgcolor: "#FCE181",
                  borderRadius: pxToRem(20),
                  zIndex: -1,
                },
              }}
            >
              <img
                src={PhilosophyImg}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: pxToRem(20),
                }}
              />
            </Box>
          </Box>
          <Box>
            {mobile ? null : <SectionTitle />}
            <BlockBordered mb={{ xs: "40px", sm: pxToRem(50) }}
            //  isAlignedRight={getLanguageClass(contactSection?.subtitle)==="is-hebrew-text"}
             >
              {contactSection.subtitle && (
                <Box
                  maxWidth={pxToRem(600)}
                  sx={{
                    fontSize: { xs: pxToRem(15), sm: pxToRem(22) }, // Responsive font size
                    fontWeight: "500",
                    marginBottom: { xs: "12px", sm: "16px" }, // Responsive bottom margin
                  }}
                  dangerouslySetInnerHTML={{ __html: contactSection.subtitle }} // Render raw HTML
                  className={getLanguageClass(contactSection?.subtitle)}
                />
              )}
            </BlockBordered>
            <Stack
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              gap={{
                xs: "10px",
                md: pxToRem(20),
                "& button": {
                  "@media(max-width: 767px)": { minWidth: "219px" },
                },
              }}
            >
              {contactSection && contactSection.button_1_text && (
                <Button
                  variant="green"
                  onClick={() =>
                    window.open(
                      contactSection.button_1_link,
                      contactSection.target_new_tab_1 ? "_blank" : "_self"
                    )
                  }
                >
                  {contactSection.button_1_text}
                </Button>
              )}
              {contactSection &&
                !contactSection.is_single_button &&
                contactSection.button_2_text && (
                  <Button
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        contactSection.button_2_link,
                        contactSection.target_new_tab_2 ? "_blank" : "_self"
                      )
                    }
                  >
                    {contactSection.button_2_text}
                  </Button>
                )}
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Philosophy;
