import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import IconBtnCircular from "../IconBtnCircular";
import { ReactComponent as Bookmark } from "../../images/bookmark.svg";
import { ReactComponent as Notes } from "../../images/notes.svg";
import { ReactComponent as Share } from "../../images/share.svg";
import { ReactComponent as Volume } from "../../images/volume.svg";
import { ReactComponent as CamcorderIcon } from "../../images/Camcorder_Icon.svg";

import api from "../../api/api";
import Toast from "../Toast";
import { border, borderRadius, padding, styled } from "@mui/system";
import { createBookmark, createNote } from "../../api/course";
import BlockBordered from "../BlockBordered";
import { set } from "react-hook-form";
import AddNoteDialog from "../AddNoteDialog";

const SidePanel = ({
  selectedLesson,
  currentTime,
  setToastOpen,
  handleListenClicked,
  isListening,
  setIsListening,
  handleWatchClicked,
  setBookmarkToastOpen,
  setBookmarkMessage,
  setBookmarkListChanged,
  setNoteListChanged,
  setNoteToastOpen,
  setListenWatchToggle,
  setReservedTime,
}) => {
  //share
  const location = useLocation();
  const theme = useTheme();
  const isMobileSSSMToSSM = useMediaQuery(
    theme.breakpoints.between("sssm", "ssm")
  );
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));

  const handleShareClick = () => {
    const fullUrl = `${window.location.origin}${location.pathname}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        setToastOpen(true);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  //endShare

  //notes
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [noteTimestamp, setNoteTimestamp] = useState(0);
  const handleOpenDialog = () => {
    setNoteTimestamp(Math.floor(currentTime));

    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setNoteTimestamp(0);
    setIsDialogOpen(false);
  };

  const handleNoteTitleChange = (event) => {
    setNoteTitle(event.target.value);
  };

  const handleNoteContentChange = (event) => {
    setNoteContent(event.target.value);
  };

  const handleSaveNote = async (noteTitle, noteContent) => {
    // console.log("Note saved:", { noteTitle, noteContent });
    setNoteListChanged(true);
    const payload = {
      lesson: selectedLesson?.id,
      title: noteTitle,
      content: noteContent,
      timestamp: noteTimestamp,
    };

    try {
      const response = await createNote(selectedLesson?.id, payload);
      setNoteToastOpen(true);
    } catch (error) {
      console.error("Error saving note:", error);
    } finally {
      handleCloseDialog();
      setNoteListChanged(false);
    }
  };

  //end notes

  //start: listen / watch

  const handleToggleListening = () => {
    setListenWatchToggle(true);
    setReservedTime(currentTime);
    if (!isListening) {
      handleListenClicked();
    } else {
      // setIsListening(false);
      handleWatchClicked();
    }
  };
  //end: listen/watch

  // bookmarks
  const [bookmarks, setBookmarks] = useState([]);
  const [disableBookmark, setDisableBookmark] = useState(false);

  const handleAddBookmark = async () => {
    setDisableBookmark(true);
    setBookmarkListChanged(true);
    // console.log("Adding bookmark:", { selectedLesson, currentTime });
    try {
      const response = await createBookmark(selectedLesson.id, currentTime);
      setBookmarks((prevBookmarks) => [...prevBookmarks, response.data]);

      setBookmarkMessage("Bookmark added successfully!");
      setBookmarkToastOpen(true);
    } catch (error) {
      console.error("Error adding bookmark:", error);
      if (
        error?.response?.data?.error ===
        "Maximum bookmarks limit of 10 lectures per course reached."
      ) {
        setBookmarkMessage(
          "You have reached the maximum number of bookmarks for this course."
        );
        setBookmarkToastOpen(true);
      }
    } finally {
      setDisableBookmark(false);
      setBookmarkListChanged(false);
    }
  };

  const handleRemoveBookmark = async (bookmarkId) => {
    try {
      await api.delete(`/api/courses/bookmarks/${bookmarkId}/`);
      setBookmarks((prevBookmarks) =>
        prevBookmarks.filter((bookmark) => bookmark.id !== bookmarkId)
      );
    } catch (error) {
      console.error("Error removing bookmark:", error);
    }
  };

  const isBookmarked = () => {
    return bookmarks.some(
      (bookmark) =>
        bookmark.time === currentTime && bookmark.lesson === selectedLesson.id
    );
  };

  return (
    <>
      {mobile_ssm && (
        <Box
          position="absolute"
          backgroundColor="#f7f6f2"
          width="100%"
          height="65px"
        ></Box>
      )}
      <Stack
        className={mobile_ssm ? "" : "media-controls-group"}
        position={{ xs: "fixed", ssm: "absolute" }}
        top={{ xs: "auto", ssm: pxToRem(30), sm: pxToRem(40) }}
        bottom={{ xs: "-50px", ssm: "auto" }}
        right={{ xs: "auto", ssm: pxToRem(40) }}
        left={{ xs: "50%", ssm: "auto" }}
        gap={{ xs: pxToRem(10), ssm: pxToRem(10) }}
        zIndex={27}
        flexDirection={{ xs: "row", ssm: "column" }}
        justifyContent={{ xs: "space-between", ssm: "flex-start" }}
        alignItems={{ xs: "center", ssm: "stretch" }}
        sx={{
          "& button": {
            backgroundColor: "#fff",
          },
          transform: "translateX(-50%)",
        }}
      >
        <Tooltip
          title={isBookmarked() ? "Remove Bookmark" : "Add Bookmark"}
          enterDelay={0}
          enterTouchDelay={0}
          // PopperProps={{ disablePortal: mobile_ssm ? false : true, sx: {textWrap: "nowrap", "& .MuiTooltip-tooltipPlacementBottom": {mt: {xs: "8px !important", sssm: "12px !important"}}}  }}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              whiteSpace: "nowrap", // Prevent line break
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement={mobile_ssm ? "bottom" : "left"}
        >
          <Box className="tooltip-fix">
            <IconBtnCircular
              sx={{
                width: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                height: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                borderRadius: { xs: "50%", sssm: pxToRem(30), ssm: "50%" },
                padding: { xs: "8px", sssm: "6px 10px", ssm: "8px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="hover-green"
              onClick={
                isBookmarked()
                  ? () =>
                      handleRemoveBookmark(
                        bookmarks.find(
                          (bookmark) =>
                            bookmark.timestamp === currentTime &&
                            bookmark.lesson === selectedLesson.id
                        ).id
                      )
                  : handleAddBookmark
              }
              disabled={disableBookmark}
            >
              <Box sx={{ flexShrink: 0, display: "flex" }}>
                {isBookmarked() ? (
                  <Bookmark color="#026670" />
                ) : (
                  <Bookmark color="#026670" />
                )}
              </Box>
              {isMobileSSSMToSSM && (
                <Typography
                  sx={{
                    color: "#BFBEBB",
                    fontSize: pxToRem(12),
                    fontWeight: 600,
                    ml: pxToRem(11),
                  }}
                >
                  Bookmark
                </Typography>
              )}
            </IconBtnCircular>
          </Box>
        </Tooltip>

        <Tooltip
          title="Add Notes"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              textWrap: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement={mobile_ssm ? "bottom" : "left"}
          onClick={handleOpenDialog}
        >
          <Box>
            <IconBtnCircular
              className="hover-green"
              sx={{
                width: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                height: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                borderRadius: { xs: "50%", sssm: pxToRem(30), ssm: "50%" },
                padding: { xs: "8px", sssm: "6px 10px", ssm: "8px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ flexShrink: 0, display: "flex" }}>
                <Notes height="20.8px" color="#026670" />
              </Box>
              {isMobileSSSMToSSM && (
                <Typography
                  sx={{
                    color: "#BFBEBB",
                    fontSize: pxToRem(12),
                    fontWeight: 600,
                    ml: pxToRem(11),
                  }}
                >
                  Note
                </Typography>
              )}
            </IconBtnCircular>
          </Box>
        </Tooltip>
        <Tooltip
          title="Share Now"
          enterDelay={0}
          enterTouchDelay={0}
          PopperProps={{
            disablePortal: mobile_ssm ? false : true,
            sx: {
              textWrap: "nowrap",
              "& .MuiTooltip-tooltipPlacementBottom": {
                mt: { xs: "8px !important", sssm: "12px !important" },
              },
            },
          }}
          placement={mobile_ssm ? "bottom" : "left"}
          sx={{
            "& + .MuiTooltip-popper .MuiTooltip-tooltip": {
              whiteSpace: "nowrap",
            },
          }}
          onClick={handleShareClick}
        >
          <Box>
            <IconBtnCircular
              className="hover-green"
              sx={{
                width: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                height: { xs: pxToRem(40), sssm: "auto", ssm: pxToRem(48) },
                borderRadius: { xs: "50%", sssm: pxToRem(30), ssm: "50%" },
                padding: { xs: "8px", sssm: "6px 10px", ssm: "8px" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Share color="#026670" style={{ marginRight: pxToRem(1.5) }} />
              {isMobileSSSMToSSM && (
                <Typography
                  sx={{
                    color: "#BFBEBB",
                    fontSize: pxToRem(12),
                    fontWeight: 600,
                    ml: pxToRem(11),
                  }}
                >
                  Share
                </Typography>
              )}
            </IconBtnCircular>
          </Box>
        </Tooltip>
        <Box position="relative" onClick={handleToggleListening}>
          <IconBtnCircular
            sx={{
              width: { xs: pxToRem(40), sssm: pxToRem(37), ssm: pxToRem(48) },
              height: { xs: pxToRem(40), sssm: pxToRem(37), ssm: pxToRem(48) },
            }}
            className="yellow-ic"
          >
            <Box
              sx={{
                flexShrink: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "& svg": {
                  transform: isListening ? "scale(0.9)" : "scale(1)",
                },
              }}
            >
              {isListening ? <CamcorderIcon /> : <Volume color="#026670" />}
            </Box>
          </IconBtnCircular>
          <Box
            className="toggle-tooltip"
            sx={{
              position: "absolute",
              top: "50%",
              right: { ssm: pxToRem(62) },
              left: { xs: "50%", ssm: "auto" },
              transform: {
                xs: "translate(-50%, 144%)",
                sssm: "translate(-50%, 155%)",
                ssm: "translateY(-50%)",
              },
              fontSize: pxToRem(12),
              fontWeight: 600,
              color: "#026670",
              borderRadius: pxToRem(10),
              backgroundColor: "#FCE181",
              whiteSpace: "nowrap",
              padding: `${pxToRem(1)} ${pxToRem(10)}`,
              opacity: 0,
              transition: "opacity 0.1s ease",
            }}
          >
            {isListening ? "Switch to Video" : "Switch to Audio"}
            {/* Listen / Watch */}
          </Box>
        </Box>
      </Stack>

      <AddNoteDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveNote}
      />
    </>
  );
};

export default SidePanel;
