import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createCheckoutSession, validatePromoCode } from "../../api/payment";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Link,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import ModalLayout from "./ModalLayout";
import Switcher from "../../components/Switcher";
import FormInputText from "../../components/FormInputText";

import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { ReactComponent as GPay } from "../../images/G_Pay.svg";
import { ReactComponent as APay } from "../../images/apple-pay.svg";
import { ReactComponent as PayPal } from "../../images/paypal.svg";
import { formatDateDuration, isTodayBetweenDates } from "../../utils/format";
import { subscribeToFreeCourse } from "../../api/course";
import { getMySetting, getStudent } from "../../api/student";
import { pxToRem } from "px2rem2px";

const DividerStyled = styled(Divider)({
  margin: `${pxToRem(30)} 0`,
  borderColor: "#BFBEBB",
});

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  justifyContent: "space-between",
  gap: "10px",
  borderRadius: 0,
  overflow: "auto hidden",
  [theme.breakpoints.down("ssm")]: {
    flexDirection: "column",
  },
}));

const ToggleButtonStyled = styled(ToggleButton)({
  border: "2px solid #BFBEBB !important",
  borderRadius: "10px !important",
  height: "64px",
  minWidth: "87px",
  padding: "0 5px",
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "& p": {
    fontSize: "14px",
    lineHeight: "14px",
    fontWeight: 700,
    color: "#333",
    textTransform: "none",
  },
  "&.Mui-selected": {
    borderColor: "#333 !important",
    backgroundColor: "transparent",
  },
});

const TextFieldStyled = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 30,
    backgroundColor: "#EDECE8",
  },
  "& input": {
    height: "54px",
    borderRadius: 30,
    padding: "0 150px 0 40px",
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
});

function formatCurrency(amount, minimumFractionDigits, maximumFractionDigits) {
  amount = parseFloat(amount);
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  });
}

const formatCc = (e, onChange) => {
  if (e.target.value.length <= 19) {
    onChange(
      e.target.value
        .replace(/\D/g, "")
        .replace(/(\d{4})(\d{0,4})(\d{0,4})(\d{0,4})/, "$1 $2 $3 $4")
        .trim()
    );
  }
};

const formatCvv = (e, onChange) => {
  if (e.target.value.length <= 4) {
    onChange(e.target.value.replace(/\D/g, "").trim());
  }
};

const formatExpiration = (e, onChange) => {
  let inputValue = e.target.value;
  inputValue = inputValue.replace(/\D/g, "");

  if (inputValue.length <= 4) {
    inputValue = inputValue.replace(/(\d{2})(\d{0,2})/, "$1/$2").trim();
  }
  onChange(inputValue);
};

const PurchaseModal = (props) => {
  const {
    course,
    openThankModal,
    openErrorModal,
    price,
    type: initialType,
    ...restProps
  } = props;
  const [isPromoVisible, setIsPromoVisible] = useState(false);
  const [tabValue, setTabValue] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();

  const [type, setType] = useState(initialType);

  console.log("typehounaaa", type);

  console.log("type is :", type);

  console.log("coursedatahere", course);

  const handleTabChange = (event, newValue) => {
    if (newValue) {
      setTabValue(newValue);
      setTimeout(() => {
        setIsCollapsed(true);
      }, 0);
    } else {
      setIsCollapsed(false);
      setTimeout(() => {
        setTabValue(newValue);
      }, 300);
    }
  };

  const { control, setValue, handleSubmit } = useForm({
    values: {
      creditCard: "",
      expiration: "",
      cvv: "",
    },
  });

  const isOnSale =
    course?.is_on_sale &&
    isTodayBetweenDates(course?.sale_start_date, course?.sale_end_date);

  // const onSubmit = async (data) => {
  //   let checkoutPrice;

  //   if (type === "normal") {
  //     checkoutPrice = isOnSale ? course.sale_price : course.subscription_price;
  //   } else {
  //     checkoutPrice = course.lifetime_price;
  //   }

  //   try {
  //     const response = await createCheckoutSession(
  //       course.id,
  //       type,
  //       checkoutPrice
  //     );
  //     window.location.href = response.data.checkout_url;
  //   } catch (error) {
  //     console.error("Error creating checkout session:", error);
  //     openErrorModal();
  //   }
  // };

  // promo code
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isValid, setIsValid] = useState(null);

  const validateCode = async (promoCode) => {
    try {
      const response = await validatePromoCode(promoCode);
      console.log("promo code response:", response);

      setIsValid(true);
      setSuccessMessage("Promo code applied successfully.");
      setErrorMessage("");
    } catch (error) {
      console.log("promocodeerrormesg", error.response.data.message);
      setIsValid(false);
      setErrorMessage(error?.response?.data?.message);
      setSuccessMessage("");
    }
  };

  const onSubmit = async (data) => {
    let checkoutPrice;
    let priceType;

    if (type === "normal") {
      checkoutPrice = isOnSale ? course.sale_price : course.subscription_price;
      priceType = "Normal";
    } else {
      checkoutPrice = course.lifetime_price;
      priceType = "Lifetime";
    }

    const priceClass = "Normal"; // Always Normal for this component

    try {
      const response = await createCheckoutSession(
        course.id,
        // type,
        // checkoutPrice,
        priceType,
        priceClass,
        promoCode
      );
      window.location.href = response.data.checkout_url;
    } catch (error) {
      console.error("Error creating checkout session:", error);
      openErrorModal();
    }
  };

  //switch toggle

  const handleSwitcherChange = (event) => {
    setType(event.target.checked ? "lifetime" : "normal");
  };

  //sjnsjnjsn
  const handleFreeOnSaleSubscription = () => {
    subscribeToFreeCourse(course.id);
    navigate(`/course/${course.slug}`);
  };

  // student settings
  const [isEligible, setIsEligible] = useState(false);

  useEffect(() => {
    // openThankModal();
    // openErrorModal();
    // return;

    const fetchStudentSetting = async () => {
      try {
        const paymentPlanResponse = await getMySetting();
        setIsEligible(paymentPlanResponse?.data?.is_eligeble_for_payment_plan);
      } catch (error) {
        console.error("Error fetching student or payment plan:", error);
      }
    };
    fetchStudentSetting();
  }, []);

  return (
    <ModalLayout {...restProps} headerTitle={course?.name}>
      <Box textAlign="center">
        <Typography
          component="p"
          fontSize={pxToRem(20)}
          fontWeight={700}
          pb={pxToRem(20)}
          mb={pxToRem(20)}
          borderBottom="1px solid #BFBEBB"
        >
          Course {type === "normal" ? "Subscription" : "Purchase"} for
        </Typography>
        <Stack
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="15px"
          mb="15px"
        >
          {isOnSale && type === "normal" ? (
            <>
              <Typography
                fontSize={pxToRem(30)}
                lineHeight="30px"
                fontWeight="700"
                color="#026670"
              >
                {course?.sale_price > 0
                  ? formatCurrency(course?.sale_price)
                  : "Free"}
              </Typography>
              <Typography
                fontSize={pxToRem(16)}
                fontWeight="600"
                color="#BFBEBB"
                sx={{
                  textDecoration: "line-through",
                  transform: "translateY(3px)",
                }}
              >
                {formatCurrency(course.subscription_price)}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                fontSize={pxToRem(30)}
                lineHeight="30px"
                fontWeight="700"
                color="#026670"
              >
                {type === "normal"
                  ? formatCurrency(course.subscription_price)
                  : formatCurrency(course.lifetime_price)}
              </Typography>
            </>
          )}
        </Stack>
        <Typography
          component="p"
          fontSize="12px"
          fontWeight={600}
          color="#BFBEBB"
          mb={pxToRem(30)}
          sx={{ color: "#454545" }}
        >
          {type === "normal"
            ? `Valid for ${formatDateDuration(course.duration)}`
            : "Valid Forever"}
        </Typography>
        <Switcher
          name="lifetime-access"
          label="Need Lifetime Access"
          defaultChecked={type === "lifetime" ? true : false}
          onChange={handleSwitcherChange}
        />
        <DividerStyled sx={{ mb: pxToRem(30) }} />
        <Button
          fullWidth
          endIcon={<Chevron />}
          onClick={() => setIsPromoVisible(!isPromoVisible)}
          sx={{
            fontSize: "16px",
            justifyContent: "space-between",
            "& .MuiButton-endIcon svg": {
              transform: isPromoVisible ? "rotate(-90deg)" : "rotate(90deg)",
            },
          }}
        >
          Have a Promo?
        </Button>
        <Collapse in={isPromoVisible} timeout="auto" unmountOnExit>
          <Stack
            component="form"
            position="relative"
            height="54px"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="#EDECE8"
            borderRadius="30px"
            mt="10px"
          >
            <TextFieldStyled
              fullWidth
              placeholder="Apply Promo Code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              sx={{
                "& input": {
                  fontSize: "12px",
                  fontWeight: 600,
                  "&::placeholder": { color: "#BFBEBB", opacity: 1 },
                },
              }}
            />
            <Button
              variant="yellow"
              size="small"
              sx={{
                position: "absolute",
                top: "50%",
                right: "8px",
                transform: "translateY(-50%)",
                minWidth: "120px",
                fontSize: "14px !important",
              }}
              onClick={() => validateCode(promoCode)}
            >
              Apply
            </Button>
          </Stack>
          {(errorMessage || successMessage) && (
            <Typography
              sx={{
                color: errorMessage ? "red" : "green",
                // textAlign: "left",
                // margin: "10px 0px 0px 40px",
                mt: "10px",
              }}
            >
              {errorMessage || successMessage}
            </Typography>
          )}
        </Collapse>
        <DividerStyled sx={{ mt: pxToRem(30) }} />
        {isEligible && (
          <>
            <Typography
              variant="medium"
              component="p"
              fontSize="16px"
              mb="20px"
            >
              You are eligible for a <b>Payment plan</b> of 3 payments of
              <b> $85.39</b>
            </Typography>
            <Button
              variant="yellow"
              size="small"
              sx={{ fontSize: "14px !important" }}
            >
              Select Payment Plan
            </Button>
            <DividerStyled />
          </>
        )}

        {isOnSale && type === "normal" && course?.sale_price === "0.00" ? (
          <>
            <Button
              variant="yellow"
              type="submit"
              sx={{ fontSize: "16px", fontWeight: 700 }}
              onClick={handleFreeOnSaleSubscription}
            >
              Subscribe
            </Button>
          </>
        ) : (
          <>
            <Box pt="25px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                  variant="yellow"
                  type="submit"
                  sx={{ fontSize: "16px", fontWeight: 700 }}
                >
                  Checkout {formatCurrency(course?.subscription_price)}
                </Button>
              </form>
            </Box>
            {/*   <ToggleButtonGroupStyled
              exclusive
              fullWidth
              value={tabValue}
              onChange={handleTabChange}
            >
              <ToggleButtonStyled value="creditCard">
                <Typography>Credit Card</Typography>
              </ToggleButtonStyled>
              <ToggleButtonStyled value="googlePay">
                <GPay />
              </ToggleButtonStyled>
              <ToggleButtonStyled value="applePay">
                <APay />
              </ToggleButtonStyled>
              <ToggleButtonStyled value="paypal">
                <PayPal />
              </ToggleButtonStyled>
            </ToggleButtonGroupStyled> */}
          </>
        )}

        {/*  {tabValue === "creditCard" ? (
              <Collapse in={isCollapsed}>
                <Box pt="25px">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Button
                      variant="yellow"
                      type="submit"
                      sx={{ fontSize: "16px", fontWeight: 700 }}
                    >
                      Checkout & Pay Now
                    </Button>
                  </form>
                </Box>
              </Collapse>
            ) : null}

            {tabValue === "googlePay" ? (
              <Collapse in={isCollapsed}>
                <Box pt="25px">
                  <Button variant="yellow">Continue with Google Pay</Button>
                </Box>
              </Collapse>
            ) : null}

            {tabValue === "applePay" ? (
              <Collapse in={isCollapsed}>
                <Box pt="25px">
                  <Button variant="yellow">Continue with Apple Pay</Button>
                </Box>
              </Collapse>
            ) : null}

            {tabValue === "paypal" ? (
              <Collapse in={isCollapsed}>
                <Box pt="25px">
                  <Button variant="yellow">Continue with Paypal</Button>
                </Box>
              </Collapse>
            ) : null}
          </> */}


        <Typography
          component="p"
          fontSize="12px"
          fontWeight={500}
          color="#BFBEBB"
          textAlign="left"
          mt="30px"
        >
          * Please note that all purchases are final. By purchasing, you agree
          to our{" "}
          <Link
            component={NavLink}
            to="/terms-conditions"
            sx={{
              textDecoration: "none",
              color: "#026670",
              position: "relative",
              "&:hover": {
                color: "#333333",
              },
              "&::after": {
                content: "''",
                position: "absolute",
                left: 0,
                bottom: 0,
                height: "2px",
                width: "0",
                backgroundColor: "#333333",
                transition: "width 0.3s ease",
              },
              "&:hover::after": {
                width: "100%",
              },
            }}
          >
            Terms & Conditions
          </Link>
          . No refunds will be provided after a course is purchased, regardless
          of the circumstances. Please review the course content and details
          carefully before proceeding with your purchase.
        </Typography>
      </Box>
    </ModalLayout>
  );
};

export default PurchaseModal;
