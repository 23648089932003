import { Box, Link, Rating, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import BlockBordered from "./BlockBordered";
import TextLink from "./TextLink";
import PlayBtn from "./PlayBtn";

import { ReactComponent as Play } from "../images/play.svg";
import { useEffect, useRef, useState } from "react";
import { Stack, styled } from "@mui/system";
import { getLanguageClass } from "../utils/languageClass";
import { StarIcon, StarIconContainer } from "../pages/courses/StarIcon";

const ClampedText = styled(Typography)(({ showBlur }) => ({
  position: "relative",
  maxHeight: "3.6em",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  lineClamp: 2,
  lineHeight: "1.8em",
  ":before": showBlur
    ? {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "1em",
        background: "linear-gradient(to bottom, transparent, #ffffff)",
      }
    : {},
}));

const CourseSubtitle = ({ subtitle }) => {
  const textRef = useRef(null);
  const [showBlur, setShowBlur] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setShowBlur(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [subtitle]);

  return (
    <Box position="relative" overflow="hidden">
      <ClampedText
        ref={textRef}
        showBlur={showBlur}
        variant="medium"
        component="p"
        color="#333333"
        className={getLanguageClass(subtitle)}
      >
        {subtitle}
      </ClampedText>
    </Box>
  );
};

const Label = styled(Typography)(({ children }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "24px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#333333",
    backgroundColor: "white",
    border: "2px solid #9e9e9e",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 25px",
  };
});

const Slide = (props) => {


  console.log("dndjnjdn",props)
  return (
    <Box
      position="relative"
      bgcolor="#fff"
      borderRadius={pxToRem(20)}
      flexGrow={1}
      sx={{
        minHeight: pxToRem(450),
        height: "100%",
        transition: "0.2s ease",
        "&::before": {
          content: "''",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: `calc(100% - ${pxToRem(40)})`,
          height: "100%",
          backgroundColor: "#FCE181",
          borderRadius: pxToRem(20),
          transform: "translate(-50%,-50%)",
          zIndex: -1,
          transition: "0.2s ease",
        },
        "&:hover": {
          boxShadow: {
            xs: "none",
            sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
          },
          "&::before": {
            height: `calc(100% + ${pxToRem(40)})`,
          },
          "& .slideTitle": {
            color: "primary.main",
          },
          "& .MuiButton-root": {
            color: "#333",
            backgroundColor: "transparent",
            "& span.MuiTypography-root::after": {
              content: "''",
              position: "absolute",
              left: 0,
              bottom: pxToRem(-8),
              height: pxToRem(2),
              width: "100%",
              backgroundColor: "#333",
            },
          },
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={pxToRem(240)}
        borderRadius={`${pxToRem(20)} ${pxToRem(20)} 0 0`}
        overflow="hidden"
      >
        {props.videoLink ? (
          <Link
            to={props.videoLink}
            component={NavLink}
            sx={{ position: "absolute" }}
          >
            <PlayBtn
              width={{ xs: "64px", md: pxToRem(96) }}
              height={{ xs: "64px", md: pxToRem(96) }}
            >
              <Play
                color="#026670"
                width={pxToRem(20)}
                height={pxToRem(24)}
                style={{ marginLeft: "6%" }}
              />
            </PlayBtn>
          </Link>
        ) : null}
        <img
          style={{ objectFit: "cover", width: "100%", height: "100%" }}
          src={props.image}
          alt=""
        />
      </Box>

      {props?.isBlog && (
        <>
          <Stack
            width="calc(100% - 60px)"
            flexDirection="row"
            alignContent="center"
            justifyContent="flex-end"
            overflow="hidden"
            flexWrap="nowrap"
            gap="8px"
            position="absolute"
            top="170px"
            right="30px"
          >
            <Label>{props.label}</Label>
          </Stack>
        </>
      )}
      <Box
        p={pxToRem(40)}
        bgcolor="#fff"
        borderRadius={`0 0 ${pxToRem(20)} ${pxToRem(20)}`}
        overflow="hidden"
        flexGrow={1}

      >
        {props?.isBlog && (
          <Typography
            component="p"
            fontSize="14px"
            fontWeight={600}
            color="#BFBEBB"
            whiteSpace="nowrap"
            marginBottom="10px"
          >
            {props.date}
          </Typography>
        )}
        <Typography
          className={`slideTitle ${getLanguageClass(props.title)}`}
          fontSize={pxToRem(22)}
          fontWeight={700}
          lineHeight={pxToRem(30)}
          color="secondary.main"
          mb={pxToRem(10)}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}

        >
          {props.title}
        </Typography>

        <BlockBordered
          isAlignedRight={
            props?.isBlog
              ? getLanguageClass(props?.text) === "is-hebrew-text"
              : getLanguageClass(props?.subtitle) === "is-hebrew-text"
          }
          mb={props.blogLink ? pxToRem(20) : 0}
        >
          <CourseSubtitle
            subtitle={props?.isBlog ? props?.text : props?.subtitle}
          />
        </BlockBordered>

        {props.blogLink ? (
          <Box>
            <TextLink isBlog href={props.blogLink}>
              Read More
            </TextLink>
          </Box>
        ) : null}


        {props?.includeCourseDetails && (
          <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            height: "100%",
            gap: pxToRem(10),
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",

              flexGrow: 1,
              marginTop: "2px",
            }}
          >
            <Typography
              component="p"
              fontSize="12px"
              fontWeight={600}
              color="#BFBEBB"
              whiteSpace="nowrap"
            >
              Posted {new Date(props?.date).toLocaleDateString()}&nbsp; •
              &nbsp;
              {props?.subscribers}{" "}
              {props?.subscribers === 1 ? "Subscriber" : "Subscribers"}
            </Typography>
          </Box>

          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              component="p"
              fontSize="14px"
              fontWeight={600}
              color="#BFBEBB"
              whiteSpace="nowrap"
              marginRight="2px"
            >
              {Number(props?.rating).toFixed(1)}
            </Typography>

            <Rating
              name="read-only"
              value={props?.rating}
              readOnly
              size="small"
              icon={<StarIcon color="#fce181" size={13} />}
              emptyIcon={<StarIcon color="#dfdedd" size={13} />}
              sx={{ marginLeft: "2px", marginTop: "-2px" }}
              IconContainerComponent={StarIconContainer}
            />
          </Box>
        </Box>
        )}
      </Box>
    </Box>
  );
};

export default Slide;
