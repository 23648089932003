import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Container, Typography, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import BlockBordered from "../../components/BlockBordered";
import PlayBtn from "../../components/PlayBtn";
import PresentationImg from "../../images/presentation-img1.jpg";
import { ReactComponent as Play } from "../../images/play.svg";
import { getPresentationSection } from "../../api/cmsApi";
import { url } from "../../api/config";
import highlightHTML from "../../utils/highlightHTML";
import ReactPlayer from "react-player";
import { getLanguageClass } from "../../utils/languageClass";
import VideoPlayer from "../course/components/VideoPlayer";
const Presentation = () => {
  const [presentationSection, setPresentationSection] = useState({
    title: "",
    subtitle: "",
    button_link: "",
    button_text: "",
    target_new_tab: false,
  });

  const [videoUrl, setVideoUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const fetchPresentation = () => {
    getPresentationSection()
      .then((res) => {
        setPresentationSection(res.data);
        setVideoUrl(`${url}${res.data.video_upload}`);
        console.log("video url", `${url}${res.data.video_upload}`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchPresentation();
  }, []);
  // console.log("video url", videoUrl);

  const customPosition = useRef(null);

  return (
    <Container
      disableGutters
      sx={{ pb: { xs: "50px", sm: "130px", md: pxToRem(150) } }}
    >
      <Box>

        {presentationSection?.title && (
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              maxWidth={pxToRem(460)}
              sx={{
                fontSize: { xs: "1.4rem", sm: "2.5rem" },
                fontWeight: 500,
                lineHeight: { xs: "40px", sm: pxToRem(60) },
                color: "inherit",
                marginBottom: { xs: "0px", sm: "24px" },
              }}
              fontFamily="PloniBold"
              dangerouslySetInnerHTML={{
                __html: highlightHTML(presentationSection?.title, true),
              }}
            />
          </Box>
        )}
        <Box>
          <BlockBordered mb={{ xs: "20px", sm: pxToRem(50) }}>
            {presentationSection?.subtitle && (
              <Box
                maxWidth={pxToRem(600)}
                sx={{
                  fontSize: { xs: pxToRem(15), sm: pxToRem(22) },
                  fontWeight: "500",
                  marginBottom: { xs: "12px", sm: "16px" },
                }}
                dangerouslySetInnerHTML={{
                  __html: presentationSection?.subtitle,
                }}
                className={getLanguageClass(presentationSection?.subtitle)}
              />
            )}
          </BlockBordered>
        </Box>

        {presentationSection && presentationSection?.button_text && (
          <Box>
            <Button
              variant="yellow"
              sx={{ position: "relative", zIndex: 1 }}
              onClick={() => {
                presentationSection?.button_link &&
                  window.open(
                    presentationSection?.button_link,
                    presentationSection?.target_new_tab ? "_blank" : "_self"
                  );
              }}
            >
              {presentationSection?.button_text}
            </Button>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "870px",
          },
          ml: "auto",
          mt: "50px",
        }}
        ref={customPosition}
      >
        <VideoPlayer
          videoUrlCustom={videoUrl}
          thumbnailCustom={PresentationImg}
          customPosition={customPosition}
          useCustomPlayer
        />
      </Box>

      <Box
        position="relative"
        maxWidth={pxToRem(960)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => setIsPlaying(true)}
        borderRadius={pxToRem(20)}
        boxShadow={
          isPlaying
            ? ""
            : `${pxToRem(-30)} ${pxToRem(30)} ${pxToRem(50)} rgba(0,0,0,0.2)`
        }
        ml="auto"
        mt={{ xs: "20px", sm: "50px" }}
        mr={{ xs: 0, sm: "30px", md: 0 }}
        sx={{
          "&::before": {
            content: "''",
            position: "absolute",
            top: { xs: "-30px", md: pxToRem(-40) },
            right: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
            width: { xs: "55%", sm: pxToRem(550) },
            height: { xs: "75%", sm: pxToRem(400) },

            borderRadius: pxToRem(20),
            zIndex: -1,
          },
        }}
      ></Box>
    </Container>
  );
};

export default Presentation;
