import React, { useState } from "react";
import {
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { Button, Stack, Typography } from "@mui/material";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import { pxToRem } from "px2rem2px";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as BulletPoint } from "../../images/bullet-point.svg";

const NavGroup = (props) => {
  const navigate = useNavigate();
  const [showAllLinks, setShowAllLinks] = useState(false);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const toggleShowAllLinks = () => {
    setShowAllLinks(!showAllLinks);
  };

  const handleButtonClick = () => {
    if (props?.isSubscribedCourses) {
      navigate("/user/courses");
    } else {
      toggleShowAllLinks();
    }
  };

  const childrenArray = Array.isArray(props.children)
    ? props.children
    : [props.children];
  const linksToShow = showAllLinks ? childrenArray : childrenArray.slice(0, 4);

  console.log("isArray", Array.isArray(props.children));

  const [isExpanded, setIsExpanded] = useState(
    props.title === "Categories" || !isMediumScreen
  );

  const handleToggle = () => {
    if (props.title !== "Categories" && isMediumScreen) {
      setIsExpanded((prev) => !prev);
    }
  };

  console.log("selectedcategorynavgroup", props?.selectedCategory);

  return (
    <Stack
      gap={{ xs: "20px", md: pxToRem(30) }}
      // borderBottom={isMediumScreen ? "none" : "1px solid rgba(191,190,187,0.5)"}
      pb={pxToRem(30)}
      mb={{ xs: pxToRem(30), md: 0 }}
      borderBottom={{ xs: "1px solid #BFBEBB80", md: "none" }}
      sx={{
        "&:last-child": {
          pb: { xs: 0, md: pxToRem(30) },
          mb: { xs: pxToRem(10), md: 0 },
          borderBottom: "none",
        },
      }}
    >
      <Stack justifyContent="space-between">
        <Stack
          flexDirection="row"
          alignItems="center"
          onClick={handleToggle}
          sx={{ cursor: props.title !== "Categories" ? "pointer" : "default" }}
        >
          <Typography
            fontSize={{ xs: "16px", md: "22px" }}
            fontWeight={{ xs: "600", md: "bold" }}
            color="#026670"
            whiteSpace="nowrap"
          >
            {props?.title}
          </Typography>
          {props?.title !== "Categories" && isMediumScreen && (
            <KeyboardArrowDownIcon
              sx={{
                color: "#026670",
                width: pxToRem(31),
                height: pxToRem(31),
                transition: "all 300ms ease-in-out",
                transform: isExpanded ? "rotate(-180deg)" : "rotate(0)",
              }}
            />
          )}
        </Stack>
        {props?.title === "Categories" ? (
          <Stack
            flexDirection={isMediumScreen ? "row" : "column"}
            gap={{ xs: "12px 5px", md: "8px" }}
            flexWrap="wrap"
            marginTop="15px"
            sx={{ "& a": { fontSize: "15px" } }}
          >
            {linksToShow.map((child, index) => {
              return (
                <Chip
                  key={index}
                  label={child}
                  variant="outlined"
                  onClick={() => props?.handleCategoryClick(child.key)}
                  sx={{
                    height: "26px",
                    maxWidth: "90%",
                    borderColor: "#026670",
                    cursor: "pointer",

                    "&:hover": {
                      backgroundColor: "#FCE181 !important",
                      borderColor: "#FCE181 !important",
                      "& .MuiTypography-root": { color: "#026670" },
                    },
                    "& .MuiTypography-root": {
                      fontSize: "14px",
                      // color: "#026670",
                    },
                    ...(String(props?.selectedCategory) === child.key && {
                      backgroundColor: "#FCE181",
                      borderColor: "#FCE181",
                      "& .MuiTypography-root": { color: "#026670" },
                    }),
                  }}
                />
              );
            })}
          </Stack>
        ) : (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List>
              {linksToShow.map((child, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    sx={{ p: 0, ":hover": { backgroundColor: "unset" } }}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <BulletPoint style={{ marginRight: "12px" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={child}
                      sx={{
                        "& .MuiTypography-root": {
                          fontWeight: 600,
                          fontSize: { xs: "14px", md: "15px" },
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {props.itemsLength > 4 && (
              <Button
                onClick={handleButtonClick}
                endIcon={<Chevron />}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#BFBEBB",
                  fontSize: "15px",
                  pl: "20px",
                  "& .MuiButton-endIcon": { ml: pxToRem(12) },
                  "& svg": {
                    transform: showAllLinks ? "rotate(270deg)" : "rotate(0deg)",
                    mt: pxToRem(0), // Adjust this value to align the icon correctly
                  },
                }}
              >
                {showAllLinks ? "View Less" : "View More"}
              </Button>
            )}
          </Collapse>
        )}
      </Stack>

      {!isMediumScreen && <Divider sx={{ width: "90%" }} />}
    </Stack>
  );
};

export default NavGroup;
