import {
  Box,
  Dialog,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "px2rem2px";
import { NavLink } from "react-router-dom";
import IconBtnCircular from "../../components/IconBtnCircular";
import { useAuth } from "../../utils/AuthContext";

import { ReactComponent as Close } from "../../images/close.svg";
import { getLanguageClass } from "../../utils/languageClass";

const LinkStyled = styled(Link)({
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: 600,
  color: "#BFBEBB",
  textAlign: "center",
  "&:hover": {
    color: "#333",
  },
});

const ModalLayout = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("ssm"));
  const { isAuthenticated } = useAuth();
  const { headerTitle, ...restProps } = props;

  return (
    <Dialog
      {...restProps}
      maxWidth="xs"
      scroll="body"
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return;
        }
      }}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "500px !important",
          borderRadius: pxToRem(20),
          verticalAlign: "center",
          backgroundColor: "#F7F6F2",
          margin: {xs: "20px !important" , ssm: `${pxToRem(32)} !important`}
        },
        zIndex: 1500,
      }}
    >
      <Stack
        position="relative"
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={pxToRem(20)}
        bgcolor="#EDECE8"
        p={{ xs: `${pxToRem(30)} ${pxToRem(25)} ${pxToRem(40)} ${pxToRem(25)}`, ssm: `${pxToRem(40)} ${pxToRem(25)}`, sm: `${pxToRem(40)} ${pxToRem(60)}` }}
        sx={{ "& button": { boxShadow: "none" } }}
      >
        <Typography
          fontSize={{xs: pxToRem(25), sm: pxToRem(30)}}
          lineHeight="30px"
          fontWeight="900"
          fontFamily="PloniBold"
          letterSpacing="-0.44px"
          textAlign="right"
          className={getLanguageClass(headerTitle,false,true)}
        >


          {props.headerTitle}

        </Typography>
        <IconBtnCircular onClick={() => props.onClose()} sx={{width: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)}, height: {xs: pxToRem(35), ssm: pxToRem(40), sm: pxToRem(48)}}}>
          <Close color="#026670" />
        </IconBtnCircular>
      </Stack>
      <Box position="relative" p={{ xs: `${pxToRem(30)} ${pxToRem(25)}`, ssm: `${pxToRem(40)} ${pxToRem(25)}`, sm: `${pxToRem(40)} ${pxToRem(60)}` }}>
        {props.children}
        <Stack
          flexDirection={{sssm: "row"}}
          alignItems="center"
          justifyContent="center"
          gap={{ xs: pxToRem(20), ssm: pxToRem(40) }}
          borderTop="1px solid #BFBEBB"
          mt={{ xs: pxToRem(30), ssm: pxToRem(40) }}
          pt={{ xs: pxToRem(30), ssm: pxToRem(40) }}
        >
          {isAuthenticated ? (
            <>
              <LinkStyled to="" component={NavLink} underline="none">
                Refund Policy
              </LinkStyled>
              <LinkStyled to="" component={NavLink} underline="none">
                Privacy Policy
              </LinkStyled>
              <LinkStyled to="" component={NavLink} underline="none">
                Terms Of Service
              </LinkStyled>
            </>
          ) : (
            <LinkStyled to="" component={NavLink} underline="none">
              Need Help?
            </LinkStyled>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ModalLayout;
