import { Box, Skeleton } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import TagCloud from "react-tag-cloud";
import { pxToRem } from "px2rem2px";
import { getTagCloud } from "../api/cmsApi";
import tagCloudTemplate from "../utils/tagCloudTemplate";
import transformArrayToCloud from "../utils/transformArrayToCloud";
import { fontFamily } from "@mui/system";
const TagsCloud = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(true);
  const [tagCloud, setTagCloud] = useState([]);
  const fetchTagCloud = () => {
    getTagCloud()
      .then((res) => {
        setTagCloud(transformArrayToCloud(res.data.keywords, tagCloudTemplate));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchTagCloud();
  }, []);
  useEffect(() => {
    setInterval(() => {
      forceUpdate();
    }, 5000);
  }, [forceUpdate]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Box
      height={pxToRem(700)}
      borderRadius={pxToRem(40)}
      sx={{
        backgroundImage: "linear-gradient(to bottom, transparent,#ffffff 100%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: { xs: "120px 0", lg: pxToRem(150) },
          width: "100%",
        }}
      >
        {loading ? (
          [...Array(5).keys()].map((val) => (
            <Skeleton
              key={val}
              variant="text"
              animation="wave"
              sx={{ fontSize: "50px" }}
            />
          ))
        ) : (
          <TagCloud
            className="tagCloud"
            style={{
              fontFamily: "Pioni",
              fontWeight: "bold",
              padding: 8,
              width: "100%",
              height: "100%",
            }}
          >
            {tagCloud &&
              tagCloud.map((element, index) => (
                <div
                  key={index}
                  text={element.text}
                  style={{ ...element.style, fontFamily: "PloniBold" }}
                  rotate={element.rotate}
                  className="hovered"
                >
                  {element.text}
                </div>
              ))}
          </TagCloud>
        )}
      </Box>
    </Box>
  );
};

export default TagsCloud;
