export default function highlightHTML(inputHtml, isHero = false) {
  // Append a space to handle <u> at the end of the string
  return inputHtml.replace(
    /<u>(.*?)<\/u>/g,
    isHero
      ? '<span class="highlighted">$1</span>'
      : '<div class="highlighted">$1</div>'
  );
  // Remove the appended space
}
