import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Visa from "../../images/credit-cards/visa.svg";
import Amex from "../../images/credit-cards/amex.svg";
import Discover from "../../images/credit-cards/discover.svg";
import Mastercard from "../../images/credit-cards/mastercard.svg";
import Paypal from "../../images/credit-cards/paypal.svg";
import { getDonationSettings } from "../../api/cmsApi";

const TextFieldStyled = styled(TextField)(({ theme }) => {
  return {
    width: "400px",
    height: "72px",
    borderRadius: "calc(infinity * 1px)",
    backgroundColor: "#EDECE8",
    justifyContent: "center",
    "& input": {
      fontSize: "40px",
      fontWeight: 700,
      color: "#026670",
      padding: "0 20px",
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "48px",
      "& input": {
        fontSize: "25px",
      },
    },
    [theme.breakpoints.down("sssm")]: {
      width: "100%",
    },
  };
});

const DonationAmount = () => {
  const [amounts, setAmounts] = useState([]);
  const [taxId, setTaxId] = useState("");
  const fetchDonationSettings = () => {
    getDonationSettings()
      .then((res) => {
        setAmounts([...res.data.amounts, "Other"]);
        setTaxId(res.data.tax_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchDonationSettings();
  }, []);

  const [selectedAmount, setSelectedAmount] = useState("100");
  const [customAmount, setCustomAmount] = useState("$100");
  const inputRef = useRef(null);

  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
    if (amount === "Other") {
      inputRef.current?.focus();
    } else {
      setCustomAmount(`$${amount}`);
    }
  };

  const handleCustomAmountChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setCustomAmount(`$${value}`);
    setSelectedAmount("Other");
  };

  return (
    <Box
      borderBottom="1px solid rgba(191,190,187,0.5)"
      pb={{ xs: "50px", ssm: "80px" }}
    >
      <Typography
        variant="sectionTitle"
        component="h2"
        fontSize="36px"
        mb={{ xs: "36px", sm: "50px" }}
      >
        Choose a <span className="highlighted">donation amount</span>
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fit,minmax(130px,1fr))",
            ssm: "repeat(auto-fit,minmax(150px,1fr))",
          },
          gap: "8px",
          mb: { xs: "30px", ssm: "60px" },
        }}
      >
        {amounts &&
          amounts.map((amount) => (
            <Button
              key={amount}
              onClick={() => handleButtonClick(amount)}
              sx={{
                height: { xs: "40px", sm: "64px" },
                borderRadius: "calc(infinity * 1px)",
                backgroundColor:
                  selectedAmount === amount ? "#FCE181" : "transparent",
                border: "2px solid",
                borderColor: selectedAmount === amount ? "#FCE181" : "#026670",
                fontSize: { xs: "18px", sm: "25px" },
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#FCE181",
                  color: "#026670",
                  border:
                    selectedAmount === amount
                      ? "2px solid #FCE181"
                      : "2px solid #026670",
                },
              }}
            >
              {amount === "Other" ? "Other" : `$${amount}`}
            </Button>
          ))}
      </Box>
      <Stack
        flexDirection={{ xs: "column-reverse", ssm: "row-reverse", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        gap="25px"
      >
        <Box textAlign={{ xs: "center", ssm: "left" }}>
          {taxId && (
            <Typography fontWeight="500" mb="8px">
              All Donations Are Tax Deductible. <strong>Tax ID: {taxId}</strong>
            </Typography>
          )}
          <Stack
            flexDirection="row"
            justifyContent={{ xs: "center", ssm: "flex-start" }}
            flexWrap="wrap"
            gap={{ xs: "5px", ssm: "11px" }}
          >
            <img src={Visa} alt="" />
            <img src={Amex} alt="" />
            <img src={Discover} alt="" />
            <img src={Mastercard} alt="" />
            <img src={Paypal} alt="" />
          </Stack>
        </Box>

        <TextFieldStyled
          inputRef={inputRef}
          placeholder="Enter amount"
          value={customAmount}
          onChange={handleCustomAmountChange}
        />
      </Stack>
    </Box>
  );
};

export default DonationAmount;
