import { useState, useEffect, useRef } from "react";
import { Box, Button, IconButton, Stack } from "@mui/material";

import { ReactComponent as Delete } from "../../images/delete.svg";
import { ReactComponent as Close } from "../../images/close-circle.svg";
import { pxToRem } from "px2rem2px";

const DeleteBtn = ({ onDelete, isNote }) => {
  const [open, setOpen] = useState(false);
  const deleteBtnRef = useRef(null);

  const handleDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete();
    setOpen(false);
  };

  useEffect(() => {
    // close on outside click
    const handleClickOutside = (event) => {
      if (deleteBtnRef.current && !deleteBtnRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);

  return (
    <Box ref={deleteBtnRef} className="deleteBtn" display={open ? "flex" : "none"}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
        sx={{
          p: 0,
          "&:hover": {
            backgroundColor: "#fff",
            "& svg": { color: "rgb(211, 47, 47)" },
          },
        }}
      >
        <Delete width={18} height={18} color="#333" />
      </IconButton>
      <Stack
        className="deleteBtnGroup"
        display={open ? "flex" : "none"}
        flexDirection="row"
        alignItems="center"
        position="absolute"
        right={isNote ? "92px" : "0"}
        top={isNote ? "-3px" : "0"}
        py={pxToRem(10)}
        zIndex={1}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(false);
          }}
          sx={{
            padding: "4px",
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
              "& svg": { color: "#333" },
            },
          }}
        >
          <Close width={18} height={18} />
        </IconButton>
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={handleDelete}
          sx={{
            backgroundColor: "rgb(211, 47, 47)",
            color: "#fff",
            fontSize: "12px",
            padding: "5px 10px",
            "&:hover": {
              backgroundColor: "rgb(162, 11, 11)",
            },
          }}
        >
          Delete?
        </Button>
      </Stack>
    </Box>
  );
};

export default DeleteBtn;
