import React from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import ModalHeader from "./ModalHeader";
import { pxToRem } from "px2rem2px";
import FormInputText from "./FormInputText";
import { useForm } from "react-hook-form";
import { Stack, styled } from "@mui/system";

const AddNoteDialog = ({
  open,
  onClose,
  onSave,
  initialTitle = "",
  initialContent = "",
}) => {
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      title: initialTitle,
      content: initialContent,
    },
  });

  const onSubmit = (data) => {
    onSave(data.title, data.content);
    reset({
      title: initialTitle,
      content: initialContent,
    });
  };


  const handleClose = () => {
    reset({
      title: initialTitle,
      content: initialContent,
    });
    onClose();
  };

  const ButtonSubmit = styled(Button)({
    width: pxToRem(300),
    height: `${pxToRem(54)} !important`,
    fontSize: pxToRem(14),
    fontWeight: 600,
    boxShadow: "none",
    margin: `${pxToRem(40)} 0`,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose} 
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "15px",
          margin: { xs: "20px !important", ssm: `${pxToRem(32)} !important` },
          maxWidth: { xs: "100%", sm: "500px" },
          width: { xs: "100%", sm: "500px" },
        },
      }}
    >
      <ModalHeader title="Add Note" inModal={true} onClose={handleClose} /> 
      <DialogContent
        sx={{
          minHeight: "240px",
          backgroundColor: "#f7f6f2",
          p: { xs: pxToRem(30), ssm: pxToRem(50) },
        }}
      >
        <Stack display="flex" flexDirection="column" gap={pxToRem(40)}>
          <FormInputText
            name="title"
            placeholder="Note Title"
            control={control}
            setValue={setValue}
            sx={{
              flexGrow: "1",
              width: { xs: "100%", ssm: "auto" },
              "& input": {
                "&:focus": {
                  outline: "none !important",
                },
              },
            }}
            rules={{
              required: "Field can't be empty",
              maxLength: {
                value: 40,
                message: "Maximum 40 characters",
              },
            }}
            muiProps={{
              type: "text",
            }}
          />

          <FormInputText
            name="content"
            control={control}
            setValue={setValue}
            placeholder="Note Content"
            sx={{
              flexGrow: "1",
              width: { xs: "100%", ssm: "auto" },
              "& .MuiInputBase-root": {
                height: "auto",
                borderRadius: pxToRem(10),
                padding: 0,
              },
              "& textarea": {
                "&:focus": {
                  outline: "none !important",
                },
              },
            }}
            rules={{
              required: "Field can't be empty",
              maxLength: {
                value: 500,
                message: "Maximum 500 characters",
              },
            }}
            muiProps={{
              type: "text",
              multiline: true,
              rows: 7,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#f7f6f2" ,display:"flex",justifyContent:"center",alignItems:"center"}}>
        <ButtonSubmit
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="yellow"
        >
          Save Note
        </ButtonSubmit>
      </DialogActions>
    </Dialog>
  );
};

export default AddNoteDialog;
