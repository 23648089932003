import {
  Box,
  MenuItem,
  Stack,
  Typography,
  Button,
  Collapse,
} from "@mui/material";
import { useModal } from "mui-modal-provider";
import { pxToRem } from "px2rem2px";
import Rating from "./Rating";
import BlockBordered from "./BlockBordered";
import TextLink from "./TextLink";
import TestimonialMenu from "./TestimonialMenu";
import CommentModal from "./commentModal/ReviewModal";
import Likes from "./Likes";
import { formatDistanceToNow } from "date-fns";
import Avatar from "../images/avatar.png";
import ReviewModal from "./commentModal/ReviewModal";
import { useEffect, useState } from "react";
import { postLike } from "../api/course";
import confetti from "canvas-confetti";
import { useParams } from "react-router-dom";
import { getLanguageClass } from "../utils/languageClass";
import { postReport } from "../api/course";
import Toast from "./Toast";
import { ReactComponent as Pin } from "../images/pin.svg";
import { ReactComponent as Chevron } from "../images/chevron.svg";
import { ReactComponent as LikeFilled } from "../images/like-filled.svg";
import Reply from "./Reply";
const Testimonial = ({
  rating,
  course,
  updateRatings,
  allReviewsModal = false,
  ...props
}) => {
  const { id } = useParams();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isShowMore, setIsShowMore] = useState(false);
  const [ratingsDialogOpen, setRatingsDialogOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(rating?.is_liked);
  const [likes, setLikes] = useState(rating?.likes);
  const { showModal } = useModal();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const editTime = formatDistanceToNow(new Date(rating?.updated_at)).replace(
    "about ",
    ""
  );
  const isEdited = rating?.updated_at !== rating?.created_at;
  const status = isEdited ? "Edited" : "Posted";
  const displayTime = `${status} ${editTime} ago`;

  const handleCopyLink = () => {
    /*  setSnackbarMessage(""); */
    navigator.clipboard.writeText(
      `https://rym.webaryco.com/course/${id}?rating=${rating.id}`
    );
    setToastOpen(true);
    setToastMessage("Link copied successfully.");
  };

  const handleLikeClick = (e) => {
    const button = e.currentTarget;
    const rect = button.getBoundingClientRect();
    const confettiX = rect.left + rect.width / 2;
    const confettiY = rect.top + rect.height / 2;
    postLike(rating.id)
      .then((res) => {
        setIsLiked(!isLiked);
        if (isLiked) {
          setLikes(likes - 1);
        } else if (!isLiked) {
          setLikes(likes + 1);
          confetti({
            particleCount: 20,
            spread: 5,
            origin: {
              x: confettiX / window.innerWidth,
              y: confettiY / window.innerHeight,
            },
            zIndex: 9999,
          });
        }
      })
      .catch((err) => {
        console.error("Error during postLike operation:", err);
      });
  };
  const handleReportClick = () => {
    postReport(rating.id)
      .then((res) => {
        setToastOpen(true);
        setToastMessage("Review reported successfully.");
      })
      .catch((err) => {
        console.error("Error during postReport operation:", err);
        setToastOpen(true);
        setToastMessage("An error occurred during report.");
      });
  };

  // console.log("rating?.is_mine",rating?.is_mine)

  return (
    <Box
      bgcolor="#fff"
      borderRadius={pxToRem(10)}
      p={{
        xs: `${pxToRem(30)} ${pxToRem(20)}`,
        ssm: pxToRem(40),
        md: pxToRem(30),
        lg: pxToRem(40),
      }}
      onClick={() => props.modal && showModal(CommentModal)}
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative", // Required to position child elements absolutely
      }}
    >
      <Stack
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={pxToRem(10)}
        mb={pxToRem(20)}
      >
        <Stack flexDirection="row" alignItems="center" columnGap={pxToRem(21)}>
          <Box
            className="avatar-container"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={{ xs: pxToRem(50), ssm: pxToRem(68) }}
            height={{ xs: pxToRem(50), ssm: pxToRem(68) }}
            borderRadius="100%"
            bgcolor="#EDECE8"
            flexShrink="0"
          >
            {props.avatar ? (
              <img
                src={rating?.student?.avatar}
                style={{
                  borderRadius: "100%",
                }}
                alt=""
              />
            ) : (
              <Typography fontSize={pxToRem(20)} fontWeight={600} color="#fff">
                MK
              </Typography>
            )}
          </Box>
          <Stack>
            <Typography
              component="span"
              fontSize={pxToRem(20)}
              fontWeight={600}
              lineHeight={pxToRem(30)}
              color="secondary"
            >
              {rating && rating.is_anonymous
                ? "Anonymous"
                : rating?.student?.name?.length > 16
                ? `${rating.student.name.slice(0, 13)}...`
                : rating?.student?.name}
            </Typography>
            {rating && rating?.created_at && (
              <Box py={`${pxToRem(4)} 0 ${pxToRem(7)}`}>
                <Rating value={rating.rating} readOnly />
              </Box>
            )}
            <Typography
              component="span"
              fontSize={pxToRem(12)}
              fontWeight={600}
              color="#BFBEBB"
            >
              {displayTime}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
          {allReviewsModal && rating?.is_pinned && (
            <Stack
              flexDirection="row"
              alignItems="center"
              justifySelf={"flex-end"}
              columnGap={pxToRem(5)}
              bgcolor="#026670"
              borderRadius={pxToRem(10)}
              p={`1px ${pxToRem(12)}`}
            >
              <Pin />
              <Typography fontSize={pxToRem(12)} fontWeight={600} color="#fff">
                Pinned
              </Typography>
            </Stack>
          )}
          <TestimonialMenu>
            {rating?.is_mine ? (
              <MenuItem
                onClick={() => {
                  setRatingsDialogOpen(true);
                  console.log("test");
                }}
              >
                Edit
              </MenuItem>
            ) : null}

            <MenuItem onClick={handleCopyLink}>Copy post link</MenuItem>
            {!rating?.is_mine && (
              <MenuItem
                onClick={() => {
                  handleReportClick();
                }}
              >
                Report
              </MenuItem>
            )}
          </TestimonialMenu>
        </Box>
      </Stack>
      <Box>
        {rating && rating?.description && (
          <BlockBordered
            mb={pxToRem(17)}
            isAlignedRight={
              getLanguageClass(rating?.description) === "is-hebrew-text"
            }
          >
            <Box
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: isShowMore ? "unset" : 5, // Line clamp when not expanded
              }}
            >
              <Typography
                variant="medium"
                component="p"
                lineHeight={pxToRem(22)}
                className={getLanguageClass(rating?.description)}
              >
                {rating?.description}
              </Typography>
            </Box>
            {rating?.description && rating?.description?.length > 180 && (
              <TextLink
                sx={{
                  "& span": { fontSize: pxToRem(14), marginTop: pxToRem(2) },
                }}
                onClick={() => {
                  setIsShowMore(!isShowMore);
                }}
              >
                {isShowMore ? "Show Less" : "Read More"}
              </TextLink>
            )}
          </BlockBordered>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: allReviewsModal ? "space-between" : "end",
          //pl: "10px",
          alignItems: "center",
          mt: "20px",
          position: allReviewsModal ? "" : "relative",
          height: "100%",
        }}
      >
        {allReviewsModal && (
          <Stack flexDirection="row" alignItems="center" gap={pxToRem(20)}>
            {rating?.replies.length > 0 && (
              <Button
                onClick={handleExpandClick}
                startIcon={<Chevron color="#fff" />}
                sx={{
                  color: "#fff",
                  backgroundColor: "#026670",
                  borderRadius: pxToRem(20),
                  p: `${pxToRem(1)} ${pxToRem(13)} ${pxToRem(2)} ${pxToRem(
                    15
                  )}`,
                  "& svg": {
                    width: pxToRem(7),
                    transform: expanded ? "rotate(270deg)" : "rotate(90deg)",
                    marginTop: expanded ? 0 : pxToRem(1),
                  },
                  "&:hover": {
                    backgroundColor: "#00545d",
                    color: "#fff",
                  },
                }}
              >
                {rating?.replies.length === 1
                  ? "1 Reply"
                  : `${rating?.replies.length} Replies`}
              </Button>
            )}
          </Stack>
        )}
        {rating && rating?.description && (
          <Box
            sx={
              allReviewsModal
                ? {}
                : { position: "absolute", bottom: 1, right: 1 }
            }
          >
            <Likes
              onClick={(e) => handleLikeClick(e)}
              number={likes}
              isLiked={isLiked}
            />
          </Box>
        )}
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack
          gap={pxToRem(20)}
          pl={{ xs: pxToRem(30), ssm: pxToRem(80) }}
          mt={pxToRem(20)}
          sx={{
            "& > div": { backgroundColor: "#F2F1ED" },
            "& .avatar-container": {
              backgroundColor: "#fff",
              "& p": {
                color: "#BFBEBB",
              },
            },
          }}
        >
          {rating.replies.map((reply, idx) => (
            <Reply key={idx} reply={reply} />
          ))}
        </Stack>
      </Collapse>
      <ReviewModal
        open={ratingsDialogOpen}
        setOpen={setRatingsDialogOpen}
        course={course}
        isEditing={true}
        currentReview={rating}
        updateRatings={updateRatings}
      />
      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </Box>
  );
};

export default Testimonial;
