import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { styled } from "@mui/system";
import Header from "../Header";
import Search from "./Search";
import Guides from "../Guides";
// import BgImg from "../../images/courses-hero-bg.png";
// import BgImgTablet from "../../images/courses-hero-bg-tablet.svg";
import TreeImg from "../../images/footer-tree.svg";
import manWhite from "../../images/man-white.png";
import GradientAnimation from "../GradientAnimation";

const PageTitleHighlighted = styled(Typography)(({ theme }) => {
  return {
    position: "relative",
    fontFamily: "PloniBold",
    fontSize: pxToRem(70),
    lineHeight: pxToRem(65),
    "&::after": {
      content: "''",
      width: "104%",
      height: pxToRem(20),
      position: "absolute",
      left: "50%",
      bottom: pxToRem(12),
      transform: "translateX(-50%)",
      backgroundColor: "rgba(252,225,129,0.5)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
      lineHeight: "50px",
      "&::after": {
        bottom: "6px",
      },
    },
  };
});

const Hero = ({
  title,
  onSearch,
  courses,
  setCourses,
  setIsOnSearchMode,
  setHasMoreActive,
  setFilterMode,
  isOnSearchMode,
  setPage,
  sx,
}) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("(min-width:950px)"));
  const isLargeScreen = useMediaQuery("(min-width:1227px)");

  return (
    <Box
      position="relative"
      pt={pxToRem(30)}
      pb={pxToRem(40)}
      minHeight="540px"
      mb={pxToRem(100)}
      sx={{
        borderRadius: { xs: 0, md: pxToRem(40) },
        "&::before": {
          content: "''",
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-125%)",
          zIndex: 2,
          background: `url(${TreeImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          width: pxToRem(520),
          height: pxToRem(600),
          opacity: 0.4,
          "@media(max-width: 1300px)": {
            left: "30px",
            transform: "none",
          },
          "@media(max-width: 899px)": {
            left: "50%",
            transform: "translateX(-50%)",
          },
        },
        // borderRadius: { xs: 0, md: pxToRem(40) },
        borderRadius: tablet ? pxToRem(40) : 0,
        zIndex: 2,
        background: `url(${TreeImg})`,
        ...sx,
      }}
    >
      <GradientAnimation />
      <Header menuVisible />

      {isLargeScreen && (
        <Box
          sx={{
            position: "absolute",
            bottom: "0%",
            right: "-6%",

            height: "80%",
            width: "580px",

            overflow: "hidden",
            borderRadius: "inherit",
            "@media (max-width: 1300px)": {
              width: "500px",
            },
            "@media (max-width: 1226px)": {
              width: "350px",
              height: "60%",
            },
          }}
        >
          <img
            src={manWhite}
            alt="man"
            style={{
              marginTop: "20px",
              width: "100%",
              height: "auto",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Box>
      )}
      <Stack
        className="header-padding"
        position="relative"
        maxWidth="683px"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        zIndex={2}
        margin={{ xs: "20px auto 0", md: "64px auto 0" }}
        padding="0 25px"
      >
        <Typography
          variant="sectionTitle"
          fontSize={`${pxToRem(45)} !important`}
          component="h2"
          mb={{ xs: "30px", ssm: "10px" }}
        >
          {title}
        </Typography>
        <Typography
          component="h1"
          fontFamily="PloniBold"
          fontSize={{ xs: pxToRem(50), sm: pxToRem(70) }}
          lineHeight={{ xs: "50px", sm: pxToRem(60) }}
          mb={isLargeScreen ? pxToRem(100) : "0"}
        >
          דא ווערן{" "}
          <PageTitleHighlighted component="span">
            דורכגערעדט
          </PageTitleHighlighted>{" "}
          די וויכטיגסטע יסודות אין לעב
        </Typography>

        <Box
          sx={{
            position: "relative",
            pb: { xs: "225px", ssm: "255px", md: 0, width: "100%" },
          }}
        >
          {isLargeScreen && (
            <Search
              onSearch={onSearch}
              courses={courses}
              setCourses={setCourses}
              setIsOnSearchMode={setIsOnSearchMode}
              isOnSearchMode={isOnSearchMode}
              setPage={setPage}
              setHasMoreActive={setHasMoreActive}
              setFilterMode={setFilterMode}
            />
          )}

          {!isLargeScreen && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                zIndex: -1,
                width: { xs: "420px", ssm: "450px" },

                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <img src={manWhite} alt="man" />
            </Box>
          )}
        </Box>
      </Stack>
      <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
    </Box>
  );
};

export default Hero;
