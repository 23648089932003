import React, { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { ReactComponent as Reverse } from "../../images/reverse.svg";
import { ReactComponent as Forward } from "../../images/forward.svg";
import { Box } from "@mui/system";

const DrivingModeSettings = ({
  drivingModeSpeed,
  setDrivingModeSpeed,
  seekHandler,
}) => {
  const [isSpeedListVisible, setSpeedListVisible] = useState(false);
  const selectorRef = useRef(null);

  const speeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  const toggleSpeedList = () => {
    setSpeedListVisible((prev) => !prev);
  };

  const handleSpeedSelect = (speed) => {
    setDrivingModeSpeed(speed);
    setSpeedListVisible(false);
  };

  const handleClickOutside = (event) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target)) {
      setSpeedListVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={selectorRef}
      sx={{
        position: "relative",
        display: "inline-block",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          textAlign: "center",
          justifyContent: "space-between",
          alignItems: "center",
          minWidth: "230px",
        }}
      >
        <Box
          onClick={() => seekHandler(-10)}
          sx={{
            cursor: "pointer",
            position: "relative",
            display: "inline-block",
          }}
        >
          <Reverse />
          <Typography
            fontSize="12px"
            color="secondary"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              pointerEvents: "none",
              fontSize: "20px",
              fontWeight: 600,
              marginLeft: "5px",
            }}
          >
            10
          </Typography>
        </Box>

        <Button
          onClick={toggleSpeedList}
          sx={{
            cursor: "pointer",
            color:"#BFBEBB",

          }}
        >
          {`${drivingModeSpeed}x`}
        </Button>

        <Box
          onClick={() => seekHandler(10)}
          sx={{
            cursor: "pointer",
            position: "relative",
            display: "inline-block",
          }}
        >
          <Forward />
          <Typography
            fontSize="12px"
            color="secondary"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              pointerEvents: "none",
              fontSize: "20px",
              fontWeight: 600,
              marginRight: "5px",
            }}
          >
            10
          </Typography>
        </Box>
      </Box>
      {isSpeedListVisible && (
        <ul
          style={{
            position: "absolute",
            bottom: "90%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            listStyle: "none",
            margin: 0,
            padding: "5px",
            border: "1px solid #026670",
            borderRadius: "4px",
            backgroundColor: "#fff",
            zIndex: 10000,
            minWidth: "100px",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          {speeds.map((speed) => (
            <li
              key={speed}
              onClick={() => handleSpeedSelect(speed)}
              style={{
                padding: "8px",
                cursor: "pointer",
                backgroundColor:
                  drivingModeSpeed === speed ? "#f0f0f0" : "transparent",
                color: drivingModeSpeed === speed ? "#026670" : "#333333",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#f0f0f0";
                e.target.style.color = "#026670";
              }}
              onMouseLeave={(e) => {
                if (drivingModeSpeed !== speed) {
                  e.target.style.backgroundColor = "transparent";
                  e.target.style.color = "#333333";
                }
              }}
            >
              {`${speed}x`}
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
};

export default DrivingModeSettings;
