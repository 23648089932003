import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import InputGroup from "./InputGroup";

const PersonalDetails = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderBottom: "1px solid rgba(191,190,187,0.5)",
        padding: { xs: "32px 0", sm: "60px 0 80px" },
      }}
    >
      <Grid item xs={12} md={3}>
        <Typography
          variant="h6"
          gutterBottom
          fontSize="24px"
          lineHeight="30px"
          fontWeight="700"
        >
          Personal Details
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <Stack gap="20px">
          <InputGroup
            label="Full Name"
            value="My Name"
            type="text"
            rules={{
              minLength: {
                value: 3,
                message: "Minimum 3 characters",
              },
              maxLength: {
                value: 50,
                message: "Maximum 50 characters",
              },
            }}
          />
          <InputGroup
            phone
            label="Phone (optional)"
            phoneNumber="+1 718-322-2444"
          />
          <InputGroup
            label="Your Email Address"
            value="hello@drawingart.co"
            type="email"
            rules={{
              required: "Field can't be empty",
              maxLength: {
                value: 100,
                message: "Maximum 100 characters",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
          />
          <InputGroup
            label="Comment & Dedication (optional)"
            value=""
            type="text"
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
