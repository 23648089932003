import { Box, Button, Stack, Typography } from "@mui/material";
import ModalLayout from "./ModalLayout";

import { ReactComponent as Close } from "../../images/cross-white.svg";
import { pxToRem } from "px2rem2px";

const ErrorModal = (props) => {
  const { openPurchaseModal, ...restProps } = props;

  return (
    <ModalLayout {...restProps} headerTitle="וואס טוט מען ווען דער מאן">
      <Box textAlign="center">
        <Stack
          position="absolute"
          top="-27px"
          left="50%"
          alignItems="center"
          justifyContent="center"
          bgcolor="#C92727"
          width={pxToRem(54)}
          height={pxToRem(54)}
          borderRadius="100%"
          sx={{ transform: "translateX(-50%)" }}
        >
          <Close />
        </Stack>
        <Typography component="p" fontSize={pxToRem(20)} fontWeight={700} sx={{ marginTop: pxToRem(10) }}>
          Ooops!
          <br /> That didn’t work!
        </Typography>
        <Typography variant="medium" component="p" fontSize={pxToRem(16)} mt={pxToRem(20)}>
          Transaction declined, please try a different payment method.
        </Typography>
        <Button
          onClick={openPurchaseModal}
          variant="yellow"
          sx={{ width: {xs: "100%", ssm: "300px"}, maxWidth: {xs: "300px", ssm: "none"}, mt: pxToRem(40), height: pxToRem(54) }}
        >
          Try Again
        </Button>
      </Box>
    </ModalLayout>
  );
};

export default ErrorModal;
