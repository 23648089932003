import { Box, Typography, Container, Grid, Stack, Button } from "@mui/material";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import TreeImg from "../../images/footer-tree.svg";
import GradientAnimation from "../../components/GradientAnimation";
import { useEffect } from "react";
import BlogImg from "../../images/slide-blog3.jpg";
import BlockBordered from "../../components/BlockBordered";
import PresentationImg from "../../images/presentation-img.jpg";
import PhilosophyImg from "../../images/philosophy-img.png";
import { styled } from "@mui/system";
import TextLink from "../../components/TextLink";

const Label = styled(Typography)(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    height: "20px",
    fontSize: "12px",
    fontWeight: 600,
    color: "#026670",
    backgroundColor: "#FFFFFF",
    // border: "2px solid #f5e093",
    borderRadius: "calc(infinity * 1px)",
    padding: "0 15px",
  };
});
const Blogs = () => {
  const blogItems = Array(6).fill({});

  useEffect(() => {
    document.title = "Blogs";
  }, []);
  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      <Box
        position="relative"
        pt={pxToRem(30)}
        pb={pxToRem(40)}
        minHeight={pxToRem(540)}
        sx={{
          display: "flex",
          flexDirection: "column",
          // background: "linear-gradient(45deg, #026670, #fce181, #026670, #fce181)",
          // backgroundSize: "600% 600%",
          // animation: `${gradientAnimation} 30s ease infinite`,
          borderRadius: { xs: 0, md: pxToRem(40) },
          "&::before": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-125%)",
            zIndex: 2,
            background: `url(${TreeImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            width: pxToRem(520),
            height: pxToRem(702),
            opacity: 0.4,
            "@media(max-width: 1300px)": {
              left: "30px",
              transform: "none",
            },
            "@media(max-width: 899px)": {
              left: "50%",
              transform: "translateX(-50%)",
            },
          },
        }}
      >
        <GradientAnimation />
        <Header menuVisible />

        <Box
          className="header-padding"
          sx={{
            flexGrow: 1,
            zIndex: 2,
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            component="h1"
            fontWeight="700"
            textAlign="center"
            px={pxToRem(30)}
            fontSize={{ xs: pxToRem(50), sm: pxToRem(60), md: pxToRem(70) }}
            mb={{ xs: "27px", md: 0 }}
          >
            Blogs & News
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontSize: pxToRem(25),
              letterSpacing: "-0.5px",
              lineHeight: pxToRem(32),
              fontWeight: 700,
              opacity: "75%"
            }}
          >
            Explore the latest blogs and news from Reb Yakov Moshe
          </Typography>
        </Box>
        <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
      </Box>
      <Container
        sx={{ px: pxToRem(30), py: { xs: pxToRem(77), md: pxToRem(120) } }}
        disableGutters
      >
        <Grid container spacing={pxToRem(80)} columns={11}>
          <Grid item xs={7} mt={{ xs: "25px", sm: "30px", md: pxToRem(40) }}>
            <Box
              position="relative"
              // maxWidth={{ xs: "300px", sm: "310px", md: pxToRem(500) }}
              width="100%"
              // height={{ xs: "310px", md: "auto" }}
              display="flex"
              borderRadius={pxToRem(20)}
              boxShadow={`${pxToRem(30)} ${pxToRem(30)} ${pxToRem(
                50
              )} rgba(0,0,0,0.2)`}
              flexShrink={0}
              sx={{
                "&::before": {
                  content: "''",
                  position: "absolute",
                  left: { xs: "-25px", sm: "-30px", md: pxToRem(-40) },
                  top: { xs: "-30px", md: pxToRem(-40) },
                  width: { xs: "200px", sm: "310px", md: "88%" },
                  height: { xs: "310px", md: "74.1%" },
                  bgcolor: "#FCE181",
                  borderRadius: pxToRem(20),
                  zIndex: -1,
                },
              }}
            >
              <img
                src={PresentationImg}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: pxToRem(20),
                }}
              />
            </Box>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mt={pxToRem(40)}
            >
              <Typography
                component="p"
                fontSize={pxToRem(16)}
                lineHeight={pxToRem(25)}
                fontWeight={600}
                color="#BFBEBB"
              >
                Reb Yakov Moshe • 11/24/2024
              </Typography>
              <Label sx={{height: "24px", border: "2px solid #f5e093", backgroundColor: "#F9EBB9", fontSize: pxToRem(14)}}>
                Update
              </Label>
            </Stack>
            <Typography
              className="slideTitle"
              fontSize={pxToRem(25)}
              fontWeight={700}
              lineHeight={pxToRem(35)}
              color="secondary.main"
              mb={pxToRem(18)}
              mt={pxToRem(19)}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "4",
                WebkitBoxOrient: "vertical",
              }}
            >
              The Power of Consistency in Learning: How Small Daily Actions Lead
              to Big Changes Over Time
            </Typography>
            <BlockBordered>
              <Typography
                className="slideTitle"
                fontSize={pxToRem(18)}
                fontWeight={500}
                lineHeight={pxToRem(30)}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "4",
                  WebkitBoxOrient: "vertical",
                }}
              >
                In a world full of distractions and quick fixes, consistency is
                the true key to achieving lasting knowledge. Whether you are
                learning a new language, mastering a musical instrument, or
                deepening your understanding of Torah, steady progress over time
                always trumps sporadic bursts of enthusiasm.
              </Typography>
            </BlockBordered>
            <TextLink
              href=""
              // onClick={handleReadMoreClick}
              sx={{
                "& span": { fontSize: pxToRem(16) },
                marginTop: pxToRem(35),
              }}
              // showFullDescription={showFullDescription}
            >
              Read More
            </TextLink>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                marginBottom: pxToRem(20),
                color: "#333333",
                fontWeight: 600,
                fontSize: pxToRem(25),
                lineHeight: pxToRem(32),
              }}
            >
              Top Posts
            </Typography>
            <Stack sx={{ rowGap: pxToRem(12) }}>
              <Stack
                flexDirection="row"
                sx={{
                  gap: pxToRem(15),
                  borderRadius: pxToRem(20),
                  backgroundColor: "#EDECE8",
                  overflow: "hidden",
                }}
              >
                <Box width={pxToRem(100)} sx={{ flexShrink: "0" }}>
                  <img
                    src={PhilosophyImg}
                    alt="Post"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    padding: `${pxToRem(14)} ${pxToRem(36)} ${pxToRem(
                      14
                    )} ${pxToRem(15)}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(16),
                      lineHeight: pxToRem(20),
                      color: "#333333",
                      fontWeight: "600",
                      marginBottom: pxToRem(10),
                    }}
                  >
                    The Power of Consistency in Learning: How Small Daily
                    Actions Lead to Big Changes Over Time
                  </Typography>
                  <Typography
                    component="p"
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                    color="#BFBEBB"
                  >
                    Reb Yakov Moshe • 11/24/2024
                  </Typography>
                </Box>
              </Stack>
              <Stack
                flexDirection="row"
                sx={{
                  gap: pxToRem(15),
                  borderRadius: pxToRem(20),
                  backgroundColor: "#EDECE8",
                  overflow: "hidden",
                }}
              >
                <Box width={pxToRem(100)} sx={{ flexShrink: "0" }}>
                  <img
                    src={PhilosophyImg}
                    alt="Post"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    padding: `${pxToRem(14)} ${pxToRem(36)} ${pxToRem(
                      14
                    )} ${pxToRem(15)}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(16),
                      lineHeight: pxToRem(20),
                      color: "#333333",
                      fontWeight: "600",
                      marginBottom: pxToRem(10),
                    }}
                  >
                    The Power of Consistency in Learning: How Small Daily
                    Actions Lead to Big Changes Over Time
                  </Typography>
                  <Typography
                    component="p"
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                    color="#BFBEBB"
                  >
                    Reb Yakov Moshe • 11/24/2024
                  </Typography>
                </Box>
              </Stack>
              <Stack
                flexDirection="row"
                sx={{
                  gap: pxToRem(15),
                  borderRadius: pxToRem(20),
                  backgroundColor: "#EDECE8",
                  overflow: "hidden",
                }}
              >
                <Box width={pxToRem(100)} sx={{ flexShrink: "0" }}>
                  <img
                    src={PhilosophyImg}
                    alt="Post"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    padding: `${pxToRem(14)} ${pxToRem(36)} ${pxToRem(
                      14
                    )} ${pxToRem(15)}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(16),
                      lineHeight: pxToRem(20),
                      color: "#333333",
                      fontWeight: "600",
                      marginBottom: pxToRem(10),
                    }}
                  >
                    The Power of Consistency in Learning: How Small Daily
                    Actions Lead to Big Changes Over Time
                  </Typography>
                  <Typography
                    component="p"
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                    color="#BFBEBB"
                  >
                    Reb Yakov Moshe • 11/24/2024
                  </Typography>
                </Box>
              </Stack>
              <Stack
                flexDirection="row"
                sx={{
                  gap: pxToRem(15),
                  borderRadius: pxToRem(20),
                  backgroundColor: "#EDECE8",
                  overflow: "hidden",
                }}
              >
                <Box width={pxToRem(100)} sx={{ flexShrink: "0" }}>
                  <img
                    src={PhilosophyImg}
                    alt="Post"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    padding: `${pxToRem(14)} ${pxToRem(36)} ${pxToRem(
                      14
                    )} ${pxToRem(15)}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(16),
                      lineHeight: pxToRem(20),
                      color: "#333333",
                      fontWeight: "600",
                      marginBottom: pxToRem(10),
                    }}
                  >
                    The Power of Consistency in Learning: How Small Daily
                    Actions Lead to Big Changes Over Time
                  </Typography>
                  <Typography
                    component="p"
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                    color="#BFBEBB"
                  >
                    Reb Yakov Moshe • 11/24/2024
                  </Typography>
                </Box>
              </Stack>
              <Stack
                flexDirection="row"
                sx={{
                  gap: pxToRem(15),
                  borderRadius: pxToRem(20),
                  backgroundColor: "#EDECE8",
                  overflow: "hidden",
                }}
              >
                <Box width={pxToRem(100)} sx={{ flexShrink: "0" }}>
                  <img
                    src={PhilosophyImg}
                    alt="Post"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    padding: `${pxToRem(14)} ${pxToRem(36)} ${pxToRem(
                      14
                    )} ${pxToRem(15)}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: pxToRem(16),
                      lineHeight: pxToRem(20),
                      color: "#333333",
                      fontWeight: "600",
                      marginBottom: pxToRem(10),
                    }}
                  >
                    The Power of Consistency in Learning: How Small Daily
                    Actions Lead to Big Changes Over Time
                  </Typography>
                  <Typography
                    component="p"
                    fontSize={pxToRem(14)}
                    fontWeight={600}
                    color="#BFBEBB"
                  >
                    Reb Yakov Moshe • 11/24/2024
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={pxToRem(20)}
          rowSpacing={pxToRem(40)}
          sx={{ marginTop: pxToRem(139) }}
        >
          {blogItems.map((blogItem, index) => (
            <Grid item xs={4} key={index}>
              <Box
                position="relative"
                bgcolor="#fff"
                borderRadius={pxToRem(20)}
                sx={{
                  transition: "0.2s ease",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  "&::before": {
                    content: "''",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: `calc(100% - ${pxToRem(40)})`,
                    height: "100%",
                    backgroundColor: "#FCE181",
                    borderRadius: pxToRem(20),
                    transform: "translate(-50%,-50%)",
                    zIndex: -1,
                    transition: "0.2s ease",
                  },
                  "&:hover": {
                    boxShadow: {
                      xs: "none",
                      sm: `0px ${pxToRem(20)} ${pxToRem(24)} rgba(0,0,0,0.1)`,
                    },
                    "&::before": {
                      height: `calc(100% + ${pxToRem(40)})`,
                    },
                    "& .slideTitle": {
                      color: "primary.main",
                    },
                    ".duration": {
                      opacity: 1,
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    height: pxToRem(210),
                    borderTopLeftRadius: pxToRem(20),
                    borderTopRightRadius: pxToRem(20),
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                    src={BlogImg}
                    alt="Blog"
                  />
                </Box>
                <Box
                  sx={{
                    padding: `${pxToRem(40)} ${pxToRem(38)} ${pxToRem(
                      40
                    )} ${pxToRem(40)}`,
                    position: "relative",
                  }}
                >
                  <Stack
                    width={{
                      xs: "calc(100% - 40px)",
                      ssm: "90%",
                      sm: "calc(100% - 60px)",
                      md: "90%",
                      lg: "calc(100% - 60px)",
                    }}
                    flexDirection="row"
                    alignContent="center"
                    justifyContent="flex-end"
                    overflow="hidden"
                    flexWrap="nowrap"
                    gap="8px"
                    position="absolute"
                    top={pxToRem(-45)}
                    right={pxToRem(40)}
                  >
                    <Label>New Course</Label>
                  </Stack>
                  <Typography
                    component="p"
                    fontSize={pxToRem(14)}
                    marginBottom={pxToRem(10)}
                    fontWeight={600}
                    color="#BFBEBB"
                  >
                    Reb Yakov Moshe • 11/24/2024
                  </Typography>
                  <Typography
                    className="slideTitle"
                    fontSize={pxToRem(22)}
                    fontWeight={700}
                    lineHeight={pxToRem(30)}
                    color="secondary.main"
                    mb={pxToRem(20)}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    Ut enim ad minim elit venia quis ullamco sit
                  </Typography>
                  <BlockBordered>
                    <Typography
                      className="slideTitle"
                      fontSize={pxToRem(16)}
                      fontWeight={500}
                      lineHeight={pxToRem(24)}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      In a world full of distractions and quick fixes,
                      consistency is the true key to achieving lasting
                      knowledge. Whether you are...
                    </Typography>
                  </BlockBordered>
                  <TextLink
                    href=""
                    // onClick={handleReadMoreClick}
                    sx={{
                      "& span": { fontSize: pxToRem(16) },
                      marginTop: pxToRem(20),
                    }}
                    // showFullDescription={showFullDescription}
                  >
                    Read More
                  </TextLink>
                </Box>
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} alignItems="center">
            <Stack alignItems="center" justifyContent="center">
              <Button variant="green">Load More Posts</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Blogs;
