import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { pxToRem } from "px2rem2px";
import { Box, Stack, Typography } from "@mui/material";
import FormInputPhone from "../../components/FormInputPhone";
import FormInputText from "../../components/FormInputText";

const InputGroup = (props) => {
  const inputStyles = {
    "& .MuiInputBase-root": {
      borderRadius: pxToRem(30),
      height: pxToRem(54),
      backgroundColor: "#EDECE8",
      "&.Mui-error": {
        outlineColor: "#d32f2f",
      },
    },
    "& input": {
      borderRadius: `${pxToRem(30)} !important`,
      padding: `${pxToRem(15)} ${pxToRem(40)}`,
      color: "#333",
      fontWeight: 600,
      "&.Mui-disabled": {
        color: "#BFBEBB",
      },
      "&:focus": {
        backgroundColor: "#fff",
      },
    },
  };

  const [temPhoneNumber, setTempPhoneNumber] = useState(
    props?.phoneNumber || ""
  );

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    values: {
      value: props.value || "",
    },
  });

  return (
    <Stack
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={{ xs: "flex-start", md: "center" }}
      gap={{ xs: "10px", md: "30px" }}
    >
      <Typography width={{ xs: "auto", md: "200px" }} variant="medium">
        {props.label}
      </Typography>
      <Box width="100%">
        {props?.phone ? (
          <FormInputPhone
            placeholder="Phone"
            rules={{
              required: "Field can't be empty",
            }}
            value={temPhoneNumber}
            onChange={(data) => {
              // props.setPhoneNumber(data.phone);
              setTempPhoneNumber(data.phone);
              clearErrors("phoneNumber");
            }}
            muiProps={{
              sx: inputStyles,
            }}
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber ? errors?.phoneNumber?.message : ""}
          />
        ) : (
          <FormInputText
            name="value"
            control={control}
            setValue={setValue}
            rules={props.rules}
            muiProps={{
              type: props.type,
              sx: inputStyles,
            }}
            error={!!errors?.value}
            helperText={errors?.value ? errors?.value?.message : ""}
          />
        )}
      </Box>
    </Stack>
  );
};

export default InputGroup;
