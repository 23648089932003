import {
  Box,
  Container,
  Divider,
  Stack,
  Typography,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import { pxToRem } from "px2rem2px";
import Guides from "./Guides";
import LinkBtn from "./LinkBtn";
import BgImg from "../images/footer-tree.svg";
import { useTheme } from "@emotion/react";
import {
  getFooterSettings,
  getNavigationMenu,
  getGeneralSettings,
} from "../api/cmsApi";

import { useState, useEffect } from "react";
import { url } from "../api/config";

const Footer = () => {
  const [footerSettings, setFooterSettings] = useState({
    email: "",
    phone: "",
    links: [],
    description: "",
  });
  const [navigationMenu, setNavigationMenu] = useState([]);
  const [logo, setLogo] = useState(null);

  const fetchFooterSettings = () => {
    getFooterSettings()
      .then((res) => {
        setFooterSettings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchNavigationMenu = () => {
    getNavigationMenu()
      .then((res) => {
        setNavigationMenu(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchLogo = () => {
    getGeneralSettings()
      .then((res) => {
        setLogo(`${url}${res.logo}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchFooterSettings();
    fetchNavigationMenu();
    fetchLogo();
  }, []);

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  return (
    <Box
      position="relative"
      bgcolor="#026670"
      borderRadius={{ xs: 0, md: pxToRem(30) }}
      pt={{ xs: "50px", sm: pxToRem(109) }}
      pb={{ xs: "50px", sm: pxToRem(103) }}
      overflow="hidden"
      sx={{
        "&::after": {
          content: "''",
          position: "absolute",
          // top: 0,
          // top: { xs: 'auto', md: 0 },
          // bottom: { xs: 0, md: 'auto' },
          bottom: 0,
          left: { xs: "50%", md: "78%" },
          // left: "78%",

          transform: mobile ? "translate(-30%, 20%)" : "translate(-50%, 0%)",
          background: `url(${BgImg}) center no-repeat`,
          backgroundSize: "100%",
          width: pxToRem(520),
          height: "100%",
        },
      }}
    >
      <Container disableGutters>
        <Stack position="relative" zIndex="1" sx={{ flexDirection: "row" }}>
          <Box
            maxWidth={pxToRem(380)}
            mr={pxToRem(118)}
            sx={{ "@media(max-width: 767px)": { mr: 0, maxWidth: "100%" } }}
          >
            <Link to="/" style={{ display: "flex" }}>
              {/* <Logo color="#fff" style={{width:"100px",height:"100px", fill:"white"}}/> */}

              {logo && (
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    filter: "brightness(0) invert(1)", // Makes the logo white
                  }}
                />
              )}
            </Link>

            {footerSettings.description ? (
              <Box
                variant="medium"
                color="#fff"
                component="p"
                sx={{
                  fontWeight: 300,
                  fontFamily: "PloniBold",
                }}
                dangerouslySetInnerHTML={{
                  __html: footerSettings.description,
                }}
              />
            ) : (
              " Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut oditaut fugit, sed quia consequuntur magni dolores eos qui ratione."
            )}
            <Divider
              sx={{ backgroundColor: "rgba(255,255,255,0.3)", my: pxToRem(32) }}
            />
            {mobile && (
              <>
                <Stack alignItems={"flex-start"} rowGap={pxToRem(23)}>
                  {navigationMenu &&
                    navigationMenu.map((item, idx) => {
                      if (!item.is_hidden) {
                        return (
                          <LinkBtn
                            url={item.url}
                            newTab={item.target_new_tab}
                            title={item.label}
                            footerLink
                            key={idx * 13}
                          />
                        );
                      }
                      return null; // Ensure the map function always returns something
                    })}
                  {footerSettings &&
                    footerSettings.links.map((item, idx) => (
                      <LinkBtn
                        url={item.url}
                        newTab={item.target_new_tab}
                        key={idx * 17}
                        submenus={item.submenus}
                        title={item.label}
                        footerLink
                      />
                    ))}
                </Stack>
                <Divider
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.3)",
                    my: pxToRem(32),
                  }}
                />
              </>
            )}
            {footerSettings && footerSettings.phone && (
              <Stack flexDirection="row">
                <Typography
                  width={pxToRem(60)}
                  fontSize={pxToRem(16)}
                  fontWeight="500"
                  color="#BFBEBB"
                >
                  Phone:
                </Typography>
                <MuiLink
                  underline="hover"
                  href="tel:2126665433"
                  color="#fff"
                  fontWeight={700}
                >
                  {footerSettings.phone}
                </MuiLink>
              </Stack>
            )}

            {footerSettings && footerSettings.email && (
              <Stack flexDirection="row">
                <Typography
                  width={pxToRem(60)}
                  fontSize={pxToRem(16)}
                  fontWeight="500"
                  color="#BFBEBB"
                >
                  Email:
                </Typography>
                <MuiLink
                  underline="hover"
                  href={`mailto:${footerSettings.email}`}
                  color="#FCE181"
                  fontWeight={700}
                >
                  {footerSettings.email}
                </MuiLink>
              </Stack>
            )}

            <Typography
              fontSize={pxToRem(14)}
              color="rgba(191, 190, 187, 0.5)"
              mt={pxToRem(50)}
            >
              ©copyright {new Date().getFullYear()} RebYakovMoshe.com
            </Typography>
          </Box>

          {!mobile && (
            <Stack alignItems={"flex-start"} rowGap={pxToRem(23)}>
              {navigationMenu &&
                navigationMenu.map((item, idx) => {
                  if (!item.is_hidden) {
                    return (
                      <LinkBtn
                        url={item.url}
                        newTab={item.target_new_tab}
                        title={item.label}
                        footerLink
                        key={idx * 13}
                        submenus={item.submenus}
                      />
                    );
                  }
                  return null; // Ensure the map function always returns something
                })}
              {footerSettings &&
                footerSettings.links.map((item, idx) => (
                  <LinkBtn
                    url={item.link}
                    newTab={item.target_new_tab}
                    title={item.label}
                    footerLink
                    key={idx * 17}
                  />
                ))}
            </Stack>
          )}
        </Stack>
        <Guides color="rgba(0,0,0,0.1)" />
      </Container>
    </Box>
  );
};

export default Footer;
