import { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import BlockBordered from "./BlockBordered";
import { getLanguageClass } from "../utils/languageClass";
import { pxToRem } from "px2rem2px";
import TextLink from "./TextLink";
import { formatDistanceToNow } from "date-fns";
export default function Reply({ reply }) {
  const [isShowMore, setIsShowMore] = useState(false);
  const editTime = formatDistanceToNow(new Date(reply?.updated_at)).replace(
    "about ",
    ""
  );
  const isEdited = reply?.updated_at !== reply?.created_at;
  const status = isEdited ? "Edited" : "Posted";
  const displayTime = `${status} ${editTime} ago`;
  console.log("Reply Component", reply);
  return (
    <Box
      bgcolor="#EDECE8"
      borderRadius="10px"
      p={{
        xs: "30px 20px",
        ssm: "40px",
        md: "30px",
        lg: "40px",
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* Header Section */}
      <Stack
        flexDirection="row"
        alignItems="flex-start"
        justifyContent="space-between"
        gap="10px"
        mb="20px"
      >
        {/* Avatar and User Details */}
        <Stack flexDirection="row" alignItems="center" columnGap="21px">
          <Box
            className="avatar-container"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={{ xs: "50px", ssm: "68px" }}
            height={{ xs: "50px", ssm: "68px" }}
            borderRadius="100%"
            bgcolor="#EDECE8"
            flexShrink="0"
          >
            <Box
              className="avatar-container"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={{ xs: pxToRem(50), ssm: pxToRem(68) }}
              height={{ xs: pxToRem(50), ssm: pxToRem(68) }}
              borderRadius="100%"
              bgcolor="#EDECE8"
              flexShrink="0"
            >
              {reply?.admin.avatar ? (
                <img
                  style={{
                    borderRadius: "200%",
                    width: "100%",
                    height: "100%",
                  }}
                  src={reply?.admin?.avatar}
                  alt=""
                />
              ) : (
                <Typography
                  fontSize={pxToRem(20)}
                  fontWeight={600}
                  color="#fff"
                >
                  {reply?.admin?.name[0]}
                </Typography>
              )}
            </Box>
          </Box>
          <Stack>
            <Typography
              component="span"
              fontSize="20px"
              fontWeight={600}
              lineHeight="30px"
              color="secondary"
            >
              {reply?.admin?.name}
            </Typography>
            <Typography
              component="span"
              fontSize={pxToRem(12)}
              fontWeight={600}
              color="#BFBEBB"
            >
              {displayTime}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Reply Content */}
      <BlockBordered mb={pxToRem(17)}>
        <Box
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: isShowMore ? "unset" : 5, // Line clamp when not expanded
          }}
        >
          <Typography
            variant="medium"
            component="p"
            lineHeight={pxToRem(22)}
            className={getLanguageClass(reply.content)}
          >
            {reply.content}
          </Typography>
        </Box>
        {reply?.content && reply?.content.length > 180 && (
          <TextLink
            sx={{
              "& span": { fontSize: pxToRem(14), marginTop: pxToRem(2) },
            }}
            onClick={() => {
              setIsShowMore(!isShowMore);
            }}
          >
            {isShowMore ? "Show Less" : "Read More"}
          </TextLink>
        )}
      </BlockBordered>
    </Box>
  );
}
