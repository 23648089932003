import { useState } from "react";
import { Box, Rating } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ReactComponent as HeartEmpty } from "../../images/heart-empty.svg";
import { ReactComponent as HeartFilled } from "../../images/heart-filled.svg";
import { pxToRem } from "px2rem2px";

const StyledBox = styled(Box)({
  position: "absolute",
  top: "20px",
  left: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  backgroundColor: "#E8E7E3",
  zIndex: 1,
  "&:has(.MuiRating-root:hover)": {
    backgroundColor: "#B0B0AE",
  },
});

const StyledRating = styled(Rating)({
  marginTop: "3px",
  "& label": {
    zIndex: 1,
    span: {
      transform: "none",
    },
  },
  "& .MuiRating-iconFilled": {
    color: "#FCE181",
  },
  "& .MuiRating-iconHover": {
    color: "#FCE181",
  },
});

const LikeBtn = ({ courseId, isFavorite, onFavoriteClick }) => {
  
  return (
    <StyledBox sx={{ backgroundColor: isFavorite ? "#B0B0AE" : "#E8E7E3", 
      width: {xs: pxToRem(48), sm: "48px"},
      height: {xs: pxToRem(48), sm: "48px"}, }}>
      <StyledRating
        max={1}
        icon={<HeartFilled />}
        emptyIcon={<HeartEmpty />}
        value={isFavorite ? 1 : null}
        onChange={() => onFavoriteClick(courseId)}
      />
    </StyledBox>
  );
};

export default LikeBtn;
