import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { borderRadius } from "@mui/system";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: pxToRem(400),
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius:"15px",
};

const SelectAssociateModal = ({ open, handleClose, associate, handlePurchase,isSubmitting }) => {

  // console.log("associate",associate)
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h5" component="h2" mb={2} sx={{textAlign:"center",fontWeight:600}}>
          Confirm Partner
        </Typography>
        {associate ? (
          <Box >
            <Typography sx={{textAlign:"center"}}>
              Send checkout link to <span style={{color:"#026670",fontWeight:600}}>{associate.user.first_name} {associate.user.last_name}'s</span> email to join this course.
            </Typography>
            <Button
              variant="yellow"
              onClick={handlePurchase}
              sx={{ mt: pxToRem(20), width: "100%" ,fontSize:"16px",letterSpacing:"1px"}}
              disabled={isSubmitting}
            >
              Confirm
            </Button>
          </Box>
        ) : (
          <Typography>No partner found.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default SelectAssociateModal;
