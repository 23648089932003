import { createCheckoutSession, resolveCheckoutSession } from "../api/payment";

export const handleResubscribe = async (
  courseId,
  priceType,
  priceClass,
  prolongBy = ""
) => {
  try {
    const response = await createCheckoutSession(
      courseId,
      priceType,
      priceClass,
      prolongBy
    );
    window.location.href = response.data.checkout_url;
  } catch (error) {
    console.log("Error creating checkout session:", error);
  }
};
export const handleResolve = async (subscriptionId) => {
  try {
    const response = await resolveCheckoutSession(subscriptionId);
    window.location.href = response.data.checkout_url;
  } catch (error) {
    console.log("Error creating checkout session:", error);
  }
};
