const tagCloudTemplate = [
  { style: { fontSize: 15, opacity: "0.5" } },
  { style: { fontSize: 15, opacity: "0.25" } },
  { style: { fontSize: 26 } },
  { style: { fontSize: 25, opacity: "0.75" } },
  { style: { fontSize: 35 } },
  { style: { fontSize: 40 } },
  { style: { fontSize: 15, opacity: "0.5" } },
  { rotate: 90, style: { fontSize: 25 } },
  { style: { fontSize: 20, opacity: "0.75" } },
  { style: { fontSize: 15, opacity: "0.25" } },
  { style: { fontSize: 50 } },
  { style: { fontSize: 15, opacity: "0.5" } },
  { style: { fontSize: 25, opacity: "0.75" } },
  { style: { fontSize: 50 } },
  { style: { fontSize: 26, opacity: "0.75" } },
  { rotate: 90, style: { fontSize: 50 } },
  { style: { fontSize: 15, opacity: "0.5" } },
  { style: { fontSize: 20, opacity: "0.75" } },
  { style: { fontSize: 20, opacity: "0.75" } },
  { style: { fontSize: 70 } },
  { style: { fontSize: 40 } },
  { style: { fontSize: 50 } },
  { rotate: 90, style: { fontSize: 50 } },
  { style: { fontSize: 15, opacity: "0.5" } },
  { style: { fontSize: 25 } },
  { style: { fontSize: 50 } },
  { style: { fontSize: 25, opacity: "0.75" } },
  { style: { fontSize: 35 } },
  { style: { fontSize: 15, opacity: "0.25" } },
  { style: { fontSize: 20, opacity: "0.75" } },
  { rotate: 90, style: { fontSize: 25, opacity: "0.75" } },
  { style: { fontSize: 15, opacity: "0.5" } },
  { style: { fontSize: 26, opacity: "0.75" } },
  { style: { fontSize: 26, opacity: "0.75" } },
  { style: { fontSize: 40 } },
  { style: { fontSize: 15, opacity: "0.5" } },
  { style: { fontSize: 15, opacity: "0.25" } },
];

export default tagCloudTemplate;
