import React from "react";
import { Box, Skeleton, useMediaQuery } from "@mui/material";
import { pxToRem } from "px2rem2px";
import { useTheme } from "@emotion/react";

const PresubscribeSkeleton = () => {
  const theme = useTheme();
  // const mobile_ssm = useMediaQuery(theme.breakpoints.down("ssm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery("(max-width:767px)");

  return (
    <>
      <Box
        className="player-bgr"
        width="100%"
        position="relative"
        display="flex"
        borderRadius={{ md: pxToRem(23) }}
        overflow="hidden"
        sx={{
          backgroundColor: "transparent",
          paddingBottom: "56.25%",
          "&:hover": { "& .toggle-tooltip": { opacity: 1 } },
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            borderRadius: { md: pxToRem(20) },
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
          }}
        />

        {isMediumScreen && (

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
               <Skeleton
              variant="rectangular"
              width={160}
              height={50}
              sx={{ borderRadius: 10,  }}
            />
          </Box>
        )}

        {!isMediumScreen && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                width: "50%",
                pt: tablet ? 0 : 6.5,
                pr: tablet ? 0 : 6.5,
                pb: tablet ? 0 : 6.5,
                pl: tablet ? 0 : 6.5,
              }}
            >
              {!tablet && (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Skeleton
                    variant="circular"
                    width={pxToRem(50)}
                    height={pxToRem(50)}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={30}
                    height={10}
                    sx={{ borderRadius: 10 }}
                  />
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "absolute",
                  top: "50%",
                  right: tablet ? "15%" : "22%",
                  transform: tablet
                    ? "translate(-15%,-50%)"
                    : "translate(-22%,-50%)",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={pxToRem(100)}
                  height={pxToRem(100)}
                />
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={20}
                  sx={{ borderRadius: 10, mt: 1 }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                width: "50%",
                pt: tablet ? 6 : 8,
                pr: tablet ? 3.5 : 6.5,
                pb: tablet ? 3.5 : 6.5,
                pl: tablet ? 3.5 : 6.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <Skeleton width="90%" height={pxToRem(40)} />
                <Skeleton width="70%" height={pxToRem(28)} sx={{ mb: 1 }} />
                <Skeleton width="45%" height={pxToRem(22)} />
                <Skeleton width="50%" height={pxToRem(27)} sx={{ mb: 3 }} />
                <Skeleton
                  variant="rectangular"
                  width={180}
                  height={45}
                  sx={{ borderRadius: 10, mt: 1 }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  justifyContent: "flex-end",
                }}
              >
                <Skeleton
                  variant="circular"
                  width={pxToRem(50)}
                  height={pxToRem(50)}
                />
                <Skeleton
                  variant="circular"
                  width={pxToRem(50)}
                  height={pxToRem(50)}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>

      {isMediumScreen && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "flex-end",
            mt: 2,
            px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "100%",
              alignItems: "flex-end",
            }}
          >
            <Skeleton width="90%" height={pxToRem(40)} />
            <Skeleton width="70%" height={pxToRem(28)} sx={{ mb: 1 }} />
            <Skeleton width="45%" height={pxToRem(22)} />
            <Skeleton width="50%" height={pxToRem(27)} sx={{ mb: 3 }} />
           
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
             <Skeleton
              variant="rectangular"
              width={180}
              height={45}
              sx={{ borderRadius: 10, mr: 3 }}
            />
            <Skeleton
              variant="circular"
              width={pxToRem(50)}
              height={pxToRem(50)}
            />
            <Skeleton
              variant="circular"
              width={pxToRem(50)}
              height={pxToRem(50)}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PresubscribeSkeleton;
