import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Stack,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { pxToRem } from "px2rem2px";
import BlockHeading from "../../../components/BlockHeading";
import Switcher from "../../../components/Switcher";
import { editPlayback, getPlayback, getUserInfo } from "../../../api/student";
import Toast from "../../../components/Toast";

import { ReactComponent as Chevron } from "../../../images/chevron.svg";

const initialSettings = {
  is_newsletter_subscribed: false,
  playing_mode: "ask",
  default_play_speedd: 1,
  autoplay: false,
  fullscreen_on_rotate: true,
};

const Playback = () => {
  const userInfo = getUserInfo();

  const [settings, setSettings] = useState(initialSettings);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectOpen2, setIsSelectOpen2] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      if (isSubmitting) return;
      try {
        const data = await getPlayback(userInfo.id);
        setSettings(data);
        console.log("data", data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSettings();
  }, [userInfo.id, isSubmitting]);

  const handleChange = async (event) => {
    const { name, value, checked, type } = event.target;
    const updatedSettings = {
      ...settings,
      [name]: type === "checkbox" ? checked : value,
    };
    setSettings(updatedSettings);

    // Trigger the API call to save the settings after each change
    // setIsSubmitting(true);
    try {
      await editPlayback(updatedSettings);
      setToastOpen(true);
      setToastMessage("Settings Updated.");
    } catch (error) {
      console.log(error);
      setToastMessage("Failed to update settings!");
    } finally {
      // setIsSubmitting(false);
    }
  };

  // Reset settings to the original values and update them via the API
  const handleResetSettings = async () => {
    setIsSubmitting(true);
    try {
      const data = await getPlayback(userInfo.id);
      setSettings(data); // Reset settings
      await editPlayback(initialSettings); // Update the settings via API
      setToastOpen(true);
      setToastMessage("Media setting were reset to default successfully.");
    } catch (error) {
      console.log(error);
      setToastMessage("Failed to reset settings!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <BlockHeading>
        <Typography component="h3" variant="blockTitle" mb={pxToRem(5)}>
          Media Settings
        </Typography>
        <Typography component="p" variant="medium">
          Sed ut perspiciatis unde omnis iste natus accusantium.
        </Typography>
      </BlockHeading>

      <Stack
        gap={pxToRem(20)}
        sx={{
          "& label": {
            alignItems: "flex-start",
            "& .MuiTypography-root": {
              fontSize: pxToRem(18),
              fontWeight: 600,
              color: "#333",
            },
          },
          "& .MuiSwitch-root": {
            mt: "4px !important",
          },
        }}
      >
        <FormControl sx={{ marginLeft: "-15px" }}>
          <InputLabel
            sx={{
              lineHeight: 1.5,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            Playing Mode
          </InputLabel>
          <Select
            value={settings.playing_mode}
            onChange={handleChange}
            name="playing_mode"
            sx={{ width: "220px", textAlign: "left" }}
            IconComponent={(props) => (
              <Chevron
                {...props}
                style={{
                  transition: "transform 0.3s",
                  transform: isSelectOpen ? "rotate(-90deg)" : "rotate(90deg)",
                }}
              />
            )}
            onOpen={() => setIsSelectOpen(true)}
            onClose={() => setIsSelectOpen(false)}
          >
            <MenuItem value="watch">Watch</MenuItem>
            <MenuItem value="listen">Listen</MenuItem>
            <MenuItem value="ask">Ask Me Everytime</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ marginLeft: "-15px" }}>
          <Select
            value={settings.default_play_speedd}
            onChange={handleChange}
            name="default_play_speedd"
            IconComponent={(props) => (
              <Chevron
                {...props}
                style={{
                  transition: "transform 0.3s",
                  transform: isSelectOpen2 ? "rotate(-90deg)" : "rotate(90deg)",
                }}
              />
            )}
            onOpen={() => setIsSelectOpen2(true)}
            onClose={() => setIsSelectOpen2(false)}
            sx={{ width: "220px", textAlign: "left" }}
          >
            <MenuItem value={0.25}>0.25</MenuItem>
            <MenuItem value={0.5}>0.5</MenuItem>
            <MenuItem value={0.75}>0.75</MenuItem>
            <MenuItem value={1.0}>Normal</MenuItem>
            <MenuItem value={1.25}>1.25</MenuItem>
            <MenuItem value={1.5}>1.5</MenuItem>
            <MenuItem value={1.75}>1.75</MenuItem>
            <MenuItem value={2.0}>2</MenuItem>
          </Select>
          <InputLabel
            sx={{
              lineHeight: 1.5,
              fontSize: "1.5rem",
              fontWeight: 600,
              color: "#333",
            }}
          >
            Default Play Speed
          </InputLabel>
        </FormControl>

        <FormControl>
          <Switcher
            checked={settings.autoplay}
            onChange={handleChange}
            label="Autoplay (for free videos)"
            name="autoplay"
          />
        </FormControl>

        <FormControl>
          <Switcher
            checked={settings.fullscreen_on_rotate}
            onChange={handleChange}
            label="Rotate On Fullscreen"
            name="fullscreen_on_rotate"
          />
        </FormControl>
      </Stack>

      <Divider
        sx={{ my: pxToRem(30), borderColor: "rgba(191,190,187, 0.5)" }}
      />

      {/* Reset Settings Button */}
      <Button
        size="small"
        variant="outlined"
        onClick={handleResetSettings}
        disabled={isSubmitting}
      >
        Reset Settings
      </Button>

      <Toast
        open={toastOpen}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
      />
    </>
  );
};

export default Playback;
