import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Hero from "./Hero";
import TagsCloud from "../../components/TagsCloud";
import Presentation from "./Presentation";
import Philosophy from "./Philosophy";
import BlogNews from "./BlogNews";
import FAQ from "./FAQ";
import Parsa from "./Parsa";
import Guides from "../../components/Guides";
import Courses from "./Courses";
import Promotion from "./Promotion";
import VideoSlider from "./VideoSlider";
import { getStudent, unsubscribeFromEmail } from "../../api/student";
import { useAuth } from "../../utils/AuthContext";
import LoginSubscribeModal from "../preSubscribe/LoginSubscribeModal";
import LoginModal from "../auth/LoginModal";
import { useModal } from "mui-modal-provider";
import { useLocation } from "react-router-dom";
import SubscriptionFeedbackModal from "../course/components/SubscriptionFeedbackModal";
import { getPromotionSection } from "../../api/cmsApi";
const Home = () => {
  const [promotionSectionOrder, setPromotionSectionOrder] = useState(4);
  const fetchPromotionSectionOrder = () => {
    getPromotionSection()
      .then((res) => {
        setPromotionSectionOrder(res.data.position);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    document.title = "Learn and Grow with Reb Yakov Moshe";
    fetchPromotionSectionOrder();
  }, []);
  console.log("promotionSectionOrder", promotionSectionOrder);
  const { checkAuth, logout } = useAuth();
  const isAuthenticated = checkAuth();

  const { showModal, hideModal } = useModal();
  const location = useLocation();

  console.log("isAuthenticated", isAuthenticated);

  const [student, setStudent] = useState(null);

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await getStudent();
        setStudent(response.data);
      } catch (error) {
        console.error("Failed to fetch student data:", error);
      }
    };

    fetchStudent();
  }, []);

  const capitalizeFirstLetter = (str) => {
    if (!str || typeof str !== "string") return ""; // check if str is undefined, null, or not a string
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const nickName = student?.nickname
    ? capitalizeFirstLetter(student.nickname)
    : "";
  const firstName = student?.user?.first_name
    ? capitalizeFirstLetter(student.user.first_name)
    : "";
  const lastName = student?.user?.last_name
    ? capitalizeFirstLetter(student.user.last_name)
    : "";

  const fullName = `${firstName} ${lastName}`;

  //start: unsubscribe from notifications
  const [openUnsubscribeFeedback, setOpenUnsubscribeFeedback] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const removeUnsubscribeParamFromURL = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("unsubscribe");
    window.history.replaceState({}, document.title, url);
  };

  const unsubscribeHelperFun = async (token) => {
    try {
      const res = await unsubscribeFromEmail(token);
      console.log("unsubscribe response: ", res);

      setModalMessage(res?.detail);
      setOpenUnsubscribeFeedback(true);
      setSuccess(true);
    } catch (error) {
      const errorMsg = error?.response?.data?.detail;
      if (errorMsg === "Given token not valid for any token type") {
        const modal = showModal(LoginSubscribeModal, {
          openLoginModal: () => {
            hideModal(modal.id);
            localStorage.setItem("unsubscribeToken", token);
            removeUnsubscribeParamFromURL();
            showModal(LoginModal);
          },
          loginMessage: "You need to log in first to unsubscribe.",
          // hideModal: () => hideModal(modal.id),
          hideModal: () => {
            hideModal(modal.id);
            removeUnsubscribeParamFromURL();
          },
        });
      } else if (errorMsg === "Invalid unsubscribe link.") {
        setModalMessage(
          "It seems like you're loged in with the wrong account."
        );
        setOpenUnsubscribeFeedback(true);
        setSuccess(false);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenUnsubscribeFeedback(false);
    removeUnsubscribeParamFromURL();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const unsubscribe = params.get("unsubscribe");
    if (unsubscribe) {
      unsubscribeHelperFun(unsubscribe);
    }
  }, [location]);

  useEffect(() => {
    const unsubscribeToken = localStorage.getItem("unsubscribeToken");
    if (unsubscribeToken) {
      unsubscribeHelperFun(unsubscribeToken);
      localStorage.removeItem("unsubscribeToken");
    }
  }, []);

  //end: unsubscribe from notifications

  const sections = [
    { id: 1, component: <Hero /> },
    { id: 2, component: <TagsCloud /> },
    {
      id: 3,
      component: isAuthenticated ? (
        <VideoSlider name={nickName || firstName} />
      ) : (
        <Box height={120} />
      ),
    },
    { id: 4, component: <Presentation /> },
    { id: 5, component: <Philosophy /> },
    { id: 6, component: <Courses /> },
    { id: 7, component: <BlogNews student={student} /> },
    { id: 8, component: <FAQ /> },
    { id: 9, component: <Parsa /> },
  ];
  if (
    promotionSectionOrder > 0 &&
    promotionSectionOrder <= sections.length + 1
  ) {
    sections.splice(promotionSectionOrder - 1, 0, {
      id: 10,
      component: <Promotion />,
    });
  }
  return (
    <Box position="relative" overflow="hidden">
      <Guides color="rgba(0,0,0,0.06)" zIndex={-1} />
      {sections.map((section, index) => (
        <Box key={index}>{section.component}</Box>
      ))}

      <SubscriptionFeedbackModal
        open={openUnsubscribeFeedback}
        handleClose={handleCloseModal}
        modalTitle={"Unsubscribe"}
        errorMessage={modalMessage}
        success={success}
        unsubscribe
      />
    </Box>
  );
};

export default Home;
