import { Box, Container, Stack, Typography } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { pxToRem } from "px2rem2px";
import TextLink from "./TextLink";
import Slider from "./Slider";
import Slide from "./Slide";
import { useEffect, useState } from "react";
import { listCourses } from "../api/course";
import { useNavigate } from "react-router-dom";

const VideosSection = ({ courseId, categoryId }) => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(
      "categories debuggin courseId",
      courseId,
      "categoryId",
      categoryId
    );
    const fetchCourses = async () => {
      if (!categoryId) {
        return;
      }
      try {
        const res = await listCourses(5, 1, "", categoryId, false);
        const filteredCourses = res.data.results.filter(
          (course) => course.id !== courseId
        );

        console.log("categories debuggin res", res);
        console.log("categories debuggin filteredCourses", filteredCourses);
        setCourses(filteredCourses);
        // setCourses(res.data.results);
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [courseId]);

  // if (loading) {
  //   return <Typography>Loadinggggggggg...</Typography>;
  // }

  if (courses.length === 0) {
    return null;
  }

  return (
    <Box
      display={{ xs: "none", ssm: "block" }}
      pb={{ xs: pxToRem(90), md: pxToRem(110) }}
    >
      <Container disableGutters>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb={{ xs: pxToRem(10), md: pxToRem(40) }}
        >
          <Typography variant="sectionTitle" component="h2">
            You <span className="highlighted">may</span> also{" "}
            <span className="highlighted">like</span>
          </Typography>
          <TextLink
            onClick={() => {
              navigate("/courses");
            }}
          >
            View All Videos
          </TextLink>
        </Stack>
      </Container>
      <Slider
        slidesPerView={1.5}
        spaceBetween={20}
        arrows
        style={{
          padding: `${pxToRem(40)} ${pxToRem(20)} ${pxToRem(50)}`,
          margin: `0 ${pxToRem(-20)}`,
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        }}
      >
        {courses.map((course) => (
          <SwiperSlide key={course.id}>
            {/* <div className="slide-wrapper"> */}
            <Slide
              image={course?.thumb_nail}
              title={course?.name}
              subtitle={course?.subtitle}
              videoLink={
                course.is_subscribed
                  ? `/course/${course.slug}`
                  : `/course/${course.slug}/presubscribe`
              }
              date={course?.release_date}
              subscribers={course?.subscriber_count}
              rating={course?.rating}
              includeCourseDetails
            />
            {/* </div> */}
          </SwiperSlide>
        ))}
      </Slider>
    </Box>
  );
};

export default VideosSection;
