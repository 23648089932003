import { Box, Container, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { pxToRem } from "px2rem2px";
import Guides from "../../components/Guides";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TermsBlock from "../../components/TermsBlock";
import HeaderBgrImg from "../../images/bgr-short.svg";
import { getPrivacyPolicy } from "../../api/cmsApi";

const Policy = () => {
  useEffect(() => {
    document.title = "Privacy Policy - Reb Yakov Moshe";
  }, []);

  const [policy, setPolicy] = useState(null);
  const fetchPrivacyPolicy = () => {
    getPrivacyPolicy()
      .then((res) => {
        setPolicy(res.data.text);
      })
      .catch((error) => {
        console.error("Error getting privacy policy:", error);
      });
  };
  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);
  return (
    <Box
      position="relative"
      maxWidth={pxToRem(1920)}
      m="0 auto"
      p={{ xs: 0, md: pxToRem(30) }}
      sx={{ overflowX: "hidden" }}
    >
      <Box position="relative">
        <Box
          display="flex"
          height={{ xs: "100px", md: pxToRem(130) }}
          borderRadius={{ xs: 0, md: "40px 40px 0 0" }}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url("${HeaderBgrImg}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <Header menuVisible hideUserProfile />
          <Guides color="rgba(0,0,0,0.03)" zIndex="0" />
        </Box>

        <Container
          disableGutters
          sx={{
            pt: { xs: "40px", sm: "50px", md: pxToRem(80) },
            pb: { xs: "60px", sm: "70px", md: pxToRem(140) },
          }}
        >
          <Typography
            variant="sectionTitle"
            component="h2"
            mb={{ xs: "15px", md: "50px" }}
          >
            Privacy <span className="highlighted">Policy</span>
          </Typography>
          {policy ? (
            <Box
              sx={{
                p: 2,
                px: 3,
                borderRadius: "8px",
              }}
              dangerouslySetInnerHTML={{ __html: policy }} // Render raw HTML
            />
          ) : (
            <Typography variant="body1">Loading policy...</Typography>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Policy;
