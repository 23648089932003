import { Box, Button, Stack, Typography } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { pxToRem } from "px2rem2px";
import BlockHeading from "../../../components/BlockHeading";
import Slider from "../../../components/Slider";
import Course from "../../../components/user/Course";

import { ReactComponent as Chevron } from "../../../images/chevron.svg";
import { ReactComponent as FilterMenu } from "../../../images/filter-menu.svg";
import { ReactComponent as Arrows } from "../../../images/down-arrow.svg";

import { useEffect, useState } from "react";
import { getFavoriteCourses } from "../../../api/student";
import { removeFavorite } from "../../../api/course";

const Courses = ({ setNoFavCourses, setFavCoursesLoaded }) => {
  const [favoriteCourses, setFavoriteCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removingCourse, setRemovingCourse] = useState(null);

  const refetchFavoriteCourses = async () => {
    try {
      const res = await getFavoriteCourses();
      setFavoriteCourses(res.data.results);
      setNoFavCourses(res.data.count===0)
      setFavCoursesLoaded(true);
    } catch (error) {
      console.error("Failed to fetch favorite courses:", error);
    } 
  };

  useEffect(() => {
    console.log("favoriteCourses", favoriteCourses);
  }, [favoriteCourses]);

  // remove from favorites
  const removeFromFavorites = async (courseId) => {
    if(loading)return
    setLoading(true)
    try {
      setRemovingCourse(courseId); 

      setTimeout(async () => {
        setFavoriteCourses(favoriteCourses.filter((c) => c.id !== courseId));
        await removeFavorite(courseId);
        console.log("Favorite course removed successfully");
        setRemovingCourse(null); 
        refetchFavoriteCourses();
      }, 500); 
    } catch (err) {
      console.error("Error removing favorite course:", err);
    }finally{

      setLoading(false)
    }
  };

  useEffect(() => {
    refetchFavoriteCourses();
  }, []);

  // useEffect(() => {
  //   if (!favoriteCourses.length > 0) {
  //     setNoFavCourses(true);
  //   } else {
  //     setNoFavCourses(false);
  //   }
  // }, [favoriteCourses]);

  return (
    <>
      {favoriteCourses?.length > 0 ? (
        <Box>
          <BlockHeading>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap="20px"
              flexWrap="wrap"
            >
              <Typography component="h3" variant="blockTitle">
                Courses
              </Typography>

              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent={{ xs: "space-between", ssm: "flex-end" }}
                gap={{ xs: "15px", ssm: "30px" }}
              >
                <Button
                  startIcon={<FilterMenu />}
                  endIcon={<Chevron />}
                  sx={{
                    color: "#333",
                    fontSize: "14px",
                    "& .MuiButton-endIcon": {
                      ml: pxToRem(12),
                      "& svg": { transform: "rotate(90deg)" },
                    },
                  }}
                >
                  Active
                </Button>

                <Button
                  startIcon={<Arrows />}
                  endIcon={<Chevron />}
                  sx={{
                    color: "#333",
                    fontSize: "14px",
                    "& .MuiButton-endIcon": {
                      ml: pxToRem(12),
                      "& svg": { transform: "rotate(90deg)" },
                    },
                  }}
                >
                  Date Subscribed
                </Button>
              </Stack>
            </Stack>
          </BlockHeading>

          <Box
            sx={{
              "& .MuiContainer-root": {
                p: 0,
                "@media(max-width: 1330px)": {
                  mb: "60px",
                },
              },
              "& .swiper": {
                p: { xs: "0 0 35px", sm: "10px 10px 35px" },
                m: { xs: 0, sm: "-10px -10px 0" },
                "@media(max-width: 1330px)": {
                  pb: "10px !important",
                },
              },
              "& .swiper-slide": { width: "auto" },
              "& .arrow-prev": { left: "-90px", top: "40%" },
              "& .arrow-next": { right: "-90px", top: "40%" },
            }}
          >
            <Slider
              slidesPerView={2}
              spaceBetween={20}
              arrows
              breakpoints={{
                320: {
                  slidesPerView: 2,
                },
                600: {
                  slidesPerView: 3,
                },
                1060: {
                  slidesPerView: 4,
                },
              }}
            >
              {favoriteCourses.map((course) => (
                <SwiperSlide key={course?.id}>
                  <Box
                    sx={{
                      transition: "opacity 0.5s ease, transform 0.5s ease",
                      opacity: removingCourse === course.id ? 0 : 1,
                      transform:
                        removingCourse === course.id
                          ? "scale(0.95)"
                          : "scale(1)",
                    }}
                  >
                    <Course
                      favorite
                      image={course.thumb_nail}
                      videoLink={`${window.location.origin}/course/${course?.slug}`}
                      title={course?.name}
                      subscription={course?.user_subscription}
                      isFavorite={course?.is_favorite}
                      removeFromFavorites={removeFromFavorites}
                      courseId={course?.id}
                    />
                  </Box>
                </SwiperSlide>
              ))}
            </Slider>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default Courses;
