import React from 'react';

const StarIcon = ({ color, size = 16 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size * 0.96} viewBox="0 0 16 15.333">
    <path id="star" d="M15.958,6.29a.849.849,0,0,0-.731-.584l-4.619-.419L8.782,1.013a.851.851,0,0,0-1.565,0L5.392,5.286.773,5.706A.851.851,0,0,0,.29,7.193l3.491,3.061L2.752,14.789a.85.85,0,0,0,1.265.919L8,13.327l3.982,2.381a.851.851,0,0,0,1.266-.919l-1.029-4.534L15.71,7.193A.851.851,0,0,0,15.958,6.29ZM8.076,13.281" transform="translate(0 -0.496)" fill={color} />
  </svg>
);

const StarIconContainer = (props) => (
  <span {...props} style={{ margin: '0 1px' }}>
    {props.children}
  </span>
);

export { StarIcon, StarIconContainer };
