import { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Link,
  Menu,
  MenuItem,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import AvatarImg from "../images/avatar.png";
import { ReactComponent as Chevron } from "../images/chevron.svg";
import { useAuth } from "../utils/AuthContext";
import { getStudent, getUserInfo } from "../api/student";
import NotificationsDropdown from "./NotificationsDropdown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import { pxToRem } from "px2rem2px";

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: "15px",
  lineHeight: "30px",
  fontWeight: 600,
  ":hover": {
    color: "#026670",
  },
}));

const ProfileMenu = ({
  insideDropDown,
  notifications,
  setNotifications,
  nextPage,
  setNextPage,
  hasMore,
  setHasMore,
  loading,
  count,
}) => {


  const [anchorEl, setAnchorEl] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const open = Boolean(anchorEl);
  const { logout } = useAuth();
  const userInfo = getUserInfo();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (keepOpen = false) => {
    if (!keepOpen) {
      setAnchorEl(null);
      setShowNotifications(false);
    }
  };

  useEffect(() => {
    getStudent().then((res) => {
      if (!res.data.is_password_set) {
        window.location.href = "/complete-google-signup";
      }
    });
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));


  const capitalizeFirstLetter = (str) => {
    if (!str || typeof str !== 'string') return ""; // check if str is undefined, null, or not a string
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const nickName = userInfo?.nickname ? capitalizeFirstLetter(userInfo.nickname) : "";
  const firstName = userInfo?.user?.first_name ? capitalizeFirstLetter(userInfo.user.first_name) : "";
  const lastName = userInfo?.user?.last_name ? capitalizeFirstLetter(userInfo.user.last_name) : "";
  
  const fullName = `${firstName} ${lastName}`;




  return (
    <>
      <Button
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        endIcon={<Chevron />}
        sx={{
          p: 0,
          "& .MuiButton-endIcon": { ml: theme.spacing(1.5) },
          "& svg": { transform: "rotate(90deg)", mt: theme.spacing(0.5) },
        }}
      >
        <Avatar sx={{ width: pxToRem(48), height: pxToRem(48) }}>
          <img src={userInfo?.avatar || AvatarImg} alt="" />
        </Avatar>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => handleClose()}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            // minWidth: isMobile ? "150px" : isTablet ? "180px" : "200px",
            minWidth: "220px",
            overflow: "visible",
            filter: "drop-shadow(0px 10px 20px rgba(0,0,0,0.16))",
            mt: 1.375,
            borderRadius: "10px",
            ".MuiList-root": {
              pl: 1,
              pr: 1,
              li: {
                p: 0,
                "&:hover": {
                  bgcolor: "transparent",
                },
              },
              hr: {
                my: 1.875,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box onClick={() => handleClose()}>
          <Link
            to="/user/dashboard"
            component={NavLink}
            underline="none"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(2),
              fontSize: "15px",
              fontWeight: "bold",
              padding: theme.spacing(3, 2.5, 0, 2.5),
            }}
          >
            <Avatar sx={{ width: theme.spacing(6), height: theme.spacing(6) }}>
              <img src={userInfo?.avatar || AvatarImg} alt="Avatar" />
            </Avatar>

            
            <Box
              component="span"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {(nickName || fullName)?.length > 12
                ? `${(nickName || fullName)?.substring(
                    0,
                    12
                  )}...`
                : nickName || fullName}
            </Box>

          </Link>
        </Box>
        <Box sx={{ padding: theme.spacing(0, 2.5) }}>
          <Divider />
        </Box>
        {showNotifications ? (
          <>
            <Box
              onClick={() => setShowNotifications(false)}
              sx={{
                padding: theme.spacing(0, 0, 1, 2),
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <IconButton> */}
              <ArrowBackIcon />
              {/* </IconButton> */}
              Back
            </Box>
            <NotificationsDropdown
              insideDropDown
              insideUserProfile
              handleCloseParentMenu={handleClose}
              notifications={notifications}
              setNotifications={setNotifications}
              nextPage={setNextPage}
              hasMore={hasMore}
              setHasMore={setHasMore}
              loading={loading}
              count={count}
            />
          </>
        ) : (
          <>
            <Box sx={{ paddingLeft: theme.spacing(2.5) }}>
              <LinkStyled
                to="/user/courses"
                component={NavLink}
                underline="none"
              >
                <Box onClick={() => handleClose()}>My Courses</Box>
              </LinkStyled>
              <LinkStyled
                to="/user/favorites"
                component={NavLink}
                underline="none"
              >
                <Box onClick={() => handleClose()}>My Favorites</Box>
              </LinkStyled>
              <LinkStyled
                // to="/user/account-settings"
                to="/user/dashboard"
                component={NavLink}
                underline="none"
              >
                <Box onClick={() => handleClose()}>Account</Box>
              </LinkStyled>

              {isMobile && notifications?.length > 0 && (
                <LinkStyled underline="none" sx={{ cursor: "pointer" }}>
                  <Box onClick={() => setShowNotifications(true)}>
                    <NotificationsDropdown
                      insideUserProfile
                      handleCloseParentMenu={handleClose}
                      notifications={notifications}
                      setNotifications={setNotifications}
                      nextPage={setNextPage}
                      hasMore={hasMore}
                      setHasMore={setHasMore}
                      loading={loading}
                      count={count}
                    />
                  </Box>
                </LinkStyled>
              )}
            </Box>
            <Box sx={{ padding: theme.spacing(0, 2.5) }}>
              <Divider />
            </Box>
            <Box
              sx={{
                padding: `0 0 ${theme.spacing(2.5)} ${theme.spacing(2.5)}`,
              }}
            >
              <LinkStyled
                to=""
                component={NavLink}
                underline="none"
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: "#BFBEBB",
                }}
              >
                <Box onClick={() => handleClose()}>Help & Support</Box>
              </LinkStyled>
              <Box
                onClick={() => {
                  handleClose();
                  logout();
                }}
                sx={{
                  fontSize: "12px",
                  lineHeight: "20px",
                  fontWeight: 600,
                  color: "#BFBEBB",
                  cursor: "pointer",
                  ":hover": { color: "#026670" },
                  padding: "8px 0",
                }}
              >
                Signout
              </Box>
            </Box>
          </>
        )}
      </Menu>
    </>
  );
};

export default ProfileMenu;
