import React, { useState } from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit,minmax(100px,1fr))",
  gap: "10px",
  borderRadius: 0,
  marginBottom: "40px",
  "& button": {
    border: "2px solid #BFBEBB !important",
    borderRadius: "calc(infinity * 1px) !important",
    height: "54px",
    fontSize: "18px",
    fontWeight: 600,
    color: "#BFBEBB",
    textTransform: "Capitalize",
    padding: "0 5px",
    whiteSpace: "nowrap",
    transition: "0.2s ease",
    "&:hover, &.Mui-selected, &.Mui-selected:hover": {
      borderColor: "#026670 !important",
      backgroundColor: "#026670",
      color: "white",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    },
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 32,
  height: 32,
  border: "2px solid #BFBEBB",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    width: 22,
    height: 22,
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: "#F7F6F2",
  "&::before": {
    display: "block",
    width: 24,
    height: 24,
    content: '""',
    backgroundColor: "#026670",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      width: 14,
      height: 14,
    },
  },
}));

export default function SustainerOptions() {
  const [frequency, setFrequency] = useState("Daily");
  const [recurrence, setRecurrence] = useState(
    "Until I Cancel The Subscription"
  );
  const [notification, setNotification] = useState(false);

  const handleFrequencyChange = (event, newFrequency) => {
    if (newFrequency !== null) {
      setFrequency(newFrequency);
    }
  };

  const handleRecurrenceChange = (event) => {
    setRecurrence(event.target.value);
  };

  const handleNotificationChange = (event) => {
    setNotification(event.target.checked);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderBottom: "1px solid rgba(191,190,187,0.5)",
        padding: { xs: "32px 0", sm: "60px 0 80px" },
      }}
    >
      <Grid item xs={12} md={3}>
        <Typography
          variant="h6"
          gutterBottom
          fontSize="24px"
          lineHeight="30px"
          fontWeight="700"
        >
          Sustainer Options
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        <ToggleButtonGroupStyled
          value={frequency}
          exclusive
          onChange={handleFrequencyChange}
        >
          <ToggleButton value="One Time">One Time</ToggleButton>
          <ToggleButton value="Daily">Daily</ToggleButton>
          <ToggleButton value="Weekly">Weekly</ToggleButton>
          <ToggleButton value="Monthly">Monthly</ToggleButton>
          <ToggleButton value="Annually">Annually</ToggleButton>
        </ToggleButtonGroupStyled>

        <Stack
          flexDirection={{ xs: "column", ssm: "row" }}
          alignItems={{ xs: "flex-start", ssm: "center" }}
          gap={{ xs: "12px", ssm: "30px" }}
          mb="30px"
        >
          <Typography component="label" fontWeight={500} flexShrink={0}>
            Number of Recurrences
          </Typography>
          <Select
            value={recurrence}
            onChange={handleRecurrenceChange}
            variant="outlined"
            fullWidth
            IconComponent={ExpandMoreIcon}
            sx={{
              bgcolor: "#EDECE8",
              borderRadius: "calc(infinity * 1px)",
              ".MuiSelect-select": { pl: "40px", pr: "40px !important" },
              ".MuiSelect-icon": {
                color: "#026670",
                right: "25px",
              },
            }}
          >
            <MenuItem value="Until I Cancel The Subscription">
              Until I Cancel The Subscription
            </MenuItem>
            <MenuItem value="10 Times">10 Times</MenuItem>
            <MenuItem value="20 Times">20 Times</MenuItem>
            <MenuItem value="50 Times">50 Times</MenuItem>
          </Select>
        </Stack>

        <FormControlLabel
          sx={{
            ".MuiFormControlLabel-label": {
              color: "#333",
              fontSize: "16px",
              fontWeight: 500,
              marginLeft: "15px",
            },
          }}
          control={
            <Checkbox
              checked={notification}
              onChange={handleNotificationChange}
              checkedIcon={<BpCheckedIcon />}
              icon={<BpIcon />}
            />
          }
          label="Receive a notification each time your card is charged?"
        />
      </Grid>
    </Grid>
  );
}
