import {
  Box,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Category from "./Category";
import Course from "./Course";
import { formatDate, formatDuration } from "../../utils/format";
import { SwiperSlide } from "swiper/react";
import Slider from "../../components/Slider";
import { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { styled } from "@mui/system";
import shouldNavigateToPresubscribe from "../../utils/subscription";
import { pxToRem } from "px2rem2px";
import CardSkeleton from "../../components/CardSkeleton";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#d6d6d6",
  color: "#333333 ",
  fontSize: "12px", 
  padding: "4px 8px", 
  "&:hover": {
    backgroundColor: "#c4c4c4",
  },
  borderRadius: "10px",
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  width: "18px",
  height: "18px",
  pading: "15px 8px",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  "& .MuiSvgIcon-root": {
    backgroundColor: "#f3f3f3",
    color: "#333333",
    borderRadius: "50%",
    padding: "2px", 
    fontSize: "16px", 
  },
  "&:hover .MuiSvgIcon-root": {
    backgroundColor: "#e0e0e0",
  },
}));

const CategoryFilterButton = ({ selectedCategory, categoryName, onClear }) => {
  return (
    selectedCategory && (
      <Box sx={{ display: "flex", alignItems: "center", gap: pxToRem(15) }}>
        {" "}
        {/* Smaller gap */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FilterAltIcon
            sx={{
              color: "#bfbebb",
              fontSize: "20px",
              width: pxToRem(30),
              height: pxToRem(30),
            }}
          />{" "}
          {/* Smaller icon size */}
          <Typography
            sx={{ color: "#bfbebb", fontSize: pxToRem(17), fontWeight: 600 }}
          >
            Filters:
          </Typography>
        </Box>
        <StyledButton
          endIcon={
            <CustomIconButton size="small" onClick={onClear}>
              <CloseIcon />
            </CustomIconButton>
          }
        >
          {categoryName}
        </StyledButton>
      </Box>
    )
  );
};

const CoursesSection = ({
  courses,
  setCourses,
  setPage,
  onFavoriteClick,
  isAuthenticated,
  lastCourseElementRef,
  lastActiveCourseElementRef,
  loading,
  courseCount,
  isOnSearchMode,
  categoriesList,
  selectedCategory,
  setSelectedCategory,
  activeCourses,
  setHasMoreActive,
  setFilterMode,
  setActiveCoursesPage,
}) => {
  const categories = courses.reduce((acc, course) => {
    const categoryName = course?.category?.name;
    if (!acc[categoryName]) {
      acc[categoryName] = [];
    }
    acc[categoryName].push(course);
    return acc;
  }, {});

  const totalVideos = courses.length;

  const lastActiveCourseId = activeCourses[activeCourses.length - 1]?.id;

  const [fetchedActiveCourseIds, setFetchedActiveCourseIds] = useState(
    new Set()
  );

  useEffect(() => {
    setFetchedActiveCourseIds((prevIds) => {
      const newIds = new Set(prevIds);
      activeCourses.forEach((course) => newIds.add(course.id));
      return newIds;
    });
  }, [activeCourses]);

  //active courses

  //for ref

  const flatCourses = useMemo(() => Object.values(categories).flat(), [categories]);
  const lastCourseId = flatCourses[flatCourses.length - 1]?.id;

  const [fetchedCourseIds, setFetchedCourseIds] = useState(new Set());

  useEffect(() => {
    setFetchedCourseIds((prevIds) => {
      const newIds = new Set(prevIds);
      flatCourses.forEach((course) => newIds.add(course.id));
  
      const idsAreEqual =
        newIds.size === prevIds.size &&
        [...newIds].every((id) => prevIds.has(id));
  
      if (idsAreEqual) {
        return prevIds; 
      }
  
      console.log("Updated fetchedCourseIds", newIds);
      return newIds;
    });
  }, [flatCourses]);


  const displayedCount = isOnSearchMode ? courses.length : courseCount;
  const displayedLabel = displayedCount === 1 ? "Course" : "Courses";

  // filter
  const categoryName = categoriesList.find(
    (category) => category.id === selectedCategory
  )?.name;

  const handleClear = () => {
    setSelectedCategory("");
    setCourses([]);
    setHasMoreActive(true);
    setFilterMode(false);
    setPage(1);
    setActiveCoursesPage(1)
  };

  //enhance ux
  // const itemsPerRow = 2;

  // const totalCourseCards = courses.length;
  // const remainder = totalCourseCards % itemsPerRow;
  // const skeletonCardsNeeded = remainder > 0 ? itemsPerRow - remainder : 0;

  // const activeCoursesRemainder = activeCourses.length % itemsPerRow;
  // const activeSkeletonsNeeded =
  //   activeCoursesRemainder > 0 ? itemsPerRow - activeCoursesRemainder : 0;

  return (
    <Box mt={{ xs: "36px", ssm: "60px" }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        gap="15px"
        mb="25px"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: { ssm: "center" },
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography
            variant="sectionTitle"
            component="h2"
            fontSize={pxToRem(35)}
            mb={0}
          >
            <span className="highlighted">Courses</span>
          </Typography>
          {selectedCategory && (
            <CategoryFilterButton
              selectedCategory={selectedCategory}
              categoryName={categoryName}
              onClear={handleClear}
            />
          )}
        </Box>
        <Typography
          fontSize="12px"
          fontWeight={600}
          color="#BFBEBB"
          whiteSpace="nowrap"
        >
          {loading ? "" : `${displayedCount} ${displayedLabel}`}
        </Typography>
      </Stack>

      {/* Active Courses  */}
      {isAuthenticated && activeCourses.length > 0 && (
        <Stack gap="40px" sx={{ width: "100%", marginBottom: "40px" }}>
          <Category title="Active Courses" isActive>
            {activeCourses.map((course) => {
              const isLastActiveCourse = course.id === lastActiveCourseId;

              const linkUrl = shouldNavigateToPresubscribe(
                course.subscription_status
              )
                ? `/course/${course.slug}/presubscribe`
                : `/course/${course.slug}`;

              return (
                <SwiperSlide key={course.id}>
                  <Course
                    passRef={
                      isLastActiveCourse ? lastActiveCourseElementRef : null
                    }
                    image={course?.thumb_nail}
                    title={course?.name}
                    isTitleRtl={course?.is_name_rtl}
                    subtitle={course?.subtitle}
                    isSubtitleRtl={course?.is_subtitle_rtl}
                    text={course?.description}
                    subscriptionStatus={course?.subscription_status}
                    linkUrl={linkUrl}
                    date={formatDate(course.release_date || course.updated_at)}
                    subscribers={course.subscriber_count}
                    duration={formatDuration(course.length)}
                    labels={course.labels.map((label) => label.name)}
                    onFavoriteClick={() => onFavoriteClick(course.id)}
                    course={course}
                    isAuthenticated={isAuthenticated}
                    courseProgress={course?.progress}
                  />
                </SwiperSlide>
              );
            })}

            {/* {isloadingActive &&
            [...Array(activeSkeletonsNeeded)].map((_, i) => (
              <SwiperSlide key={`active-skeleton-${i}`}>
                <CardSkeleton />
              </SwiperSlide>
            ))} */}
          </Category>
        </Stack>
      )}
      {/* Courses  */}

      <Stack gap="40px" sx={{ width: "100%" }}>
        {Object.keys(categories).map((category, index) => (
          <Category key={index} title={category}>
            {categories[category].map((course, courseIndex) => {
              const isLastCourse = course.id === lastCourseId;

              const linkUrl = shouldNavigateToPresubscribe(
                course.subscription_status
              )
                ? `/course/${course.slug}/presubscribe`
                : `/course/${course.slug}`;

              return (
                <SwiperSlide key={course.id}>
                  <Course
                    passRef={
                      isOnSearchMode
                        ? null
                        : isLastCourse
                        ? lastCourseElementRef
                        : null
                    }
                    image={course?.thumb_nail}
                    title={course?.name}
                    isTitleRtl={course?.is_name_rtl}
                    subtitle={course?.subtitle}
                    isSubtitleRtl={course?.is_subtitle_rtl}
                    text={course?.description}
                    subscriptionStatus={course?.subscription_status}
                    linkUrl={linkUrl}
                    date={formatDate(course.release_date || course.updated_at)}
                    subscribers={course.subscriber_count}
                    duration={formatDuration(course.length)}
                    labels={course.labels.map((label) => label.name)}
                    onFavoriteClick={() => onFavoriteClick(course.id)}
                    course={course}
                    isAuthenticated={isAuthenticated}
                    courseProgress={course?.progress}
                  />
                </SwiperSlide>
              );
            })}
            {/* {loading &&
            index === Object.keys(categories).length - 1 &&
            [...Array(skeletonCardsNeeded)].map((_, i) => (
              <SwiperSlide key={`skeleton-${i}`}>
                <CardSkeleton />
              </SwiperSlide>
            ))} */}
          </Category>
        ))}
      </Stack>

      {loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            mt:
              activeCourses.length === 0 && courses.length === 0
                ? "0px"
                : "20px",
          }}
        >
          {activeCourses.length === 0 && courses.length === 0 && (
            <>
              <Skeleton variant="text" width="20%" height={pxToRem(30)} />
              <Skeleton variant="text" width="100%" height={pxToRem(10)} />
            </>
          )}
          <Box sx={{ display: "flex", gap: "20px" }}>
            <CardSkeleton />
            <CardSkeleton />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CoursesSection;
